import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService } from '../../../../../../auth/auth.service';
import { CaseSummary } from '../../../../../../shared/models/case';
import { KeyValuePair } from '../../../../../../shared/models/key-value-pair';
import { NotificationService } from '../../../../../../shared/services/notification.service';
import {
  ServedStatus,
  WeightedMatrixDto,
  WeightedMatrixLevelDto,
  WeightedMatrixScoreDto,
  WeightedMatrixSpecialCircumstanceDto,
} from '../../../../../models/iep-weighted-matrix';
import { IepDetailWeightedMatrixService } from '../../../../../services/iep-detail-weighted-matrix.service';

@Component({
  selector: 'app-iep-weight-summary',
  templateUrl: './iep-weight-summary.component.html',
  styleUrls: ['./iep-weight-summary.component.scss'],
})
export class IepWeightSummaryComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  @Input() iepId: string;
  @Input() caseSummary: CaseSummary;

  weightedMatrix: WeightedMatrixDto;
  weightedMatrixLevels: WeightedMatrixLevelDto[] = [];
  weightedMatrixSubscription$: Subscription;
  readOnly = false;
  formGroup = new FormGroup({
    servedStatus: new FormControl(null, Validators.required),
  });
  servedStatuses: KeyValuePair[] = [
    new KeyValuePair(ServedStatus.FederalAndState, 'Both Federal and State'),
    new KeyValuePair(ServedStatus.FederalOnly, 'Federal Only'),
    new KeyValuePair(ServedStatus.StateOnly, 'State Only'),
    new KeyValuePair(ServedStatus.NotApplicable, 'Not Applicable'),
  ];
  servedStatusOptions: KeyValuePair[] = [];

  get isAutoScore() {
    return this.weightedMatrix?.iepHasSDO === false && this.weightedMatrix?.iepHasSAO === false;
  }

  get isRLevelScore() {
    return this.weightedMatrix?.iepHasSDO === false && this.weightedMatrix?.iepHasSAO === true;
  }

  get showSpecialCircumstances() {
    return !this.isAutoScore && (!!this.weightedMatrix?.iepHasATO || !!this.weightedMatrix?.iepHasSDO);
  }

  get showRecommendation() {
    return this.weightedMatrix?.weightedMatrixSpecialCircumstance?.isFinalized || this.isAutoScore || this.isRLevelScore;
  }

  get canViewWeightedMatrix() {
    return this.authService.isWeightedMatrixEditor;
  }

  get canEditWeightedMatrix() {
    return this.authService.isWeightedMatrixEditor;
  }

  constructor(
    private notificationService: NotificationService,
    private weightedMatrixService: IepDetailWeightedMatrixService,
    private route: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (!this.caseId || !this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }

    this.weightedMatrixSubscription$ = forkJoin([
      this.weightedMatrixService.getWeightedMatrix(this.caseId),
      this.weightedMatrixService.getWeightedLevels(this.caseId),
    ]).subscribe(([weightedMatrixResponse, weightedMatrixLevelResponse]) => {
      this.weightedMatrix = weightedMatrixResponse.value;

      this.weightedMatrixLevels = weightedMatrixLevelResponse.value;

      this.resetServedStatusOptions();

      if (this.isAutoScore && this.weightedMatrix?.weightedMatrixScoreHistory?.length === 0) {
        // For auto score create RS level score
        this.createRsScore();
      }

      if (this.isRLevelScore) {
        // Recommendation is not needed for R Level scores
        this.weightedMatrix.isRecommendationFinalized = true;
        this.weightedMatrixService.updateWeightedMatrix(this.weightedMatrix.id, this.weightedMatrix).subscribe(() => {});
      }

      this.formGroup.controls.servedStatus.valueChanges.subscribe((selectedStatus) => {
        this.updateWeightedMatrix(selectedStatus);
      });
    });

    this.weightedMatrixService.weightedMatrixScoreUpdated$.subscribe(() => {
      this.refreshAndResetServedStatus();
    });
  }

  createRsScore() {
    const rsLevel = this.weightedMatrixLevels.find((x) => x.level === 'RS');
    const matrixScore: WeightedMatrixScoreDto = {
      weightedMatrixId: this.weightedMatrix.id,
      iepId: this.iepId,
      recommendedScore: null,
      actualScore: null,
      weightedMatrixLevelId: rsLevel?.id,
      comments: null,
      hasSpecialCircumstance: false,
    };

    this.weightedMatrixService.createWeightedMatrixScore(this.caseSummary.id, matrixScore).subscribe(
      (val) => {
        this.notificationService.success('RS score has been created.');

        this.weightedMatrix.isRecommendationFinalized = true;
        this.weightedMatrix.servedStatus = ServedStatus.FederalOnly;
        this.weightedMatrixService.updateWeightedMatrix(this.weightedMatrix.id, this.weightedMatrix).subscribe(() => {});
      },
      () => {
        this.notificationService.error('There was an error while automatically creating RS score.');
      }
    );
  }

  resetServedStatusOptions() {
    this.servedStatusOptions = [];
    const latestScore = this.weightedMatrix?.weightedMatrixScoreHistory.sort((a, b): any => {
      return new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime();
    });

    if (latestScore.length < 1) return;

    let defaultValue = null;
    if (latestScore) {
      const level = this.getLevel(latestScore[0].weightedMatrixLevelId);
      if (level === 'RP') {
        this.servedStatusOptions = [];
      } else if (level === 'RS') {
        defaultValue = ServedStatus.FederalOnly;
        this.servedStatusOptions = this.servedStatuses.filter((lvl) => lvl.key !== ServedStatus.FederalAndState);
      } else {
        defaultValue = ServedStatus.FederalAndState;
        this.servedStatusOptions = this.servedStatuses.map((lvl) => lvl);
      }
    }
    defaultValue = this.weightedMatrix?.servedStatus ? this.weightedMatrix?.servedStatus : defaultValue;
    this.formGroup.controls.servedStatus.setValue(defaultValue);
  }

  refreshAndResetServedStatus() {
    this.weightedMatrixService.getWeightedMatrixScoreHistory(this.caseSummary?.id).subscribe((scoreHistoryResponse) => {
      if (scoreHistoryResponse.succeeded) {
        this.weightedMatrix.weightedMatrixScoreHistory = scoreHistoryResponse.value;
        this.resetServedStatusOptions();
      } else {
        this.notificationService.error('Failed to load score history');
      }
    });
  }

  getLevel(weightedMatrixLevelId: string) {
    return this.weightedMatrixLevels?.find((level) => level.id === weightedMatrixLevelId).level;
  }

  updateWeightedMatrix(selectedStatus: ServedStatus) {
    this.weightedMatrix.servedStatus = selectedStatus;
    this.weightedMatrixService.updateWeightedMatrix(this.weightedMatrix.id, this.weightedMatrix).subscribe((updateResponse) => {
      if (!updateResponse.succeeded) {
        this.notificationService.error('Failed to updated served status.');
      }
    });
  }

  questionsSubmitted(questions: WeightedMatrixSpecialCircumstanceDto) {
    this.weightedMatrix.weightedMatrixSpecialCircumstance = questions;
    if (this.weightedMatrix?.weightedMatrixSpecialCircumstance?.isFinalized) {
      this.notificationService.success('Special circumstance questions saved.');
      this.weightedMatrixService.weightedMatrixSpecialCircumstancesUpdated.next(null);
    }
  }

  ngOnDestroy(): void {
    this.weightedMatrixSubscription$?.unsubscribe();
  }
}
