import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { LearnerSearchResult } from 'src/app/shared/models/learner';
import { AuthService } from '../../../auth/auth.service';
import { AppPermissions } from '../../../permissions';

@Component({
  selector: 'app-child-search-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnChanges {
  displayedColumns = [
    'selectMatch',
    'lastName',
    'firstName',
    'dateOfBirth',
    'grade',
    'status',
    'owner',
    'annualReviewDate',
    'reevalDate',
    'attendingAea',
    'attendingDistrict',
  ];

  @Input() isLocationOnlySearch: boolean;
  @Input() searchResults: LearnerSearchResult[] = [];
  @Input() referralId: string;

  pageSize = 5;
  pageSizeOptions: number[];
  dataSource = new MatTableDataSource<LearnerSearchResult>();
  shortDateFormat = shortDateFormat;
  addNewLearner() {}

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.isLocationOnlySearch) {
      this.pageSize = 25;
      this.pageSizeOptions = [25, 50, 100];
    } else {
      this.pageSize = 5;
      this.pageSizeOptions = [5, 10, 20, 50];
    }
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
    this.initDataSource();
  }

  ngOnChanges() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource.data = this.searchResults;
  }
  selectLearner(element) {
    this.router.navigate(['/', 'child-find', 'details', element.id], {
      queryParams: {
        sif: element.isSif,
        zoneId: element.zoneId,
        referralId: this.referralId,
        sifRecord: window.btoa(unescape(encodeURIComponent(JSON.stringify(element.sifRecord ?? '')))),
        isLocationOnlySearch: this.isLocationOnlySearch,
      },
    });
  }
}
