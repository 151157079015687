import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { noNumbersValidator, phoneValidator } from 'src/app/shared/validators';
import { AlertConfig } from '../../../shared/components/page-alert/page-alert.component';
import { ReferralService } from '../early-access-referral.service';

@Component({
  selector: 'app-parent-info-form',
  templateUrl: './parent-info-form.component.html',
  styleUrls: ['../early-access-referral.component.scss', './parent-info-form.component.scss'],
})
export class ParentInfoFormComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  emailPhoneRequirement: AlertConfig = {
    status: 'info',
    message: 'Please provide at least one phone number or email address for a parent or family member',
  };

  @Input() parentForm: FormGroup;

  formGroup: FormGroup;
  yesNoOptions = yesNoOptions;
  usStateOptions = usStateOptions;
  referralHowHearAboutUsOptions: KeyValuePair[];
  referralHowHearAboutUsOtherId: string;
  stringSizes = StringSizes;

  constructor(private readonly fb: FormBuilder, private referralService: ReferralService, private locationService: LocationService) {}

  get referralSource() {
    return this.parentForm.get('referralSource').value;
  }

  get othersInHome(): FormArray {
    return this.formGroup.controls.othersInHome as FormArray;
  }

  get referralSourceHowHearAboutUsId() {
    return this.parentForm.get('referralSourceHowHearAboutUsId') as FormControl;
  }

  get referralSourceEarlyAccessOtherText() {
    return this.parentForm.get('referralSourceEarlyAccessOtherText') as FormControl;
  }

  ngOnInit() {
    this.formGroup = this.parentForm.controls.parentInfo as FormGroup;
    this.initializeControls();
    this.referralService.getReferralSourceHowHearAboutUs().subscribe((res) => {
      this.referralHowHearAboutUsOptions = res.map((val) => new KeyValuePair(val.id, val.label));
      this.referralHowHearAboutUsOtherId = res.find((x) => x.isOther)?.id;
    });

    this.formGroup.get('parent1LivesWithChild').valueChanges.subscribe((val) => {
      this.parentLivesWithChildChanged(val, 'parent1');
    });

    this.formGroup.get('parent2LivesWithChild').valueChanges.subscribe((val) => {
      this.parentLivesWithChildChanged(val, 'parent2');
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateReferralSource(event: MatCheckboxChange, parent: string) {
    this.parentForm.get('referralSource').markAsDirty();
    if (event.checked) {
      this.parentForm.get('referralSource').setValue(parent);
    } else {
      this.parentForm.get('referralSource').setValue(null);
    }
    this.parentForm.get('referralSourceHowHearAboutUsId').setValue(null);
  }

  populateLocationFromZipCode(parent: string) {
    const zipCodeControl = this.formGroup.get(parent + 'ZipCode');
    const cityControl = this.formGroup.get(parent + 'City');
    const stateControl = this.formGroup.get(parent + 'State');
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          cityControl.patchValue(res.city);
          stateControl.patchValue('IA');
        }
      },
      () => {
        cityControl.patchValue(null);
        stateControl.patchValue(null);
      }
    );
  }

  private initializeControls() {
    this.formGroup.addControl('parent1Name', new FormControl('', [Validators.required, noNumbersValidator]));
    this.formGroup.addControl('parent1Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl(
      'parent1LivesWithChild',
      new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required],
      })
    );
    this.formGroup.addControl('parent1HomePhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1WorkPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1CellPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestTimeToContact', new FormControl(''));
    this.addParentExtraAddressControls('parent1');

    this.formGroup.addControl('parent2Name', new FormControl('', noNumbersValidator));
    this.formGroup.addControl('parent2Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl('parent2LivesWithChild', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('parent2HomePhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2WorkPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2CellPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestTimeToContact', new FormControl(''));
    this.formGroup.addControl('othersInHome', new FormControl(''));
    this.addParentExtraAddressControls('parent2');

    this.formGroup.setValidators([parent2LivesWithValidator, bestWayToReachValidator('parent1'), bestWayToReachValidator('parent2')]);

    this.formGroup.updateValueAndValidity();

    this.bestWayToContactValidator();
  }

  bestWayToContactValidator(): void {
    /* Parent 1 validators */
    this.formGroup.controls.parent1BestWayToReachHomePhone.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent1HomePhone, checked);
    });

    this.formGroup.controls.parent1BestWayToReachWorkPhone.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent1WorkPhone, checked);
    });

    this.formGroup.controls.parent1BestWayToReachCellPhone.valueChanges.subscribe((checked) => {
      // If Cell Phone is unchecked, make sure to check Texting option
      const textChecked = this.formGroup.controls.parent1BestWayToReachText.value;
      if (!checked && textChecked === true) {
        this.setRequiredValidator(this.formGroup.controls.parent1CellPhone, true);
      } else {
        this.setRequiredValidator(this.formGroup.controls.parent1CellPhone, checked);
      }
    });

    this.formGroup.controls.parent1BestWayToReachText.valueChanges.subscribe((checked) => {
      // If Texting is unchecked, make sure to check Cell Phone option
      const phoneChecked = this.formGroup.controls.parent1BestWayToReachCellPhone.value;
      if (!checked && phoneChecked === true) {
        this.setRequiredValidator(this.formGroup.controls.parent1CellPhone, true);
      } else {
        this.setRequiredValidator(this.formGroup.controls.parent1CellPhone, checked);
      }
    });

    this.formGroup.controls.parent1BestWayToReachEmail.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent1Email, checked);
    });

    /* Parent 2 validators */
    this.formGroup.controls.parent2BestWayToReachHomePhone.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent2HomePhone, checked);
    });

    this.formGroup.controls.parent2BestWayToReachWorkPhone.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent2WorkPhone, checked);
    });

    this.formGroup.controls.parent2BestWayToReachCellPhone.valueChanges.subscribe((checked) => {
      // If Cell Phone is unchecked, make sure to check Texting option
      const textChecked = this.formGroup.controls.parent2BestWayToReachText.value;
      if (!checked && textChecked === true) {
        this.setRequiredValidator(this.formGroup.controls.parent2CellPhone, true);
      } else {
        this.setRequiredValidator(this.formGroup.controls.parent2CellPhone, checked);
      }
    });

    this.formGroup.controls.parent2BestWayToReachText.valueChanges.subscribe((checked) => {
      // If Texting is unchecked, make sure to check Cell Phone option
      const phoneChecked = this.formGroup.controls.parent2BestWayToReachCellPhone.value;
      if (!checked && phoneChecked === true) {
        this.setRequiredValidator(this.formGroup.controls.parent2CellPhone, true);
      } else {
        this.setRequiredValidator(this.formGroup.controls.parent2CellPhone, checked);
      }
    });

    this.formGroup.controls.parent2BestWayToReachEmail.valueChanges.subscribe((checked) => {
      this.setRequiredValidator(this.formGroup.controls.parent2Email, checked);
    });
  }

  setRequiredValidator(control: AbstractControl, isRequired: boolean): void {
    if (isRequired) {
      control.setValidators([Validators.required]);
    } else {
      control.setValidators(null);
    }
    control.updateValueAndValidity();
  }

  private addParentExtraAddressControls(forParent: string) {
    this.formGroup.addControl(forParent + 'StreetAddress', new FormControl(''));
    this.formGroup.addControl(forParent + 'City', new FormControl(''));
    this.formGroup.addControl(forParent + 'ZipCode', new FormControl(''));
    this.formGroup.addControl(forParent + 'State', new FormControl(''));
  }

  private parentLivesWithChildChanged(value: boolean, parent: string) {
    setTimeout(() => {
      if (value === true) {
        this.removeParentExtraAddressValidators(parent);
      }
      if (value === false) {
        this.addParentExtraAddressValidators(parent);
      }
    }, 0);
  }

  private removeParentExtraAddressValidators(forParent: string) {
    const fieldNames = [forParent + 'StreetAddress', forParent + 'City', forParent + 'ZipCode', forParent + 'State'];
    fieldNames.forEach((f) => {
      const control = this.formGroup.get(f);
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  private addParentExtraAddressValidators(forParent: string) {
    const fieldNames = [forParent + 'StreetAddress', forParent + 'City', forParent + 'State'];
    fieldNames.forEach((f) => {
      const control = this.formGroup.get(f);
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    });
    this.formGroup.get(forParent + 'ZipCode').setValidators([Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]);
    this.formGroup.get(forParent + 'ZipCode').updateValueAndValidity();
  }
}

const parent2LivesWithValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const parent2Name = control.get('parent2Name');
  const parent2LivesWithChild = control.get('parent2LivesWithChild');

  return parent2Name && parent2Name.value !== '' && parent2LivesWithChild && typeof parent2LivesWithChild.value !== 'boolean'
    ? { parent2LivesWithChildRequired: true }
    : null;
};

const bestWayToReachValidator =
  (parent: string): ValidatorFn =>
  (control: FormGroup): ValidationErrors | null => {
    const homePhone = control.get(`${parent}HomePhone`);
    const homePhoneIsBest = control.get(`${parent}BestWayToReachHomePhone`);
    const cellPhone = control.get(`${parent}CellPhone`);
    const cellPhoneIsBest = control.get(`${parent}BestWayToReachCellPhone`);
    const textPhoneIsBest = control.get(`${parent}BestWayToReachText`);
    const workPhone = control.get(`${parent}WorkPhone`);
    const workPhoneIsBest = control.get(`${parent}BestWayToReachWorkPhone`);
    const email = control.get(`${parent}Email`);
    const emailIsBest = control.get(`${parent}BestWayToReachEmail`);

    if (
      (!!homePhoneIsBest && !homePhone) ||
      (!!workPhoneIsBest && !workPhone) ||
      (!!cellPhoneIsBest && !cellPhone) ||
      (!!textPhoneIsBest && !cellPhone) ||
      (!!emailIsBest && !email)
    ) {
      const errors = {};
      errors[`${parent}OnePhoneNumberContactRequired`] = true;
      return errors;
    } else {
      return null;
    }
  };
