<mat-card class="mt-2" *ngIf="learners">
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="fullName" matSortDirection="asc" class="mat-elevation-z0">
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"></th>
        <td mat-cell *matCellDef="let element" class="text-left">
          <ng-container>
            <button
              *ngIf="canViewAppointments(element)"
              (click)="onViewAppointments(element.id)"
              aria-label="View Appointments"
              mat-icon-button
              color="primary"
              tabindex="0"
            >
              <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
            </button>
            <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button *ngIf="canEdit" aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit(element)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="canDelete" aria-label="Delete Icon" mat-icon-button color="warn" (click)="onDelete(element)" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Learner Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateOfBirth | dobNoTz | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendingAea">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attending AEA</th>
        <td mat-cell *matCellDef="let element">
          {{ element.attendingAea }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendingDistrict">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attending District</th>
        <td mat-cell *matCellDef="let element">
          {{ element.attendingDistrict }}
        </td>
      </ng-container>

      <ng-container matColumnDef="building">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Building</th>
        <td mat-cell *matCellDef="let element">
          {{ element.building }}
        </td>
      </ng-container>

      <ng-container matColumnDef="gradeDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
        <td mat-cell *matCellDef="let element">
          <app-dhh-learner-grade-view
            [dateOfBirth]="element.dateOfBirth"
            [gradeDescription]="element.gradeDescription"
          ></app-dhh-learner-grade-view>
        </td>
      </ng-container>

      <ng-container matColumnDef="classroom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Classroom</th>
        <td mat-cell *matCellDef="let element">
          {{ element.classroom }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="8">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
    </table>

    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</mat-card>
