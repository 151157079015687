/* eslint-disable prettier/prettier */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { UserService } from '../../../shared/services/user/user.service';
import { AuthService } from '../../auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { StateComplaintService } from '../../../my-tools/dispute-resolution/state-complaint/shared/services/state-complaint.service';
import { MediationService } from '../../../my-tools/dispute-resolution/mediation/mediation-shared/services/mediation.service';

@Component({
  selector: 'app-interactive-access-login',
  templateUrl: './interactive-access-login.component.html',
  styleUrls: ['./interactive-access-login.component.scss'],
})
export class InteractiveAccessLoginComponent implements OnInit {
  isBusy = false;
  loginFailed = false;

  loginForm: FormGroup;
  options: string[];
  filteredLogins: Observable<string[]>;

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly stateComplaintService: StateComplaintService,
    private readonly mediationService: MediationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', { validators: [Validators.required, Validators.email] }],
      password: ['', { validators: [Validators.required] }],
    });

    this.userService.getInteractiveAccessUsers().subscribe((users) => {
      if (users && users.length) {
        this.options = users.map((u) => u.userName).sort();
        this.filteredLogins = this.email.valueChanges.pipe(
          startWith(''),
          map((value) => this.filter(value))
        );
        this.loginForm.get('password').setValue('fake-password');
      }
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();

    if (this.loginForm.invalid) {
      return;
    }

    this.loginFailed = false;
    this.isBusy = true;

    this.authService.interactiveAccessLogin(this.loginForm.value).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          if (this.authService.isInteractiveAccessUser) {
            if (this.route.snapshot.queryParamMap.get('isConnecting')) {
              this.authService.navigateToInteractiveAccess();
            } else {
              const stateComplaintCases = this.stateComplaintService.getStateComplaintCasesByUserId(this.authService.user.id);
              const mediationCases = this.mediationService.getCaseList({});
              forkJoin([stateComplaintCases, mediationCases]).subscribe(([stateComplaintCases, mediationCases]) => {
                if (stateComplaintCases.length > 1) {
                  this.authService.navigateToStateComplaintDashboard();
                } else if (stateComplaintCases.length == 1) {
                  const stateComplaintCase = stateComplaintCases[0];
                  this.authService.navigateToStateComplaintCaseDetails(stateComplaintCase.id);
                } else if (mediationCases.length > 1) {
                  this.router.navigate(['/', 'my-tools', 'dispute-resolution', 'mediation']);
                } else if (mediationCases.length == 1) {
                  const mediationCase = mediationCases[0];
                  this.router.navigate(['/', 'my-tools', 'dispute-resolution', 'mediation', mediationCase.id, 'dashboard']);
                } else {
                  this.authService.navigateToStateComplaintDashboard();
                }
              });
            }
          } else {
            console.log('Not an Interactive Access user');
            this.loginFailed = true;
          }
        }
        this.isBusy = false;
      },
      (err: any) => {
        this.loginFailed = true;
        this.isBusy = false;
      }
    );
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
