import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PopupProxy } from './services/popup-proxy';

export interface NewWindowConfig {
  external?: boolean;
  isPreview?: boolean;
  path: string;
  params?: { [key: string]: any };
  popup?: boolean;
  width?: string;
}

/**
 * Shortcut to openNewWindow with the popup set to true
 * @param config Can be a string url or a complete config
 */
export const openPopup = (config: string | NewWindowConfig) => {
  if (typeof config === typeof '') {
    config = {
      external: false,
      isPreview: false,
      params: {},
      popup: true,
      width: '1000px',
      path: config as string,
    };
  } else {
    (config as NewWindowConfig).popup = true;
  }
  return openNewWindow(config as NewWindowConfig);
};

export const openNewWindow = (config: string | NewWindowConfig) => {
  if (typeof config === typeof '') {
    config = {
      external: false,
      isPreview: false,
      params: {},
      popup: false,
      width: '1000px',
      path: config as string,
    };
  }
  const { external = false, isPreview = false, params = {}, popup = false, width = '1000px' } = config as NewWindowConfig;
  let path = (config as NewWindowConfig).path;
  if (path.startsWith('/')) {
    path = path.substring(1);
  }
  const origin = window.location.origin;
  const windowOpt = popup ? `toolbar=no,scrollbars=no,resizable=yes,top=200,left=500,width=${width},height=740` : '';
  const flattenedParams = new URLSearchParams(params).toString();
  const url = `${external ? `${path}` : `${origin}/${path}`}?popup=${popup}&preview=${isPreview}${
    flattenedParams ? '&' + flattenedParams : ''
  }`;
  return window.open(url, '_blank', windowOpt);
};

export const popupChecker = (route: ActivatedRoute): boolean => {
  const popupParam = route.snapshot.queryParamMap.get('popup');

  if (popupParam) {
    return popupParam === 'true';
  } else {
    return false;
  }
};

export const openPrintWindow = (dialog: MatDialog, dialogComponent: any, printData: any): MatDialogRef<any, any> =>
  dialog.open(dialogComponent, {
    width: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    maxWidth: '100vw',
    hasBackdrop: false,
    data: {
      printData,
    },
    panelClass: 'print__modal',
  });

export const openDocumentWindow = (learnerId: string, documentId: string) => openNewWindow(`api/documents/${learnerId}/${documentId}`);

export const openPdfWindow = (learnerId: string, documentId: string) => openPopup(`documents/${learnerId}/${documentId}`);

export const openDraftPdfWindow = (res: ArrayBuffer) => {
  const page = openPopup('documents/draft');
  window.addEventListener('message', (event) => {
    if (event.data == 'loaded' && event.origin == page.origin) {
      PopupProxy.draftPdf.postMessage(res);
      window.removeAllListeners('message');
    }
  });
};

export const openPdfWindowByDocid = (documentId: string) => openPopup(`documents/docid/${documentId}`);
