import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { KeyValuePair } from '../shared/models/key-value-pair';
import { AlertConfig } from '../shared/components/page-alert/page-alert.component';
import { FamilyMemberService } from '../shared/services/family-member/family-member.service';
import { FamilyMemberNeedingValidationDto } from '../portal/model/family-member-dto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  uniqueLearnerDistricts: KeyValuePair[];
  alert: AlertConfig = {
    status: 'warning',
    message: 'There are family contacts awaiting validation.',
  };
  familyMembersNeedingValidation: Array<FamilyMemberNeedingValidationDto> = [];

  get title() {
    return 'My Dashboard';
  }

  get canViewCaseList() {
    return !this.authService.isSuperAdmin && (!this.authService.isUserWithDhhOnlyRoles || this.authService.isDhhAudiologist);
  }

  get canViewPendingAppointments() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler;
  }

  get canViewDhhCalendar() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isDhhAudiometrist;
  }

  get canViewAchieveCalendar() {
    return !this.authService.isDhhUser || (!this.authService.isUserWithDhhOnlyRoles && this.authService.isDhhAudiologist);
  }

  get isUserWithDhhOnlyRoles() {
    return this.authService.isUserWithDhhOnlyRoles;
  }

  get shouldRouteToDhhChildSearch() {
    return !this.authService.isDhhScheduler && !this.authService.isDhhAudiologist && !this.authService.isDhhAudiometrist;
  }

  constructor(
    private authService: AuthService,
    private readonly router: Router,
    private readonly familyMemberService: FamilyMemberService
  ) {
    if (this.isUserWithDhhOnlyRoles && this.shouldRouteToDhhChildSearch) {
      this.routeToDhhChildSearch();
    }
  }

  ngOnInit() {
    this.familyMemberService.getFamilyMembersNeedingValidation().subscribe((res) => {
      if (res.succeeded) {
        this.familyMembersNeedingValidation = res.value;
      }
    });
  }

  routeToDhhChildSearch() {
    this.router.navigate(['/', 'dhh', 'child-search']);
  }

  onNavigateToValidationDashboard() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/family-member-validation-dashboard']));
    window.open(url, '_blank');
  }
}
