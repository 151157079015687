import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dhh-reporting',
  templateUrl: './dhh-reporting.component.html',
  styleUrls: ['./dhh-reporting.component.scss'],
})
export class DhhReportingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
