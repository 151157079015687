<div class="calendar">
  <div class="calendar__header">
    <h3 class="mb-0">DHH Calendar</h3>
  </div>

  <app-help-directional-text [outsideCard]="true">
    <p class="mb-2">Click on any event in the calendar to see additional details.</p>
  </app-help-directional-text>

  <mat-card class="card__calendar">
    <div class="display-flex justify-content-end align-items-center flex-wrap">
      <ng-container [formGroup]="filterFormGroup">
        <app-autocomplete
          formControlName="soundBoothLocationId"
          [options]="dhhSoundBooths"
          class="mr-2"
          label="Filter by Booth"
          aria-labelledby="Filter by Booth"
        ></app-autocomplete>

        <app-autocomplete formControlName="districtId" [options]="dhhDistricts" class="mr-2" label="Filter by District"></app-autocomplete>

        <app-checkbox-single
          formControlName="onlyMyAppointments"
          class="mr-2"
          label="Only My Appointments"
          aria-labelledby="Only My Appointments"
        ></app-checkbox-single>

        <app-clear-form-button [formGroup]="filterFormGroup" class="mr-2"></app-clear-form-button>

        <button mat-raised-button color="primary" label="Add New" aria-labelledby="Add New" (click)="addNewEvent()" tabindex="0">
          Add New
        </button>
      </ng-container>
    </div>

    <full-calendar [options]="calendarOptions"></full-calendar>

    <div #dhhEventDetailScrollZone></div>
    <div *ngIf="selectedEvent">
      <hr class="input__divider" />

      <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
        <header>
          <h3 class="my-0">Event: {{ selectedEventTitle }}</h3>
        </header>
        <div class="display-flex align-items-center justify-content-between flex-wrap">
          <div class="flex-1" *ngIf="canEditEvent">
            <button mat-raised-button aria-label="Edit Button" color="primary" (click)="editEvent()" class="mr-2" tabindex="0">Edit</button>
          </div>
          <div class="flex-1" *ngIf="canCancelEvent">
            <button
              mat-raised-button
              aria-label="Cancel Button"
              color="primary"
              [disabled]="selectedEvent.hasOpenAppointment || isEventPast"
              (click)="onCancel()"
              class="mr-2"
              tabindex="0"
            >
              Cancel Event
            </button>
          </div>
          <div class="flex-1">
            <button mat-raised-button aria-label="Close Button" color="primary" (click)="onEventDetailClose()" tabindex="0">Close</button>
          </div>
        </div>
      </div>

      <div>
        <p class="my-0">
          {{ selectedEventSubTitle }}
        </p>
      </div>

      <hr class="input__divider" />

      <ng-container *ngIf="selectedEvent.appointmentType?.label === 'Sound Booth'">
        <app-dhh-event-detail [dhhEvent]="selectedEvent"></app-dhh-event-detail>
      </ng-container>
    </div>
  </mat-card>
</div>
