import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { DhhLearnerDto, DhhLearnerScreeningDto, DhhScreeningSource } from '../../../../models/DhhDtos';
import { DhhLookupsService } from '../../../../services/dhh-lookups.service';
import { DhhService } from '../../../../services/dhh.service';
import { DhhScreeningPassCriteria } from '../../../dhh-administration/shared/models/testing-equipment.models';
import { DhhScreeningService } from '../../../dhh-screening/shared/dhh-screening.service';
import { getMonthDayYearString } from '../../../../../shared/dateTimeHelpers';

@Component({
  selector: 'app-dhh-learner-screening-form',
  templateUrl: './dhh-learner-screening-form.component.html',
  styleUrls: ['./dhh-learner-screening-form.component.scss'],
})
export class DhhLearnerScreeningFormComponent implements OnInit {
  pageTitle = 'Edit Screening Information';
  screeningOptions: KeyValuePair[] = [];
  screeningId: string;
  learnerId: string;
  learner: DhhLearnerDto;
  passCriteria: DhhScreeningPassCriteria[] = [];
  screeningSource: DhhScreeningSource;
  learnerScreening: DhhLearnerScreeningDto;
  isSaving = false;
  screeningNotesRequired = false;
  formGroup = this.fb.group({
    id: [],
    screeningDate: [null, [Validators.required]],
    screeningOptionId: [null, [Validators.required]],
    screeningNotes: [null],
  });
  today = dayjs().startOf('day').toDate();

  get isFormGroupEdited() {
    return (
      !this.learnerScreening?.id ||
      getMonthDayYearString(this.learnerScreening.screeningDate) !== getMonthDayYearString(this.formGroup.get('screeningDate').value) ||
      this.learnerScreening.screeningOptionId !== this.formGroup.get('screeningOptionId').value ||
      this.formGroup.get('screeningNotes').value
    );
  }

  constructor(
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhScreeningService: DhhScreeningService,
    private readonly dhhService: DhhService,
    private readonly spinnerService: SpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DhhLearnerScreeningFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.data.learnerId;
    this.screeningId = this.data.screeningId;
    this.passCriteria = this.data.passCriteria;
    this.screeningSource = this.data.screeningSource;

    await this.getLookups().then(() => {
      if (this.screeningId) {
        this.getLearnerScreeningInfo();
      } else {
        this.pageTitle = 'Add Screening Information';
        this.getLearner();
      }
    });
  }

  setFormSubscription() {
    this.formGroup.controls.screeningDate.valueChanges.subscribe(() => {
      if (!this.screeningNotesRequired) {
        this.screeningNotesRequired = true;
        this.formGroup.controls.screeningNotes.setValidators(Validators.required);
        this.formGroup.controls.screeningNotes.updateValueAndValidity();
      }
    });
    this.formGroup.controls.screeningOptionId.valueChanges.subscribe(() => {
      if (!this.screeningNotesRequired) {
        this.screeningNotesRequired = true;
        this.formGroup.controls.screeningNotes.setValidators(Validators.required);
        this.formGroup.controls.screeningNotes.updateValueAndValidity();
      }
    });
  }

  getLearnerScreeningInfo() {
    setTimeout(() => this.spinnerService.incrementLoading(), 100);
    this.dhhScreeningService.getScreening(this.screeningId).subscribe(
      async (result) => {
        if (result.succeeded) {
          this.learnerScreening = result.value;
          this.learner = this.learnerScreening.learner;
          this.formGroup.controls.id.setValue(this.learnerScreening.id);
          this.formGroup.controls.screeningDate.setValue(this.learnerScreening.screeningDate);
          this.formGroup.controls.screeningOptionId.setValue(this.learnerScreening.screeningOptionId);

          setTimeout(() => this.setFormSubscription(), 100);
        } else {
          this.dhhScreeningService.handleError('Failed to retrieving screening information', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      },
      (error) => {
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
        this.dhhScreeningService.handleError('There was an error while retrieving screening information', error);
      }
    );
  }

  getLearner() {
    setTimeout(() => this.spinnerService.incrementLoading(), 100);
    this.dhhService.getLearner(this.learnerId).subscribe(
      (result) => {
        if (result.succeeded) {
          this.learner = result.value;
          this.initNewScreeningFormValues();
        } else {
          this.dhhService.handleError('Failed to load learner data', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      },
      (error) => {
        this.dhhService.handleError('There was an error while retrieving learner data', error);
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      }
    );
  }

  async getLookups() {
    const screeningOptionsLookup = await this.dhhLookupService.getScreeningOptions().toPromise();
    this.screeningOptions = screeningOptionsLookup.value.map((r) => new KeyValuePair(r.id, r.label));
  }

  initNewScreeningFormValues() {
    if (this.learner?.isDoNotTest) {
      const doNotTestscreeningOptionId = this.screeningOptions.find((o) => o.value === 'Do Not Test')?.key;
      if (doNotTestscreeningOptionId) {
        this.formGroup.controls.screeningOptionId.setValue(doNotTestscreeningOptionId);
      }
    }

    this.formGroup.controls.screeningDate.setValue(this.today);
  }

  onCancel() {
    this.closeModal(false);
  }

  onSubmit() {
    this.isSaving = true;

    if (this.formGroup.valid) {
      const screeningDto = this.formGroup.value as DhhLearnerScreeningDto;
      screeningDto.learnerId = this.learnerId;
      screeningDto.passCriteriaJson =
        this.passCriteria?.length > 0
          ? JSON.stringify(
              this.passCriteria.map((p) => {
                return { frequency: p.frequencyLabel, decibel: p.decibelLabel };
              })
            )
          : null;
      screeningDto.screeningSource = this.screeningSource;

      this.dhhScreeningService.saveScreening(screeningDto).subscribe(
        (result) => {
          if (result.succeeded) {
            this.closeModal(true);
          } else {
            this.dhhScreeningService.handleError('Failed to save screening information.', result);
          }
        },
        (error) => {
          this.dhhScreeningService.handleError('There was an error while saving screening information.', error);
        },
        () => {
          this.isSaving = false;
        }
      );
    } else {
      this.dhhScreeningService.handleError('Please completed all required fields', {
        message: 'Please completed all required fields and try again',
      });
    }
  }

  private closeModal(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
