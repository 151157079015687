import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { CaseSummary, ExitCaseInfo, ExitStep, ExitStepType, IntakeType } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { DateToUtcPipe } from '../../../shared/pipes/date-transform.pipe';
import { CaseService } from '../../../shared/services/case/case.service';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { AlertDialogComponent, NotificationService } from '../../../shared/services/notification.service';
import { RoutingService } from '../../../shared/services/routing.service';
import { openNewWindow } from '../../../shared/windowHelpers';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-exiting-section',
  templateUrl: 'exiting-section.component.html',
  styleUrls: ['exiting-section.component.scss'],
})
export class ExitingSectionComponent extends BaseComponent implements OnInit {
  @Input() public case: CaseSummary;
  @Output() cancelExitEvent = new EventEmitter<string>();
  @Output() finalizeExitEvent = new EventEmitter<string>();

  @ViewChild('ecoSection', { read: ElementRef })
  private ecoSectionElem: ElementRef;

  public exitOptions: Array<KeyValuePair>;
  public formGroup: FormGroup;
  public nextSteps: Array<ExitStep>;
  public canFinalize = false;
  public canEdit = false;
  intakeTypeEnum = IntakeType;
  date = dayjs().toDate();

  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    deactivationService: DeactivationService,
    private readonly routingService: RoutingService,
    private authService: AuthService
  ) {
    super(deactivationService);
    this.debounceSavingTime = 1;
  }

  get incompleteItems() {
    return this.nextSteps?.filter((x) => x.name !== 'ecoForm' && x.name !== 'pkEcoForm');
  }

  get displayEcoForm() {
    if (this.case?.intakeType === this.intakeTypeEnum.PartC || this.case?.intakeType === this.intakeTypeEnum.PartCLate) {
      return this.nextSteps?.some((s) => s.name === 'ecoForm');
    }

    return (
      this.case?.intakeType === this.intakeTypeEnum.PartB && this.case?.learner?.isPK && this.nextSteps?.some((s) => s.name === 'pkEcoForm')
    );
  }

  get exitInFuture() {
    const exitDateControl = this.formGroup.get('exitDate');
    if (!!exitDateControl) {
      const exitDate = dayjs(exitDateControl.value).toDate();
      return exitDate >= this.date;
    }
    return true;
  }

  async ngOnInit(): Promise<void> {
    this.loadNextSteps();
    const response = await this.caseService.getCaseExitReasons(this.case.id).toPromise();
    this.exitOptions = response.value;
    this.formGroup = this.formBuilder.group({
      exitDate: [this.case.exitDate, Validators.required],
      exitReasonId: [
        {
          value: this.case.exitReasonId,
          disabled: this.case.isSystemExit || this.case.exitReasonId != null,
        },
        this.case.isSystemExit ? null : Validators.required,
      ],
      childFindExitReasonId: [
        {
          value: this.case.childFindExitReasonId,
          disabled: this.case.isSystemExit,
        },
      ],
      childFindExitDate: [
        {
          value: this.case.childFindExitDate,
          disabled: this.case.isSystemExit,
        },
      ],
      isSystemExit: [this.case.isSystemExit],
    });
    this.canEdit = this.authService.isSuperAdmin || this.authService.isCaseOwner(this.case.caseId);
    if (!this.canEdit) {
      this.formGroup.controls.exitReasonId.disable();
    } else {
      this.initializeFromFormGroup(this.formGroup, (value) => this.saveExitingData(value));
      this.startAutosaving();
    }
  }

  public goToStep(step: ExitStep): void {
    switch (step.name) {
      case ExitStepType.pwn:
        this.goToPwnStep(step.data?.pwnId);
        break;
      case ExitStepType.postsecondary:
        this.goToPostsecondarySummary(step.data?.learnerId);
        break;
      case ExitStepType.eco:
        this.scrollToEcoSection();
        break;
      case ExitStepType.pkEco:
        this.scrollToEcoSection();
        break;
      case ExitStepType.transitionPlanning:
        this.goToTransitionPlanning(step.data?.learnerId);
        break;
      case ExitStepType.evaluationAndAssessment:
        this.goToEvaluationOverview(step.data?.caseId, step.data?.evaluationId);
        break;
      case ExitStepType.reevaluation:
        this.goToEvaluation(step.data?.learnerId);
        break;
      case ExitStepType.reevaluationMeeting:
        this.openMeetingScheduler(step.data?.learnerId);
        break;
    }
  }

  public goToPwnStep(pwnId: string): void {
    let path = `cases/${this.case.id}/pwns`;
    if (pwnId) {
      path += `/${pwnId}`;
      openNewWindow({
        path,
        popup: true,
      });
    } else {
      this.caseService.preExitDraftPwns(this.case.id).subscribe((result) => {
        if (result.succeeded && result.value?.length > 0) {
          //TODO: waiting for the right message from IA/PMs
          this.dialog.open(AlertDialogComponent, {
            data: {
              title: 'Case Exit PWN',
              message: 'An active Prior Written Notice already exists for this learner. Please complete the draft PWN first.',
            },
          });
        } else {
          openNewWindow({
            path,
            popup: true,
          });
        }
      });
    }
  }

  public goToPostsecondarySummary(learnerId: string): void {
    this.router.navigateByUrl(`learner-management/${learnerId}/postsecondary-summary`);
  }

  public goToTransitionPlanning(learnerId: string): void {
    this.router.navigateByUrl(`learner-management/${learnerId}/transition-planning`);
  }

  public goToEvaluation(learnerId: string): void {
    this.router.navigateByUrl(`learner-management/${learnerId}/evaluation`);
  }

  public goToEvaluationOverview(caseId: string, evaluationId: string): void {
    this.router.navigateByUrl(`cases/${caseId}/evaluation/${evaluationId}/overview`);
  }

  public openMeetingScheduler(learnerId: string): void {
    this.routingService.openScheduleMeeting(learnerId);
  }

  scrollToEcoSection() {
    this.ecoSectionElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  public finalizeExit(): void {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will finalize the exit process.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.caseService.finalizeExit(this.case.learnerId, this.case.id).subscribe(
          (operationResult) => {
            if (operationResult.succeeded) {
              this.finalizeExitEvent.emit(this.case.id);
              this.notificationService.success('Successfully finalized!');
            } else {
              this.notificationService.error('Failed to finalized case exit.');
            }
          },
          (error) => {
            this.handleError(error);
          }
        );
      }
    });
  }

  public cancelExit(): void {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove any in-process documents and cancel the exit process.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.caseService.cancelExit(this.case.learnerId, this.case.id).subscribe(
          (operationResult) => {
            if (operationResult.succeeded) {
              this.cancelExitEvent.emit(this.case.id);
              this.notificationService.success('Successfully cancelled!');
            } else {
              this.notificationService.error('Failed to cancel case exit.');
            }
          },
          (error) => {
            this.handleError(error);
          }
        );
      }
    });
  }

  async ecoRatingUpdated() {
    await this.loadNextSteps();
  }

  private async loadNextSteps(): Promise<void> {
    this.canFinalize = false;
    const result = await this.caseService.getExitNextSteps(this.case.id).toPromise();
    this.nextSteps = result.value;
    this.canFinalize = this.incompleteItems.length === 0;
  }

  private async saveExitingData(formValue: any): Promise<void> {
    this.canFinalize = false;
    const exitInfo = {
      exiting: true,
      ...formValue,
    } as ExitCaseInfo;
    if (exitInfo.exitDate) {
      exitInfo.exitDate = new DateToUtcPipe().transform(new Date(exitInfo.exitDate));
    }
    if (exitInfo.exitReasonId) {
      this.case.exitReasonId = exitInfo.exitReasonId;
      this.formGroup.controls.exitReasonId.disable();
    }
    if (exitInfo.isSystemExit === true || this.case.exitReasonId != null) {
      exitInfo.childFindExitReasonId = this.case.childFindExitReasonId;
      exitInfo.childFindExitDate = this.case.childFindExitDate;
      exitInfo.exitReasonId = this.case.exitReasonId;
    }

    this.caseService.updateExitingInfo(this.case.id, exitInfo).subscribe(
      async () => {
        await this.loadNextSteps();
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleError(error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.error(errorMessage);
  }
}
