import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDocument } from 'src/app/shared/models/file-document';
import { LetterDocument } from '../../models/letter-document';
import { openPopup } from '../../windowHelpers';
import { downloadBlob } from '../../downloadHelpers';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private baseUrl(learnerId: string) {
    return `api/documents/${learnerId}`;
  }

  constructor(private http: HttpClient) {}

  getAll(caseId: string) {
    return this.http.get<FileDocument[]>(`api/cases/${caseId}/documents`);
  }

  getDocument(learnerId: string, documentId: string) {
    return this.http.get(this.baseUrl(learnerId) + `/${documentId}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  getGenericDocument(documentId: string, observeResponse = false) {
    const options = { responseType: 'arraybuffer' as 'json' };
    if (observeResponse) {
      options['observe'] = 'response';
    }
    return this.http.get('api/documents/generic/docid' + `/${documentId}`, options);
  }

  getAllForLearner(learnerId: string) {
    return this.http.get<FileDocument[]>(this.baseUrl(learnerId));
  }

  uploadDocuments(formData: FormData, learnerId: string) {
    return this.http.post<FileDocument[]>(this.baseUrl(learnerId), formData);
  }

  getMergedDocument(documentIds: string[]) {
    let params = new HttpParams();
    documentIds.forEach((documentId) => (params = params.append('documentIds', documentId)));
    return this.http.get('api/documents/print', { params });
  }

  updateDocument(documentId: string, document: any) {
    return this.http.put(`api/documents/${documentId}`, document);
  }

  deleteDocument(documentId: string) {
    return this.http.post(`api/documents/delete/${documentId}`, documentId);
  }

  getLettersForLearner(learnerId: string) {
    return this.http.get<LetterDocument[]>(this.baseUrl(learnerId) + '/letters');
  }

  downloadOrViewDocument(document: { id: string; type: string; fileName: string }) {
    if (document.type === 'application/pdf') {
      openPopup(`documents/generic/docid/${document.id}`);
    } else {
      this.getGenericDocument(document.id).subscribe((file: any) => {
        const blob = new Blob([file], { type: document.type });
        downloadBlob(blob, document.fileName);
      });
    }
  }
}
