import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { LoggerService } from '../../../services/logger/logger.service';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
})
export class NumberFieldComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @Input() showAsterisk = false;
  @Input() hideAsterisk = false;
  @Input() useInputGroup = false;
  @Input() min: number;
  @Input() max: number;
  @Input() step = 1;
  // todo this component is using value accessor. We SHOULD be able to use the native blur but I couldn't get it to work
  @Output() blurMe = new EventEmitter<any>();
  @Input() shouldAllowNegatives = true;
  @Input() shouldAllowDecimals = true;
  @Input() tabindex = 0;

  private keysToRemove = ['e', '+', '.', '-'];

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.shouldAllowNegatives) this.keysToRemove = this.keysToRemove.filter((k) => k !== '-');
    if (this.shouldAllowDecimals) this.keysToRemove = this.keysToRemove.filter((k) => k !== '.');
  }

  internalBlur(event: any) {
    if (this.control && this.control.value !== null && this.control.value !== undefined) {
      const value = parseFloat(this.control.value);

      if (!Number.isInteger(this.step)) {
        let roundedValue;

        if (Math.floor(value * 1000) % 10 < 5) {
          roundedValue = Math.floor(value * 100) / 100;
        } else {
          roundedValue = Math.ceil(value * 100) / 100;
        }

        this.control.setValue(roundedValue);

        if (this.max !== undefined && roundedValue > this.max) {
          this.control.setValue(this.max);
        } else if (this.min !== undefined && roundedValue < this.min) {
          this.control.setValue(this.min);
        }
      } else {
        if (this.max !== undefined && value > this.max) {
          this.control.setValue(this.max);
        } else if (this.min !== undefined && value < this.min) {
          this.control.setValue(this.min);
        }
      }
    }
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }

  filter(event: KeyboardEvent) {
    return !this.keysToRemove.includes(event.key);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
