<h3 mat-dialog-title class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Audiogram</h3>

<div mat-dialog-content class="mat-dialog-content py-3">
  <div class="audiogram-wrapper">
    <app-audiogram-chart
      [previousValues]="data.previousValues"
      [conductions]="data.conductions"
      [frequencies]="data.frequencies"
    ></app-audiogram-chart>
  </div>
</div>

<div mat-dialog-actions class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" color="primary" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
