<mat-accordion class="accordion accordion--close accordion--short" *ngIf="!inCard; else showCard">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex flex-1 justify-content-between align-items-center">
          <h3 class="my-0 text-normal">Family Consent</h3>
          <span
            matTooltip="Procedural Safeguards must be provided to the family in order to access any consent(s)"
            [matTooltipDisabled]="availConsentFormTypeOptions.length !== 0"
          >
            <button
              mat-raised-button
              color="primary"
              aria-label="Add New Family Consent"
              *ngIf="canAddNew && availConsentFormTypeOptions.length !== 0 && learnerHasWorkableCase && hasEditPermission"
              (click)="openAddConsentDialog($event)"
              tabindex="0"
            >
              Add New
            </button>
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="consentContent"></ng-container>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #showCard>
  <div class="display-flex justify-content-between align-items-center mb-2">
    <h3 class="mb-0" *ngIf="!inCard">Family Consent</h3>
    <div class="display-flex justify-content-end flex-1">
      <span
        matTooltip="Procedural Safeguards must be provided to the family in order to access any consent(s)"
        [matTooltipDisabled]="availConsentFormTypeOptions.length !== 0"
      >
        <button
          mat-raised-button
          color="primary"
          aria-label="Add New Family Consent"
          *ngIf="canAddNew && availConsentFormTypeOptions.length !== 0 && learnerHasWorkableCase && hasEditPermission"
          (click)="openAddConsentDialog($event)"
          tabindex="0"
        >
          Add New
        </button>
      </span>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="consentContent"></ng-container>
</ng-template>

<ng-template #consentContent>
  <div class="row">
    <div class="col-md-12">
      <mat-card class="pt-1 card--none">
        <app-help-directional-text>
          <p>Click on the consent to view{{ authService.isVrUser ? '' : '/sign' }}.</p>
        </app-help-directional-text>

        <div class="table-overflow">
          <mat-accordion displayMode="flat" multi class="mat-table">
            <section matSort class="mat-header-row">
              <span class="mat-header-cell mat-header-cell--actions"></span>
              <span (click)="onSortHeader('consentType')" class="mat-header-cell" mat-sort-header="consentType">Consent Type</span>
              <span (click)="onSortHeader('notes')" class="mat-header-cell" mat-sort-header="notes">Notes</span>
              <span (click)="onSortHeader('status')" class="mat-header-cell" mat-sort-header="status">Status</span>
              <span (click)="onSortHeader('date')" class="mat-header-cell" mat-sort-header="date">Date</span>
            </section>
            <mat-expansion-panel
              *ngFor="let consentForm of filteredFamilyConsentData"
              class="mat-elevation-z border-bottom color-fix"
              #mep="matExpansionPanel"
            >
              <!-- TODO: replace with check for econsent-->
              <mat-expansion-panel-header class="mat-row">
                <span class="mat-cell mat-cell--actions" *ngIf="!isPortalUser">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="actionsMenu"
                    [disabled]="actionsDisabled(consentForm)"
                    *ngIf="learnerHasWorkableCase && hasEditPermission"
                    tabindex="0"
                    aria-label="Quick Access Menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu">
                    <ng-container *ngIf="consentForm?.documents?.length === 0">
                      <button
                        class="mr-1"
                        aria-label="Upload Document"
                        *ngIf="consentForm.type === consentFormType.PostReferralScreening && canUploadDocument(consentForm)"
                        mat-menu-item
                        (click)="uploadConsentIntakeDocument($event, consentForm.caseId, consentFormType.PostReferralScreening)"
                        tabindex="0"
                      >
                        Upload Document
                      </button>
                      <button
                        class="mr-1"
                        aria-label="Upload Document"
                        *ngIf="consentForm.type !== consentFormType.PostReferralScreening && canUploadDocument(consentForm)"
                        mat-menu-item
                        (click)="uploadConsentStatusDocument($event, consentForm, consentForm.type)"
                        tabindex="0"
                      >
                        Upload Document
                      </button>
                    </ng-container>

                    <button
                      *ngIf="consentForm?.status !== consentStatus.Editing"
                      mat-menu-item
                      aria-label="Update Notes"
                      (click)="
                        mep.expanded = !mep.expanded; openNotesDialog($event, consentForm.caseId, consentForm.statusId, consentForm.notes)
                      "
                      tabindex="0"
                    >
                      Update Notes
                    </button>
                    <button
                      *ngIf="
                        isSuperAdmin ||
                        (consentForm?.status !== consentStatus.Editing &&
                          consentForm?.status !== consentStatus.Denied &&
                          consentForm?.status !== consentStatus.Revoked &&
                          consentForm?.status !== consentStatus.Inactive &&
                          (checkCaseOwner(consentForm.caseId) || checkReevaluationConsent(consentForm)))
                      "
                      mat-menu-item
                      aria-label="Add Status"
                      (click)="mep.expanded = !mep.expanded; openDialog($event, consentForm)"
                      tabindex="0"
                    >
                      Add Status
                    </button>
                  </mat-menu>
                </span>
                <span class="mat-cell display-flex align-items-center">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="openDocument(consentForm?.documents); $event.stopPropagation()"
                    *ngIf="consentForm?.documents?.length > 0"
                    tabindex="0"
                    aria-label="Open Document"
                  >
                    <mat-icon class="mr-2 icon--consent" aria-labelledby="description"> description </mat-icon>
                  </button>

                  <a
                    *ngIf="
                      consentForm.type === consentFormType.FiieConsent &&
                      fiieConsentFormId !== null &&
                      (consentForm?.status === consentStatus.Approved || consentForm?.status === consentStatus.Requested)
                    "
                    [routerLink]=""
                    (click)="openReadOnlyFiie(consentForm.caseId)"
                    target="_self"
                    tabindex="0"
                    role="link"
                  >
                    Consent for Full and Individual Initial Evaluation
                  </a>
                  <a
                    *ngIf="
                      consentForm.type === consentFormType.FiieConsent &&
                      authService.isAllowedByCaseId(
                        consentForm.caseId,
                        requestForFiieConsentAdditionalRequirements,
                        permissions.RequestForFiieConsent
                      ) &&
                      (consentForm.status === consentStatus.Denied ||
                        consentForm.status === consentStatus.Revoked ||
                        consentForm.status === consentStatus.Editing)
                    "
                    [routerLink]="routingService.fiieConsentPath(learnerId, consentForm.caseId)"
                    target="_self"
                    tabindex="0"
                    role="link"
                  >
                    Consent for Full and Individual Initial Evaluation
                  </a>

                  <span *ngIf="consentForm.type !== consentFormType.FiieConsent">
                    {{ consentFormTitlesByType[consentFormType[consentForm.type]] }}
                    {{ consentForm.aeaOrDistrictName ? ': ' + consentForm.aeaOrDistrictName : '' }}
                  </span>
                </span>
                <span class="mat-cell">
                  {{ consentForm.notes }}
                </span>
                <span class="mat-cell">
                  <span>{{ consentForm.status }}</span>
                </span>
                <span class="mat-cell">
                  {{ consentForm?.dateSigned | dateFormat }}
                </span>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div>
                <!-- replace with check for econsent-->
                <app-signed-form-overview
                  [(signedFormId)]="consentForm.signedFormId"
                  [allowDocumentUpload]="false"
                  [learnerId]="learnerId"
                  [parentId]="consentForm.id"
                  [consentFormType]="consentFormType[consentForm.type]"
                  [isRevokable]="false"
                  [allowWetSignature]="false"
                  [caseId]="consentForm.caseId"
                  (signatureCompleted)="refresh($event, consentForm.caseId)"
                  [isComplete]="consentForm.isComplete"
                  [consentStatus]="consentForm.status"
                ></app-signed-form-overview>
                </div>
                <div class="mat-row border-top" *ngFor="let child of consentForm.history">
                  <span class="mat-cell mat-cell--actions"> </span>
                  <span class="mat-cell">
                  <span>
                    <button
                      mat-icon-button
                      color="primary"
                      (click)="openDocument(child?.documents); $event.stopPropagation()"
                      *ngIf="child?.documents?.length > 0"
                      tabindex="0"
                      aria-label="Open Document"
                    >
                      <mat-icon
                        class="mr-2 icon--consent"
                        attr.aria-label="description {{ consentFormTitlesByType[consentFormType[consentForm.type]] }}"
                      >
                        description
                      </mat-icon>
                    </button>

                    {{ consentFormTitlesByType[consentFormType[consentForm.type]] }}
                  </span>
                </span>
                  <span class="mat-cell">
                  {{ child.notes }}
                </span>
                  <span class="mat-cell">
                  <span>{{ child.status }}</span>
                </span>
                  <span class="mat-cell">
                  {{ child.date | dateFormat }}
                </span>
                </div>
              </ng-template>
            </mat-expansion-panel>
            <p class="cell--no-results" *ngIf="filteredFamilyConsentData?.length === 0">
              <span class="text-sm">
                <i>No results...</i>
              </span>
            </p>
          </mat-accordion>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
