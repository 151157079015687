import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthService, Roles } from '../../../auth/auth.service';
import { debounceFormValueChanges } from '../../formHelpers';
import { IntakeType } from '../../models/case';
import { KeyValuePair } from '../../models/key-value-pair';
import { User } from '../../models/user';
import { UserAeaDto } from '../../models/userAeaDto';
import { BuildingService } from '../../services/building/building.service';
import { ProfessionService } from '../../services/profession/profession.service';
import { TeamService } from '../../services/team/team.service';
import { PaginatedList } from '../../models/paginated-list';
import { AeaService } from '../../services/aea/aea.service';

export interface TeamMemberSearchEntity extends User {
  isAdded: boolean;
}

export interface FilterParams<T> {
  buildings: string[];
  professions: string[];
  data: T;
}

export interface TeamSearchModalData {
  users?: User[] | UserAeaDto[];
  config?: { userId: string; intakeType: IntakeType; learnerId: string; isMeeting: boolean; userIds: Array<string> };
  allowedRoles: Roles[];
  dontAllowSelfSelection: boolean;
  showBuildingFilter: boolean;
  showAeaFilter: boolean;
  getTeamMemberFunc?: (config: any) => Promise<PaginatedList<TeamMemberSearchEntity>>;
  displayedColumns: string[];
  modalTitle: string;
  singleSelect: boolean;
  aeas?: any[];
  districts?: any[];
  buildings?: any[];
}

@Component({
  selector: 'app-team-member-search-modal',
  templateUrl: './team-member-search-modal.component.html',
  styleUrls: ['./team-member-search-modal.component.scss'],
})
export class TeamMemberSearchModalComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  @Input('aria-label') ariaLabel: string;

  @ViewChild('teamMemberTable')
  teamMemberTable: ElementRef;

  @ViewChild(MatSort)
  sort: MatSort;

  formGroup = new FormGroup({
    searchTerm: new FormControl(''),
    jobTitle: new FormControl(''),
    building: new FormControl(''),
    aea: new FormControl(''),
    includeResidentInResults: new FormControl(false),
  });

  jobTitleOptions: KeyValuePair[] = [];
  buildingOptions: KeyValuePair[] = [];
  aeaOptions: KeyValuePair[] = [];

  aeas: any[];
  districts: any[];
  buildings: any[];

  teamMembers: TeamMemberSearchEntity[];
  unfilteredMembers: TeamMemberSearchEntity[];

  dataSource: MatTableDataSource<TeamMemberSearchEntity> = new MatTableDataSource();
  displayedColumns = ['fullName', 'actions'];
  title = 'Add Team Members';
  orderBy = 'FullName';
  orderByDescending = false;

  searchCriteria = '';

  currentPage = 0;
  pageSize = 20;
  totalRows = 0;

  loading: boolean;
  singleSelect = false;

  get currentSort() {
    return {
      orderBy: this.orderBy,
      orderByDescending: this.orderByDescending,
    };
  }

  get currentPageInfo(): PageEvent {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  constructor(
    public dialogRef: MatDialogRef<TeamMemberSearchModalComponent>,
    private teamService: TeamService,
    private buildingService: BuildingService,
    private aeaService: AeaService,
    private professionService: ProfessionService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) readonly data: TeamSearchModalData
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.data.displayedColumns) {
      this.displayedColumns = this.data.displayedColumns;
    }

    if (this.data.modalTitle) {
      this.title = this.data.modalTitle;
    }

    this.aeas = this.data?.aeas || [];
    this.districts = this.data?.districts || [];
    this.buildings = this.data?.buildings || [];

    this.singleSelect = !!this.data?.singleSelect;

    await this.getTeamMembers().then(() => {
      this.loading = false;
      this.initializeSortChanges();
    });
    this.initOptions();
    this.initSubscriptions();
  }

  private initializeSortChanges() {
    this.sort?.sortChange.subscribe((sort: Sort) => {
      this.orderBy = sort.active;
      this.orderByDescending = sort.direction === 'desc' ? true : false;
      this.getTeamMembers();
    });
  }

  private async getTeamMembers(searchCriteria?, saveCriteria?: boolean): Promise<void> {
    if (saveCriteria) {
      this.searchCriteria = searchCriteria;
    }

    const { userId, intakeType, learnerId, isMeeting, userIds } = this.data.config;

    const buildingId = this.formGroup.get('building').value;
    const professionId = this.formGroup.get('jobTitle').value;
    const aeaId = this.formGroup.get('aea').value;

    let users: PaginatedList<TeamMemberSearchEntity>;

    if (this.data.getTeamMemberFunc) {
      users = await this.data.getTeamMemberFunc({
        userId,
        userIds: userIds.join(','),
        intakeType,
        currentPage: this.currentPage + 1,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        orderByDescending: this.orderByDescending,
        learnerId,
        isMeeting,
        searchCriteria: this.searchCriteria,
        buildingId,
        professionId,
        aeaId,
      });
    } else {
      const filterParams = await this.teamService
        .getPaginatedTeamUsers(
          userId,
          userIds.join(','),
          intakeType,
          this.currentPage + 1,
          this.pageSize,
          this.orderBy,
          this.orderByDescending,
          learnerId,
          isMeeting,
          this.searchCriteria,
          buildingId,
          professionId,
          aeaId,
          this.data.allowedRoles
        )
        .toPromise();
      users = filterParams.data;
    }

    if (this.data.dontAllowSelfSelection) {
      users.items = users.items.filter((u) => u.userId !== (this.data.config?.userId ?? this.authService.user.id));
    }

    this.dataSource.data = users.items;
    this.unfilteredMembers = this.dataSource.data;
    this.totalRows = users.totalCount;
  }

  async pageChanged(event: PageEvent): Promise<void> {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.scrollToTopOfTable();
    this.loading = true;
    await this.getTeamMembers().then(() => (this.loading = false));
  }

  private scrollToTopOfTable(): void {
    this.teamMemberTable?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onToggleTeamMember(member: TeamMemberSearchEntity) {
    if (this.singleSelect) {
      this.dialogRef.close({
        newUserIds: [member.userId],
        teamUsers: this.dataSource.data,
      });
    } else {
      member.isAdded = !member.isAdded;
    }
  }

  onAccept() {
    const newUserIds = this.dataSource.data.filter((x) => x.isAdded).map((x) => x.userId);

    this.dialogRef.close({
      newUserIds,
      teamUsers: this.dataSource.data,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onFilter() {
    this.getTeamMembers('', false);
  }

  private initSubscriptions(): void {
    this.subscriptions.add(
      debounceFormValueChanges(this.formGroup.get('searchTerm')).subscribe((value: string) => this.getTeamMembers(value, true))
    );
  }

  private initOptions(): void {
    if (this.data.showBuildingFilter) {
      if (this.data.buildings?.length > 0) {
        this.buildingOptions = this.buildings.map((building) => {
          const districtName = this.districts.find((district) => district.id === building.parentId)?.label || '';
          return new KeyValuePair(building.id, `${building.label}, ${districtName}`);
        });
      } else {
        this.buildingService.getAllBuildings().subscribe({
          next: (buildings) =>
            (this.buildingOptions = buildings.map(
              (building) => new KeyValuePair(building.id, `${building.name}, ${building.districtName}`)
            )),
          complete: () => this.buildingOptions.unshift(new KeyValuePair('', 'Select One')),
        });
      }
    }

    if (this.data.showAeaFilter) {
      if (this.data.aeas?.length > 0) {
        this.aeaOptions = this.aeas.map((aea) => {
          return new KeyValuePair(aea.id, aea.name);
        });
      } else {
        this.aeaService.getAllAeas().subscribe({
          next: (aeas) => (this.aeaOptions = aeas.map((aea) => new KeyValuePair(aea.id, aea.name))),
          complete: () => this.aeaOptions.unshift(new KeyValuePair('', 'Select One')),
        });
      }
    }

    this.professionService.getProfessions().subscribe({
      next: (professions) =>
        (this.jobTitleOptions = professions.map((x) => new KeyValuePair(x.id, x.label)).sort((a, b) => a.value.localeCompare(b.value))),
      complete: () => this.jobTitleOptions.unshift(new KeyValuePair('', 'Select One')),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
