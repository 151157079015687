<app-page-header [pageTitle]="'DHH Child Search'" backgroundColor="blue"></app-page-header>

<div *ngIf="isReady">
  <mat-card class="mt-4">
    <mat-card-title class="mb-3">
      Search
      <mat-error class="display-inline text-sm"> Last name, first name, date of birth or state id is required </mat-error>
    </mat-card-title>
    <form [formGroup]="formGroup" (keyup.enter)="onSearch()">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 no-form-field-padding">
            <div class="row">
              <div class="col-lg-12 no-form-field-padding">
                <app-text-field
                  label="Last Name"
                  formControlName="lastName"
                  [showAsterisk]="formGroup.hasError('searchInputRequired')"
                  attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
                ></app-text-field>
              </div>
              <div class="col-lg-12 no-form-field-padding">
                <app-text-field
                  label="First Name"
                  formControlName="firstName"
                  [showAsterisk]="formGroup.hasError('searchInputRequired')"
                  attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
                ></app-text-field>
              </div>
              <div class="col-lg-12 no-form-field-padding">
                <app-date-picker
                  label="DOB"
                  controlName="dateOfBirth"
                  [max]="today"
                  [min]="twentyFiveYearsAgo"
                  [showAsterisk]="formGroup.hasError('searchInputRequired')"
                  attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
                ></app-date-picker>
              </div>
              <!--<div class="col-lg-12 no-form-field-padding">
                <app-radio-group [options]="dataSourceOptions" formControlName="searchDataSource" [useUndo]="false"></app-radio-group>
              </div>-->
            </div>
          </div>

          <div class="col-lg-6 no-form-field-padding">
            <div class="row">
              <div class="col-lg-12 no-form-field-padding">
                <app-number-field
                  label="State ID"
                  formControlName="stateAssignedId"
                  [showAsterisk]="formGroup.hasError('searchInputRequired')"
                  attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
                ></app-number-field>
                <mat-error
                  style="display: inline"
                  *ngIf="formGroup.controls.stateAssignedId.touched && formGroup.get('stateAssignedId').hasError('stateIdInvalidLength')"
                >
                  <span class="text-sm">State ID must be 10 digit numbers</span>
                </mat-error>
              </div>

              <div class="col-lg-12 no-form-field-padding">
                <app-autocomplete label="AEA" formControlName="attendingAeaId" [options]="aeaOptions"></app-autocomplete>
              </div>
              <div class="col-lg-12 no-form-field-padding">
                <app-autocomplete label="District" formControlName="attendingDistrictId" [options]="districtOptions"></app-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card>

  <div class="row">
    <div class="col-lg-12">
      <div class="mt-2 display-flex justify-content-between flex-wrap align-items-start">
        <div class="display-flex">
          <button
            mat-raised-button
            aria-label="Add New Learner"
            color="primary"
            class="align-self-center mr-3"
            [disabled]="!submitAttempted || formGroup.invalid"
            *ngIf="canAddNewLearner"
            (click)="onAddNewLearner()"
            tabindex="0"
          >
            Add New Learner
          </button>
        </div>

        <div class="search-buttons display-flex align-items-end justify-content-end flex-1">
          <div>
            <button
              type="button"
              class="mr-2 btn--icon"
              mat-raised-button
              aria-label="Clear"
              color="primary"
              (click)="clear()"
              [disabled]="!formGroup.dirty"
              tabindex="0"
            >
              Clear
              <svg-icon src="assets/img/icons/Clear.svg" svgClass="svg-btn"></svg-icon>
            </button>

            <button mat-raised-button aria-label="Search" color="primary" (click)="onSearch()" [disabled]="formGroup.invalid" tabindex="0">
              Search
              <i class="fas fa-search ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-child-search-learner-list [learners]="learners" (tablePaginationEvent)="onPaginationChange($event)"></app-child-search-learner-list>
</div>
