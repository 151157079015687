import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import {
  IncompleteDataReportElement,
  IncompleteDataReportItem,
} from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { DeleteConfirmationComponent } from 'src/app/shared/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { addWeekdays } from '../../../shared/dateTimeHelpers';
import { CaseSummary, FamilyMeetingRead } from '../../../shared/models/case';
import { LearnerSummary } from '../../../shared/models/learner';
import { CaseService } from '../../../shared/services/case/case.service';
import { PdfOutputs, ReportingService } from '../../../shared/services/reporting/reporting.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from '../../../shared/windowHelpers';
import { IepView, TrialPlacementTarget } from '../../models/iep';
import { IepAmendment } from '../../models/iep-amendment';
import { IepAccommodationService } from '../../services/iep-accommodation.service';
import { IepActivitySupportService } from '../../services/iep-activity-support.service';
import { IepAmendmentService } from '../../services/iep-amendment.service';
import { IepGoalService } from '../../services/iep-goal.service';
import { IepServiceService } from '../../services/iep-service.service';
import { IepService } from '../../services/iep.service';
import { ClpService } from '../../services/clp.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FeatureFlagService } from '../../../shared/services/feature-flags/feature-flag.service';

@Component({
  selector: 'app-iep-details',
  templateUrl: './iep-details.component.html',
  styleUrls: ['./iep-details.component.scss'],
})
export class IepDetailsComponent implements OnInit, OnDestroy {
  iepId: string;
  caseId: string;
  iep: any;
  pdfOutputs = PdfOutputs;
  learner: LearnerSummary;
  caseSummary: CaseSummary;
  amendment: IepAmendment = null;
  dataLoaded = false;
  pageTitle: string;
  pageTitleColor: string;
  amendments: IepAmendment[] = [];
  incompleteItems: IncompleteDataReportItem[];
  formGroup = new FormGroup({
    includesTrialPlacement: new FormControl(null),
    trialPlacementDate: new FormControl(null),
    trialPlacementTarget: new FormControl(null),
  });
  iepMeetings: FamilyMeetingRead[];
  isSaving = false;
  permissions = AppPermissions;
  isDeleting = false;

  private subscriptions = new Subscription();
  @ViewChild('goals') goals: MatExpansionPanel;
  @ViewChild('plaafp') plaafp: MatExpansionPanel;
  @ViewChild('services') services: MatExpansionPanel;
  @ViewChild('accommodations') accommodations: MatExpansionPanel;
  @ViewChild('activitiesSupports') activitiesSupports: MatExpansionPanel;
  @ViewChild('extendedSchoolYear') extendedSchoolYear: MatExpansionPanel;
  @ViewChild('physicalEducation') physicalEducation: MatExpansionPanel;
  @ViewChild('assessment') assessment: MatExpansionPanel;
  @ViewChild('leastRestrictiveEnv') leastRestrictiveEnv: MatExpansionPanel;
  @ViewChild(MatAccordion) serviceAccordion: MatAccordion;
  isAccordionsExpanded = false;
  today = dayjs().startOf('day').toDate();
  durationDate = dayjs().startOf('day').add(1, 'year');
  fourtyfiveWeekdaysFromNow = addWeekdays(dayjs(), 45);
  tenDaysPrior = dayjs().startOf('day').add(-10, 'day').toDate();
  maxTrialPlacementDate = this.durationDate.toDate();
  ignoreChanges = false;
  learnerId: string;
  needsNewConsent: boolean;
  canCancelTrialPlacement = false;
  canFinalizeIep = false;

  trialPlacementOptions: KeyValuePair[] = [
    new KeyValuePair(TrialPlacementTarget.AllItems, 'All Services and Supports'),
    new KeyValuePair(TrialPlacementTarget.SomeItems, 'Some Services and Supports'),
  ];

  get amendmentIsFinalized() {
    return !!this.amendment?.finalizeDate;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get doesIncludeTrialPlacement() {
    return this.formGroup.get('includesTrialPlacement').value !== null && this.formGroup.get('includesTrialPlacement').value === true;
  }

  get trialPlacementTarget() {
    return this.formGroup.get('trialPlacementTarget').value;
  }

  get incompleteItemsAmount() {
    if (!this.incompleteItems) {
      return '';
    }
    return `${this.incompleteItems.length} Notices`;
  }

  get canPushToPortal() {
    return this.hasOpenAmendment && this.featureFlagService.featureOn('familyPortalEnabled');
  }

  constructor(
    private caseService: CaseService,
    private iepAmendmentService: IepAmendmentService,
    private iepService: IepService,
    private iepServiceService: IepServiceService,
    private iepGoalService: IepGoalService,
    private iepAccommodationService: IepAccommodationService,
    private iepActivitySupportService: IepActivitySupportService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private routingService: RoutingService,
    private readonly title: Title,
    private authService: AuthService,
    private readonly reportingService: ReportingService,
    private readonly clpService: ClpService,
    private readonly notificationService: NotificationService,
    private featureFlagService: FeatureFlagService
  ) {}

  async ngOnInit(): Promise<void> {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');

    this.iep = await this.iepService
      .get(this.iepId)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.router.navigate(['/not-found']);
        } else {
          this.iepService.handleError('An error occured while retrieving IEP data.', error);
        }
      });

    if (!this.iep) return;

    forkJoin([
      this.caseService.getCaseSummary(this.caseId).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
      this.iepAmendmentService.getAmendmentsByIepId(this.iepId),
      this.iepService.canCancelTrialPlacement(this.iepId),
      this.iepService.getIepMeetings(this.caseId),
    ]).subscribe(([caseResult, iepAmendments, canCancelTrialPlacement, iepMeetings]) => {
      if (!caseResult) return;
      this.amendments = iepAmendments;
      this.iepMeetings = iepMeetings;
      this.learner = caseResult.learner;
      this.learnerId = caseResult.learnerId;
      this.canCancelTrialPlacement = canCancelTrialPlacement;
      this.caseSummary = caseResult;
      this.pageTitle = 'IEP - View - ' + caseResult.learner.fullName;
      this.title.setTitle('IEP - View - ACHIEVE');
      this.pageTitleColor = 'blue';
      const amendment = iepAmendments.find((x) => !x.finalizeDate);

      this.canFinalizeIep =
        this.authService.isSuperAdmin ||
        (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.FinalizeIEP) &&
          this.authService.user?.id === this.caseSummary?.caseOwnerId);

      if (amendment) {
        this.amendment = amendment;
        this.setAmendment();
      }

      this.getIncompleteItems();

      this.subscriptions.add(
        this.iepAmendmentService.amendmentUpdated$.subscribe(() => {
          this.getAmendment();
        })
      );

      this.setupFormSubscriptions();
      this.formGroup.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
        if (this.formGroup.dirty && !this.isSaving) {
          this.saveAmendment();
        }
      });
      this.dataLoaded = true;
    });
  }

  getIncompleteItems() {
    this.iepService.getIncompleteDataReport(this.iepId, this.amendment?.id).subscribe((result) => {
      this.incompleteItems = result;
      this.needsNewConsent = result.filter((x) => x.action === 'consent').length > 0;
    });
  }

  refreshIncompleteItems(e: MouseEvent) {
    e.stopPropagation();
    this.getIncompleteItems();
  }

  refreshData() {
    this.getIncompleteItems();
  }

  onEndTrialPlacement() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Are you sure? This will end the trial placement.',
      },
    });
    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.canCancelTrialPlacement = true;

        Object.keys(this.formGroup.controls).forEach((x) => {
          this.formGroup.get(x).setValue(x === 'includesTrialPlacement' ? false : null);
        });
        this.saveAmendment();

        setTimeout(() => {
          this.iepServiceService.servicesUpdated.next();
          this.iepAccommodationService.accommodationsUpdated.next();
          this.iepActivitySupportService.activitySupportUpdated.next();
        }, 200);

        this.canCancelTrialPlacement = false;
      }
    });
  }

  openPanel(event: IncompleteDataReportItem) {
    const panel = this[event.section] as MatExpansionPanel;
    if (panel) {
      if (!panel.expanded) {
        panel.toggle();
      }
      panel._body.nativeElement?.scrollIntoView({ behavior: 'smooth' });
    }
    if (event.section === 'leastRestrictiveEnv' && event.element === IncompleteDataReportElement.RECPNotAccurate) {
      this.iepServiceService.focusLRERECPBtn.next(undefined);
    }
  }

  trialPlacementChange() {
    setTimeout(() => {
      this.iepService.canCancelTrialPlacement(this.iepId).subscribe((res) => {
        this.canCancelTrialPlacement = res;
        this.getIncompleteItems();
      });
    }, 200);
  }

  goToCLP() {
    this.router.navigate(['/', 'cases', this.caseId, 'iep', this.iepId, 'clp']);
  }

  finalizeIepAmendment() {
    const clpDialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        question: 'CLP Changes',
        disableClose: true,
        subQuestion: 'Does the Amendment require any changes to the CLP?',
      },
    });
    clpDialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.clpService.unlockClp(this.iepId).subscribe((clpRes) => {
          if (clpRes.succeeded) {
            this.goToCLP();
            return;
          }
          this.notificationService.error(clpRes.errors?.length > 0 ? clpRes.errors[0]?.description : 'Something went wrong!');
        });
      } else {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            subQuestion: 'Are you sure you want to finalize? This action cannot be undone.',
            disableClose: true,
          },
        });
        dialogRef.afterClosed().subscribe((yesImSure) => {
          if (yesImSure) {
            this.isSaving = true;
            this.iepService.finalize(this.iepId, this.amendment.id).subscribe((result) => {
              this.isSaving = false;
              this.createAmendedOutput();
            });
          }
        });
      }
    });
  }

  private createAmendedOutput() {
    this.isSaving = true;
    this.reportingService.createIepOutput(this.iepId, null, true, this.amendment.id).subscribe({
      next: (documentId: string) => openPdfWindow(this.learnerId, documentId),
      error: (err) => this.reportingService.handleOutputError(err),
      complete: () => {
        this.routingService.iepList(this.learnerId);
        this.isSaving = false;
      },
    });
  }

  goToLMDocs() {
    this.routingService.documentation(this.learnerId);
  }

  enterProgress() {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseId).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  getAmendment() {
    this.iepAmendmentService.getAmendmentsByIepId(this.iepId).subscribe((iepAmendment) => {
      const amendment = iepAmendment.find((x) => !x.finalizeDate);
      if (amendment) {
        this.amendment = amendment;
        this.setAmendment();
      }
    });
  }

  setAmendment() {
    this.formGroup.patchValue(
      {
        includesTrialPlacement: this.amendment?.includesTrialPlacement,
        trialPlacementDate: this.amendment?.trialPlacementDate,
        trialPlacementTarget: this.amendment?.trialPlacementTarget,
      },
      { emitEvent: false }
    );

    this.pageTitle = 'IEP - Amending';
    this.pageTitleColor = 'red';
  }

  saveAmendment() {
    const model = {
      ...this.amendment,
      ...this.formGroup.value,
    };
    this.iepAmendmentService.updateAmendment(this.iepId, model).subscribe(() => {});
  }

  setupFormSubscriptions() {
    this.subscriptions.add(
      this.formGroup.get('includesTrialPlacement').valueChanges.subscribe((x) => {
        if (this.canCancelTrialPlacement === false) {
          this.formGroup.get('includesTrialPlacement').setValue(true, { emitEvent: false });
        }
        if (this.doesIncludeTrialPlacement) {
          this.formGroup.get('trialPlacementDate').setValidators(Validators.required);
        } else {
          this.formGroup.get('trialPlacementDate').clearValidators();
          this.formGroup.get('trialPlacementDate').setValue(null);
        }
        this.formGroup.updateValueAndValidity();
        this.cd.detectChanges();
      })
    );
  }

  onDataHistoryNavigate() {
    this.routingService.dataHistory(this.learnerId);
  }

  toggleAccordion() {
    this.isAccordionsExpanded = !this.isAccordionsExpanded;
    this.isAccordionsExpanded ? this.serviceAccordion.openAll() : this.serviceAccordion.closeAll();
  }

  onViewOverallSummary() {}

  updateAmendment(callback) {
    this.iepAmendmentService.updateAmendment(this.iepId, this.amendment).subscribe(() => {
      callback();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getClpPageQueryParams(): { amendmentId: string } {
    if (this.hasOpenAmendment) return { amendmentId: this.amendment?.id };
    else return null;
  }

  onDelete() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '550px',
      data: {
        question: 'Clicking Yes will cancel this amendment. Please fill in the reason for the cancellation to continue.',
      },
    });
    dialogRef.afterClosed().subscribe((val) => {
      if (val?.delete) {
        this.isDeleting = true;
        this.isSaving = true;
        this.iepAmendmentService.deleteAmendment(this.amendment.id, val?.cancelReason).subscribe(() => {
          this.isSaving = false;
          this.routingService.iepList(this.learnerId);
        });
      }
    });
  }

  getLastIepDocumentId(iep: IepView) {
    if (iep) {
      if (!iep.amendments || iep.amendments?.length == 0) return iep.iepDocumentId;

      return (
        iep.amendments?.sort((a, b) => {
          return Date.parse(b.finalizeDate) - Date.parse(a.finalizeDate);
        })[0]?.outputId || iep.iepDocumentId
      );
    }
  }
}
