import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IepSectionDetails } from 'src/app/iep/models/iep-section-details';
import { IlotReportFiltersDto } from 'src/app/my-tools/continuous-improvement/models/ilot-models';
import { FeatureFlagService } from '../../services/feature-flags/feature-flag.service';
import { AdditionalInformationDto } from '../../models/letter-document';
import { CorrelationService } from '../logger/correlation.service';
import { NotificationService } from '../notification.service';
import { OperationResultWithValue } from '../../models/operation-result';
import { DhhFormInputModel, DhhLearnerTestDto, DhhTestContactInfo } from '../../../dhh/models/DhhDtos';

export interface FinalExitOutputResult {
  exitLetterDocumentId: string;
  partCTransitionPlanDocumentId?: string;
}

export enum PdfOutputs {
  ConsentInitialEvalPwn = 'consent-initial-eval-pwn',
  CaseClosureLetter = 'case-closure-letter',
  PartCResultsLetter = 'part-c-results-letter',
  SummaryIfspOutcomesServices = 'summary-ifsp-outcomes-and-services',
  PartBPwnOutput = 'part-b-pwn-output',
  PartCPwnOutput = 'part-c-pwn-output',
  IepOutput = 'iep-output',
  IfspOutput = 'ifsp-output',
  PostReferralScreeningResults = 'post-referral-screening-results',
  MeetingNotice = 'meeting-notice',
  EvaluationAssessmentReport = 'evaluation-assessment-report',
  TransferOfRightsParents = 'transfer-of-rights-parents',
  TransferOfRightsLearners = 'transfer-of-rights-learners',
  PlodOutput = 'plod',
  FbaOutput = 'fba',
  BipOutput = 'bip',
  MdOutput = 'md',
  IcdPhysicianLetter = 'icd-physician-letter',
  PartCEvaluationSummary = 'part-c-evaluation-summary',
  EsyPlan = 'esy-plan',
  EaEvaluationAssessmentConsent = 'ea-evaluation-assessment-consent',
  Fgrbi = 'fgrbi',
  PartCTeamMembersSurvey = 'part-c-team-members-survey',
  PartBTeamMembersSurvey = 'part-b-team-members-survey',
  Reevaluation = 'reevaluation',
  FinalExit = 'final-exit',
  AgreementToExcuse = 'agreement-to-excuse',
  PartCTransitionPlan = 'part-c-transition-plan',
  PostSecondarySummary = 'postsecondary-summary',
  ThankYouLetter = 'thank-you-letter',
  SurrogateLetter = 'surrogate-letter',
  IepGoalProgressReport = 'iep-goal-progress-report',
  FbaLetter = 'fba-letter',
  ClpOutput = 'clp',
  AgeOfMajority = 'age-of-majority',
  OutOfStatePlacementOutput = 'out-of-state-placement',
  ImplementationPlan = 'implementation-plan',
  ImplementationPlanProgressMonitoring = 'implementation-plan-progress-monitoring',
  ELAAAssessment = 'elaa-assessment',
  IlotSurvey = 'ilot-survey',
  DataReviewProtocol = 'data-review-protocol',
  IlotReport = 'ilot-report',
  IfspOutcomesProgressReport = 'ifsp-outcome-progress-report',
  StateComplaintBlankForm = 'state-complaint-blank-form',
  MediationBlankForm = 'mediation-blank-form',
}

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private readonly pdfOutputs = PdfOutputs;
  private baseUrl = 'api/reporting';

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    private correlationService: CorrelationService
  ) {}
  createUniversalOutput<T>(id: string, outputType: PdfOutputs, additionalId?: string, params?: HttpParams): Observable<T> {
    // if (this.isOutputDisabled(outputType)) return new Observable();
    return this.http.post<T>(`${this.baseUrl}/${id}/${outputType}/${additionalId ? additionalId : ''}`, {}, { params });
  }

  createCaseClosureLetter(caseId: string): Observable<string> {
    const output = this.pdfOutputs.CaseClosureLetter;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createPartCResultsLetter(caseId: string): Observable<string> {
    const output = this.pdfOutputs.PartCResultsLetter;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createSummaryIfspOutcomesServices(id: string): Observable<string> {
    const output = this.pdfOutputs.SummaryIfspOutcomesServices;
    return this.createUniversalOutput<string>(id, output);
  }

  createPartBPwnOutput(pwnId: string): Observable<string> {
    const output = this.pdfOutputs.PartBPwnOutput;
    return this.createUniversalOutput<string>(pwnId, output);
  }

  createMdOutput(mdId: string): Observable<string> {
    const output = this.pdfOutputs.MdOutput;
    return this.createUniversalOutput(mdId, output);
  }

  createPartCPwnOutput(pwnId: string): Observable<string> {
    const output = this.pdfOutputs.PartCPwnOutput;
    return this.createUniversalOutput<string>(pwnId, output);
  }

  createIepOutput(
    iepId: string,
    sectionDetails: IepSectionDetails = null,
    pushOutputToFamily: boolean,
    amendmentId: string = null
  ): Observable<string> {
    const output = this.pdfOutputs.IepOutput;
    if (!sectionDetails) {
      sectionDetails = {} as IepSectionDetails;
    }
    return this.http.post<any>(
      `${this.baseUrl}/${iepId}/${output}/${pushOutputToFamily}/${amendmentId ? amendmentId : ''}`,
      sectionDetails
    );
  }

  createIfspOutput(ifspId: string, pushOutputToFamily: boolean, modificationId: string = null): Observable<string> {
    return this.http.post<string>(
      `${this.baseUrl}/${ifspId}/${this.pdfOutputs.IfspOutput}/${pushOutputToFamily}/${modificationId ? modificationId : ''}`,
      null
    );
  }

  createBip(caseId: string, bipId: string, pushOutputToFamily = false): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${caseId}/${this.pdfOutputs.BipOutput}/${bipId}/${pushOutputToFamily}`, null);
  }

  createClpOutput(iepId: string, pushOutputToFamily = false): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${iepId}/${this.pdfOutputs.ClpOutput}/${pushOutputToFamily}`, null);
  }

  createEsyPlan(iepId: string, pushOutputToFamily = false): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${iepId}/${this.pdfOutputs.EsyPlan}/${pushOutputToFamily}`, null);
  }

  getDraftEsyPlan(iepId: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${this.baseUrl}/${iepId}/${this.pdfOutputs.EsyPlan}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  createScreeningResultsOutput(learnerId: string): Observable<string> {
    const output = this.pdfOutputs.PostReferralScreeningResults;
    return this.createUniversalOutput<string>(learnerId, output);
  }

  createMeetingNoticeOutput(meetingId: string, utcOffsetMinutes?: number): Observable<string> {
    const output = this.pdfOutputs.MeetingNotice;
    if (this.isOutputDisabled(output)) return new Observable();

    return this.http.post<string>(`${this.baseUrl}/${meetingId}/${output}/${utcOffsetMinutes ?? ''}`, null);
  }

  createEvaluationAssessmentReport(evaluationId: string, startDate?: Date, endDate?: Date): Observable<string> {
    const output = this.pdfOutputs.EvaluationAssessmentReport;
    const params = new HttpParams()
      .append('startDate', startDate ? startDate.toISOString() : new Date().toISOString())
      .append('endDate', endDate ? endDate.toISOString() : new Date().toISOString());
    return this.createUniversalOutput<string>(evaluationId, output, '', params);
  }

  createAgeOfMajorityDocuments(learnerId: string): Observable<string[]> {
    const output = this.pdfOutputs.AgeOfMajority;
    return this.createUniversalOutput<any>(learnerId, output);
  }

  createConsentInitialEvalPwnOutput(caseId: string): Observable<string> {
    const output = this.pdfOutputs.ConsentInitialEvalPwn;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createPlodOutput(ifspId: string, plodId: string): Observable<string> {
    const output = this.pdfOutputs.PlodOutput;
    return this.createUniversalOutput<string>(ifspId, output, plodId);
  }

  createFbaOutput(fbaId: string, pushOutputToFamily = false): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${fbaId}/${this.pdfOutputs.FbaOutput}/${pushOutputToFamily}`, null);
  }

  createIcdPhysicianLetter(icdId: string): Observable<string> {
    const output = this.pdfOutputs.IcdPhysicianLetter;
    return this.createUniversalOutput<string>(icdId, output);
  }

  createPartCEvaluationSummary(caseId: string): Observable<string> {
    const output = this.pdfOutputs.PartCEvaluationSummary;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createEaEvaluationAssessmentConsent(learnerId: string): Observable<string> {
    const output = this.pdfOutputs.EaEvaluationAssessmentConsent;
    return this.createUniversalOutput<string>(learnerId, output);
  }

  createFgrbiOutput(servicelogId: string): Observable<string> {
    const output = this.pdfOutputs.Fgrbi;
    return this.createUniversalOutput<string>(servicelogId, output);
  }

  createPartCTeamMembersSurvey(caseId: string): Observable<string> {
    const output = this.pdfOutputs.PartCTeamMembersSurvey;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createPartBTeamMembersSurvey(iepId: string): Observable<string> {
    const output = this.pdfOutputs.PartBTeamMembersSurvey;
    return this.createUniversalOutput<string>(iepId, output);
  }

  createReevaluationOutput(reevaluationId: string, finalizedOutput = false, pushOutputToFamily = false): Observable<string> {
    return this.http.post<string>(
      `${this.baseUrl}/${reevaluationId}/${this.pdfOutputs.Reevaluation}/${finalizedOutput}/${pushOutputToFamily}`,
      null
    );
  }

  createFinalExitOutput(caseId: string): Observable<FinalExitOutputResult> {
    const output = this.pdfOutputs.FinalExit;
    return this.createUniversalOutput<FinalExitOutputResult>(caseId, output);
  }

  createAgreementToExcuseOutput(meetingParticipantId): Observable<string> {
    const output = this.pdfOutputs.AgreementToExcuse;
    return this.createUniversalOutput<string>(meetingParticipantId, output);
  }

  createPartCTransitionPlanOutput(transitionId: string): Observable<string> {
    const output = this.pdfOutputs.PartCTransitionPlan;
    return this.createUniversalOutput<string>(transitionId, output);
  }

  createPostSecondarySummaryOutput(postSecondarySummaryId: string): Observable<string> {
    const output = this.pdfOutputs.PostSecondarySummary;
    return this.createUniversalOutput<string>(postSecondarySummaryId, output);
  }

  createThankYouLetter(caseId: string): Observable<string> {
    const output = this.pdfOutputs.ThankYouLetter;
    return this.createUniversalOutput<string>(caseId, output);
  }

  createSurrogateLetter(learnerId: string): Observable<string> {
    const output = this.pdfOutputs.SurrogateLetter;
    return this.createUniversalOutput<string>(learnerId, output);
  }

  createIepGoalProgressReport(iepId: string, isGoalOnly = false): Observable<string> {
    const output = this.pdfOutputs.IepGoalProgressReport;
    const params = new HttpParams().append('isGoalOnly', isGoalOnly);
    return this.createUniversalOutput<string>(iepId, output, '', params);
  }

  createFbaLetter(fbaId: string): Observable<string> {
    const output = this.pdfOutputs.FbaLetter;
    return this.createUniversalOutput<string>(fbaId, output);
  }

  createOutOfStatePlacementOutput(id: string): Observable<string> {
    const output = this.pdfOutputs.OutOfStatePlacementOutput;
    return this.createUniversalOutput<string>(id, output);
  }

  createImplementationPlanOutput(planId: string): Observable<string> {
    const output = this.pdfOutputs.ImplementationPlan;
    return this.createUniversalOutput<string>(planId, output);
  }

  createImplementationPlanProgressMonitoringOutput(planId: string): Observable<string> {
    const output = this.pdfOutputs.ImplementationPlanProgressMonitoring;
    return this.createUniversalOutput<string>(planId, output);
  }

  createELAAAssessmentOutput(assessmentId: string): Observable<string> {
    const output = this.pdfOutputs.ELAAAssessment;
    return this.createUniversalOutput<string>(assessmentId, output);
  }

  createIlotSurveyOutput(ilotSurveyId: string): Observable<string> {
    const output = this.pdfOutputs.IlotSurvey;
    return this.createUniversalOutput<string>(ilotSurveyId, output);
  }

  createSdiSurveyOutput(surveyId: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${surveyId}/sdi-survey-output`, null);
  }

  createDataReviewProtocolOutput(id: string): Observable<string> {
    const output = this.pdfOutputs.DataReviewProtocol;
    return this.createUniversalOutput<string>(id, output);
  }

  createIlotReportOutput(filters: IlotReportFiltersDto): Observable<string> {
    const output = this.pdfOutputs.IlotReport;
    return this.http.post<string>(`${this.baseUrl}/${output}`, filters);
  }

  createStateComplaintBlankFormOutput(): Observable<Blob> {
    const output = this.pdfOutputs.StateComplaintBlankForm;
    return this.http.get<Blob>(`${this.baseUrl}/${output}`, { responseType: 'blob' as 'json', params: null });
  }

  createMediationBlankFormOutput(): Observable<Blob> {
    const output = this.pdfOutputs.MediationBlankForm;
    return this.http.get<Blob>(`${this.baseUrl}/${output}`, { responseType: 'blob' as 'json', params: null });
  }

  createIfspOutcomeProgressReportOutput(ifspId: string): Observable<string> {
    const output = this.pdfOutputs.IfspOutcomesProgressReport;
    return this.http.post<string>(`${this.baseUrl}/${ifspId}/${output}`, null);
  }

  public handleOutputError(err: any): void {
    this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
      this.notificationService.alert(err.error, "Couldn't open output")
    );
  }

  private checkOutputDisabled(outputFlag: string, outputName?: string): boolean {
    if (this.featureFlagService.featureOff(outputFlag)) {
      this.outputDisabledError(outputName);
      return true;
    } else {
      return false;
    }
  }

  public outputDisabledError(name?: string): void {
    this.notificationService.error(`${name ? name : 'Output'} is not enabled`);
  }

  public isOutputDisabled(output: PdfOutputs): boolean {
    switch (output) {
      case 'consent-initial-eval-pwn':
        return this.checkOutputDisabled('outputConsentInitialEvalPwnEnabled');

      case 'case-closure-letter':
        return this.checkOutputDisabled('outputCaseClosureEnabled');

      case 'part-c-results-letter':
        return this.checkOutputDisabled('outputResultsLetterEnabled');

      case 'summary-ifsp-outcomes-and-services':
        return this.checkOutputDisabled('outputOutcomesServicesProviderListEnabled');

      case 'part-b-pwn-output':
        return this.checkOutputDisabled('outputPartBPwnEnabled');

      case 'part-c-pwn-output':
        return this.checkOutputDisabled('outputPartCPwnEnabled');

      case 'iep-output':
        return this.checkOutputDisabled('outputIepEnabled');

      case 'ifsp-output':
        return this.checkOutputDisabled('outputIfspEnabled');

      case 'post-referral-screening-results':
        return this.checkOutputDisabled('outputPostReferralScreeningResultsEnabled');

      case 'meeting-notice':
        return this.checkOutputDisabled('outputMeetingNoticePartCEnabled');

      case 'evaluation-assessment-report':
        return this.checkOutputDisabled('outputEvaluationSummaryEnabled');

      case 'transfer-of-rights-parents':
        return this.checkOutputDisabled('outputTransferOfRightsEnabled');

      case 'transfer-of-rights-learners':
        return this.checkOutputDisabled('outputTransferOfRightsEnabled');

      case 'plod':
        return this.checkOutputDisabled('outputPlodEnabled');

      case 'fba':
        return this.checkOutputDisabled('outputFbaEnabled');

      case 'bip':
        return this.checkOutputDisabled('outputBipEnabled');

      case 'icd-physician-letter':
        return this.checkOutputDisabled('outputIcdPhysicianLetterEnabled');

      case 'part-c-evaluation-summary':
        return this.checkOutputDisabled('outputPartCEvaluationSummaryEnabled');

      case 'esy-plan':
        return this.checkOutputDisabled('outputEsyPlanEnabled');

      case 'ea-evaluation-assessment-consent':
        return this.checkOutputDisabled('outputConsentPwnEvalAssessmentEnabled');

      case 'fgrbi':
        return this.checkOutputDisabled('outputFgrbiEnabled');

      case 'reevaluation':
        return this.checkOutputDisabled('outputReevaluationEnabled');

      case 'final-exit':
        return this.checkOutputDisabled('outputFinalExitEnabled');

      case 'agreement-to-excuse':
        return this.checkOutputDisabled('outputAgreementToExcuseEnabled');

      case 'part-c-transition-plan':
        return this.checkOutputDisabled('outputPartCTransitionPlanEnabled');
    }
  }

  createAdditionalInformationLetter(model: AdditionalInformationDto) {
    return this.http.post<string>(`${this.baseUrl}/additional-information-letter`, model);
  }

  generateDhhAppointmentConfirmationLetter(learnerAppointmentId: string) {
    return this.http.get<string>(`${this.baseUrl}/dhh-appointment-confirmation-letter/${learnerAppointmentId}`);
  }

  createDhhFormOutput(formInputModel: DhhFormInputModel) {
    return this.http.post<OperationResultWithValue<string>>(`${this.baseUrl}/dhh-form-output`, formInputModel);
  }

  getDraftDhhFormOutput(formInputModel: DhhFormInputModel) {
    return this.http.post<Blob>(`${this.baseUrl}/dhh-form-output/preview`, formInputModel, {
      responseType: 'blob' as 'json',
    });
  }

  getDraftDhhMedicalResultsReport(contact: DhhTestContactInfo, learnerId: string, testId: string, testFinalized = false) {
    const params = {
      TestId: testId,
      LearnerId: learnerId,
      ContactId: contact?.ContactId ?? null,
      AddressId: contact?.AddressId ?? null,
      CopyTo: contact?.CopyTo ?? null,
      IsFinalized: testFinalized,
    };
    return this.http.post<Blob>(`${this.baseUrl}/dhh-medical-result-report/preview`, params, {
      responseType: 'blob' as 'json',
    });
  }

  createDhhMedicalResultsReport(contact: DhhTestContactInfo, learnerId: string, testId: string) {
    const params = {
      TestId: testId,
      LearnerId: learnerId,
      ContactId: contact?.ContactId ?? null,
      AddressId: contact?.AddressId ?? null,
      CopyTo: contact.CopyTo,
      IsFinalized: true,
    };
    return this.http.post<OperationResultWithValue<string>>(`${this.baseUrl}/dhh-medical-result-report`, params);
  }
}
