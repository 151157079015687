import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { formatDate } from '@angular/common';
import { FormBuilder } from '@angular/forms';

import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { DhhEventDto } from '../../../models/DhhDtos';
import { DistrictService } from '../../../../shared/services/district/district.service';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhService } from '../../../services/dhh.service';
import { AuthService } from '../../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { DhhRoutingService } from '../../../services/dhh-routing.service';
import { DhhAppointmentTypeIds } from '../../../models/static-lookups';

@Component({
  selector: 'app-dhh-calendar',
  templateUrl: './dhh-calendar.component.html',
  styleUrls: ['./dhh-calendar.component.scss'],
})
export class DhhCalendarComponent implements OnInit {
  @ViewChild('dhhEventDetailScrollZone') eventDetailScrollZone: ElementRef;

  dhhSoundBooths: KeyValuePair[];
  dhhDistricts: KeyValuePair[];
  events: DhhEventDto[] = [];
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear, prev, today, next, nextYear',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    plugins: [dayGridPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: false,
    allDaySlot: true,
    slotLabelInterval: '24:00',
    allDayText: '',
    height: 'auto',
    eventClick: this.onEventClick.bind(this),
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
    },
    timeZone: 'America/Chicago',
    displayEventTime: false,
    validRange: {
      start: '1900-01-01',
    },
    nextDayThreshold: '00:00:00',
  };

  filterFormGroup = this.fb.group({
    soundBoothLocationId: [null],
    districtId: [null],
    onlyMyAppointments: [null],
  });
  filterSoundBoothLocationId: string;
  filterDistrictId: string;
  filterOnlyMyEvents: boolean;
  selectedEvent: DhhEventDto;
  today: Date;

  get canEditEvent() {
    return (
      this.selectedEvent.appointmentType?.label !== 'Sound Booth' || this.authService.isDhhAudiologist || this.authService.isDhhScheduler
    );
  }

  get canCancelEvent() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isDhhAudiometrist;
  }

  constructor(
    private ngZone: NgZone,
    private readonly districtService: DistrictService,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    public readonly authService: AuthService,
    private readonly dhhRoutingService: DhhRoutingService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.getEvents();

    this.setFormSubscription();

    await this.getLookups();

    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
  }

  async getLookups() {
    const soundBoothLocations = await this.dhhLookupService.getSoundBoothLocations().toPromise();
    this.dhhSoundBooths = soundBoothLocations.value.map((a) => new KeyValuePair(a.id, a.label));

    const districts = await this.districtService.getDistricts().toPromise();
    this.dhhDistricts = districts.map((a) => new KeyValuePair(a.id, a.name));
  }

  setFormSubscription() {
    this.filterFormGroup.controls.soundBoothLocationId.valueChanges.subscribe((value) => {
      this.filterSoundBoothLocationId = value;
      this.filterByBooth(value);
    });

    this.filterFormGroup.controls.districtId.valueChanges.subscribe((value) => {
      this.filterDistrictId = value;
      this.filterByDistricts(value);
    });

    this.filterFormGroup.controls.onlyMyAppointments.valueChanges.subscribe((value) => {
      this.filterOnlyMyEvents = value;
      this.filterMyEvents(value);
    });
  }

  getEvents() {
    this.dhhService.getEvents().subscribe(
      (result) => {
        if (result.succeeded) {
          this.events = result.value;
          this.filterFormGroup.reset();
        } else {
          this.dhhService.handleError('There was an error while retrieving events', result);
        }
      },
      (error) => {
        this.dhhService.handleError('Failed to retrieve events', error);
      }
    );
  }

  filterByBooth(soundBoothLocationId: string) {
    this.filterSoundBoothLocationId = soundBoothLocationId;
    this.runFilter();
  }

  filterByDistricts(districtId: string) {
    this.filterDistrictId = districtId;
    this.runFilter();
  }

  filterMyEvents(onlyMyAppointments: boolean) {
    this.filterOnlyMyEvents = onlyMyAppointments;
    this.runFilter();
  }

  runFilter() {
    this.calendarOptions.events = this.events
      .filter(
        (e) =>
          (!this.filterOnlyMyEvents ||
            e.audiologists.some((x) => x.id === this.authService.user.id) ||
            e.audiometrists.some((x) => x.id === this.authService.user.id)) &&
          (!this.filterSoundBoothLocationId || e.soundBoothLocationId === this.filterSoundBoothLocationId) &&
          (!this.filterDistrictId || e.districtId === this.filterDistrictId)
      )
      .map((e) => ({
        title: this.getEventTitle(e),
        start: e.startDateAndTime.toString().substring(0, e.startDateAndTime.toString().length - 9),
        end: e.endDate?.toString().substring(0, e.endDate.toString().length - 1),
        appointment: e,
        allDay: false,
        className:
          this.isSelfEvent(e) && e.appointmentType.label === 'Sound Booth'
            ? ['self-appointment-calendar-event']
            : e.appointmentType.label === 'Sound Booth'
            ? ['soundbooth-calendar-event']
            : ['screening-calendar-event'],
      }));
  }

  getEventTitle(event: DhhEventDto) {
    let title = '';
    if (event.startTime) {
      title = formatDate(event.startDateAndTime, 'h:mma', 'en-US').toLowerCase();
    }

    if (event.soundBoothLocation) {
      title += ` ${event.soundBoothLocation.label}`;
    } else {
      title = event.appointmentType.label;
    }
    if (event.appointmentTypeId === DhhAppointmentTypeIds.Screening || DhhAppointmentTypeIds.Recheck) {
      const location = event.buildingName ? event.buildingName : event.districtName;
      if (location) title += ' - ' + location;
    }

    return title;
  }

  isSelfEvent(event: DhhEventDto) {
    return (
      event.audiologists.some((x) => x.id === this.authService.user.id) ||
      event.audiometrists.some((x) => x.id === this.authService.user.id)
    );
  }

  addNewEvent() {
    const callBackFunc = () => {
      this.ngZone.run(() => {
        this.selectedEvent = null;
        this.getEvents();
      });
    };

    this.dhhRoutingService.openEventScheduler(null, callBackFunc);
  }

  editEvent() {
    const callBackFunc = () => {
      this.ngZone.run(() => {
        this.selectedEvent = null;
        this.getEvents();
      });
    };

    this.dhhRoutingService.openEventScheduler(this.selectedEvent.appointmentId, callBackFunc);
  }

  onCancel() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to remove this event from the calendar? Please confirm that you wish to continue.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.dhhService.cancelAppointment(this.selectedEvent.appointmentId).subscribe(
          (result) => {
            if (result.succeeded) {
              this.selectedEvent = null;
              this.getEvents();
            } else {
              this.dhhService.handleError('Failed to cancel the selected event', result);
            }
          },
          (error) => {
            this.dhhService.handleError('There was an error cancelling the event', error);
          }
        );
      }
    });
  }

  onEventClick(arg: EventClickArg) {
    this.selectedEvent = arg.event.extendedProps.appointment;
    this.scrollToEvent();
  }

  scrollToEvent() {
    if (this.selectedEvent) {
      this.eventDetailScrollZone.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
        alignToTop: true,
      });
    }
  }

  get selectedEventTitle() {
    return this.selectedEvent.soundBoothLocation ? this.selectedEvent.soundBoothLocation.label : this.selectedEvent.appointmentType.label;
  }

  get selectedEventSubTitle() {
    let title = this.isScreeningOrRecheck(this.selectedEvent.appointmentTypeId)
      ? 'Start Date: ' + formatDate(this.selectedEvent.startDate, 'MM/dd/yyyy', 'en-US')
      : 'Date: ' + formatDate(this.selectedEvent.startDate, 'MM/dd/yyyy', 'en-US');
    if (this.selectedEvent.startTime) {
      title += ' | Time: ' + this.selectedEvent.startTime + (this.selectedEvent.endTime ? ' - ' + this.selectedEvent.endTime : '');
    }
    if (this.isScreeningOrRecheck(this.selectedEvent.appointmentTypeId)) {
      title += ' | End Date: ' + formatDate(this.selectedEvent.endDate, 'MM/dd/yyyy', 'en-US');
      if (this.selectedEvent.buildingId) {
        title += ' | Location: ' + this.selectedEvent.buildingName;
      } else if (this.selectedEvent.districtId) {
        title += ' | Location: ' + this.selectedEvent.districtName;
      }
      if (this.selectedEvent.audiometrists?.length > 0) {
        title += ' | Audiometrist: ' + this.selectedEvent.audiometrists.map((a) => `${a.firstName} ${a.lastName}`).join(', ');
      }
    }
    if (this.selectedEvent.audiologists?.length > 0) {
      title += ' | Audiologist: ' + this.selectedEvent.audiologists.map((a) => `${a.firstName} ${a.lastName}`).join(', ');
    }
    return title;
  }

  onEventDetailClose() {
    this.selectedEvent = null;
  }

  get isEventPast() {
    return new Date(this.selectedEvent.startDateAndTime) < this.today;
  }

  isScreeningOrRecheck(appointmentTypeId: string) {
    return appointmentTypeId === DhhAppointmentTypeIds.Screening || appointmentTypeId === DhhAppointmentTypeIds.Recheck;
  }
}
