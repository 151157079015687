import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AppMatModule } from '../shared/app-mat.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DhhRoutingModule } from './dhh-routing.module';
import { DhhChildSearchComponent } from './components/dhh-child-search/dhh-child-search.component';
import { DhhPendingLearnersComponent } from './components/dhh-pending-learners/dhh-pending-learners.component';
import { DhhReportingComponent } from './components/dhh-reporting/dhh-reporting.component';
import { DhhCalendarComponent } from './components/shared/dhh-calendar/dhh-calendar.component';
import { DhhLearnersListComponent } from './components/shared/dhh-learners-list/dhh-learners-list.component';
import { DhhLearnerEntryComponent } from './components/dhh-learner-entry/dhh-learner-entry.component';
import { ChildSearchLearnerListComponent } from './components/dhh-child-search/child-search-learner-list/child-search-learner-list.component';
import { DhhPendingLearnerUpdateComponent } from './components/dhh-pending-learners/dhh-pending-learner-update/dhh-pending-learner-update.component';
import { DhhEventSchedulerComponent } from './components/shared/dhh-event-scheduler/dhh-event-scheduler.component';
import { DhhPendingAppointmentsComponent } from './components/dhh-appointment/dhh-pending-appointments/dhh-pending-appointments.component';
import { DhhSharedModule } from './components/shared/dhh-shared.module';
import { DhhPendingAppointmentsTableComponent } from './components/shared/dhh-pending-appointments-table/dhh-pending-appointments-table.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    DhhChildSearchComponent,
    DhhPendingLearnersComponent,
    DhhReportingComponent,
    DhhLearnersListComponent,
    DhhLearnerEntryComponent,
    ChildSearchLearnerListComponent,
    DhhPendingLearnerUpdateComponent,
    DhhEventSchedulerComponent,
  ],
  imports: [
    CommonModule,
    AppMatModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DhhRoutingModule,
    FullCalendarModule,
    DhhSharedModule,
    AngularSvgIconModule,
  ],
  exports: [DhhPendingAppointmentsComponent, DhhPendingAppointmentsTableComponent, DhhCalendarComponent],
})
export class DhhModule {}
