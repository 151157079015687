<div class="header__row">
  <h3>Family Contact Information</h3>
  <div *ngIf="learner?.hasWorkableCase && !authService.isPortalUser">
    <button
      mat-raised-button
      aria-label="Add Surrogate Information"
      (click)="onAddFirstSurrogates()"
      color="primary"
      class="contact-button"
      *ngIf="learner.surrogates.length === 0 && showSurrogateButton && canAddSurrogates"
      tabindex="0"
    >
      Add Surrogate Information
    </button>

    <a
      *ngIf="canEmailTheFamily"
      mat-raised-button
      [href]="learnerService.getFamilyEmailString(learner?.familyMembers)"
      target="_blank"
      color="primary"
      class="contact-button"
    >
      Email Family
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div *ngIf="isEditing && learner?.hasWorkableCase" class="mb-3">
      <app-family-member
        [formGroup]="formGroup"
        [parentForm]="parent"
        [cardTitle]="editTitle"
        [relationships]="relationships"
        [relationshipOptions]="relationshipOptions"
        [referralHowHearAboutUsOptions]="[]"
        [howHearAboutUsOtherId]="''"
        [isFromFamilyContact]="true"
        [isPartCLearner]="!!partCActiveCase"
        [learner]="learner"
      ></app-family-member>
      <div class="action__row">
        <button mat-raised-button aria-label="Cancel Button" (click)="cancelEdit()" tabindex="0">Cancel</button>
        <button [isBusy]="activeCall" color="primary" mat-raised-button aria-label="Save Button" (click)="saveEdit()"
                tabindex="0">
          Save
        </button>
      </div>
    </div>
    <mat-card class="py-1 position-fix--card">
      <div class="table-overflow app-table">
        <table matSort mat-table [dataSource]="dataSource" class="w-100">
          <ng-container matColumnDef="actions" *ngIf="isEditAllowed">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let family">
              <div *ngIf="canViewActions" class="display-flex align-items-center justify-content-start">
                <button mat-icon-button aria-label="View Icon" color="primary"
                        (click)="viewMoreFamilyMemberModal(family.id)">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                <button
                  aria-label="Edit Icon"
                  mat-icon-button
                  color="primary"
                  (click)="edit(family)"
                  *ngIf="!learner?.cannotEditFamilyInfo"
                  tabindex="0"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  aria-label="Delete Icon"
                  mat-icon-button
                  color="warn"
                  (click)="delete(family)"
                  *ngIf="!learner?.cannotEditFamilyInfo && moreThanOneParent(family) && family.type !== 'Learner'"
                  tabindex="0"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="fullName">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let family">{{ family.fullName }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let family">{{ family.email }}</td>
          </ng-container>

          <ng-container matColumnDef="homePhone">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Phones</th>
            <td mat-cell *matCellDef="let family" class="column-nowrap">
              <span [style.display]="'block'" *ngIf="family.homePhone">
                {{ family.homePhone | phone }}
              </span>
              <span [style.display]="'block'" *ngIf="family.workPhone">
                {{ family.workPhone | phone }}
              </span>
              <span [style.display]="'block'" *ngIf="family.cellPhone">
                {{ family.cellPhone | phone }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="familyRelationship">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Relationship</th>
            <td mat-cell *matCellDef="let family">
              {{ family.familyRelationship }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Portal Status</th>
            <td mat-cell *matCellDef="let family">
              {{ getPortalStatus(family) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="validated">
            <th mat-sort-header mat-header-cell *matHeaderCellDef>Validated</th>
            <td mat-cell *matCellDef="let family">
              <ng-container
                *ngIf="
                  relationshipAllowed(family.familyRelationshipId) &&
                  (family.familyUserId === null || (!!family.familyUserId && family.accessRevoked))
                "
              >
                <mat-checkbox
                  [checked]="family.validated"
                  [ngClass]="{'read-only-checkbox': !family.validated, 'read-only-checkbox-checked': family.validated}"
                  [disabled]="!family.validated"
                ></mat-checkbox>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="familyAccess">
            <th mat-sort-header mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let family">
              <ng-container
                *ngIf="
                  family.validated &&
                  family.familyUserId === null &&
                  family.email &&
                  !family.noPortalAccess &&
                  canInviteFamilyMember &&
                  relationshipAllowed(family.familyRelationshipId)
                "
              >
                <button
                  mat-raised-button
                  aria-label="Share"
                  color="primary"
                  (click)="onInviteFamilyMember(family.id)"
                  tabindex="0"
                  [isBusy]="activeCall"
                >
                  Invite To Portal
                </button>
              </ng-container>
              <ng-container *ngIf="family.familyUserId !== null && canManageFamilyMemberAccess">
                <button
                  mat-icon-button
                  color="primary"
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="actionsMenu"
                  tabindex="0"
                  aria-label="Quick Access Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actionsMenu="matMenu">
                  <button
                    *ngIf="family.isUserAccountActive"
                    class="mr-1"
                    aria-label="Reset Password"
                    (click)="resetPassword(family)"
                    mat-menu-item
                  >
                    Reset Password
                  </button>
                  <button
                    *ngIf="!family.accessRevoked"
                    (click)="revokeAccess(family)"
                    class="mr-1"
                    aria-label="Revoke Access"
                    mat-menu-item
                  >
                    Revoke Access
                  </button>
                  <button
                    *ngIf="family.accessRevoked"
                    (click)="reactivateAccess(family)"
                    class="mr-1"
                    aria-label="Reactivate Access"
                    mat-menu-item
                  >
                    Reactivate Access
                  </button>
                </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [dataSource]="dataSource"></mat-paginator>
        <button
          mat-icon-button
          aria-label="Add Icon"
          color="primary"
          (click)="addingNew()"
          *ngIf="isEditAllowed && !isEditing && learner?.hasWorkableCase && canViewActions && !learner?.cannotEditFamilyInfo"
          class="position-fix--add"
          tabindex="0"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </mat-card>
  </div>
</div>

<section #surrogateSection class="card-spacing-top"
         *ngIf="canAddSurrogates && (learner.surrogates.length > 0 || showSurrogateSection)">
  <app-surrogate-contact-info
    [learnerId]="learner.id"
    [learner]="learner"
    [isFirstSurrogate]="isFirstSurrogate"
    [hasWorkableCase]="learner?.hasWorkableCase"
    (cancelSurrogateEntry)="onCancelSurrogateEntry()"
  ></app-surrogate-contact-info>
</section>
