import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OperationResult, OperationResultWithValue } from '../../../../../shared/models/operation-result';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { MediationChangeCaseNumber } from '../models/mediation-change-case-number';
import { MediationCloseCase } from '../models/mediation-close-case';

@Injectable({
  providedIn: 'root',
})
export class MediationService {
  private baseUrl = 'api/dispute-resolution/mediation';
  constructor(private readonly _httpClient: HttpClient, private readonly spinnerService: SpinnerService) {}

  caseClosed = new Subject<boolean>();
  caseClosed$ = this.caseClosed.asObservable();

  mediatorChanged = new Subject<boolean>();
  mediatorChanged$ = this.mediatorChanged.asObservable();

  submitMediationRequest(formData: any) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this._httpClient.post(`${this.baseUrl}/submissions`, formData).pipe(
      tap(turnOffSpinner, turnOffSpinner),
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getMediationSubmission(complaintId: string) {
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/submissions/${complaintId}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  updateSubmission(submissionId: string, obj: any) {
    return this._httpClient.put<OperationResultWithValue<any>>(`${this.baseUrl}/submissions/${submissionId}`, obj).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  forwardMediation(dto: { recipients: any[]; complaintId: string }) {
    return this._httpClient.post<OperationResultWithValue<any>>(`${this.baseUrl}/submissions/${dto.complaintId}/forwards`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getMediatiors() {
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/lookup-mediators`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getCaseList(obj: any) {
    const query = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        query.append(key, obj[key]);
      }
    });
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/cases?${query.toString()}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  private throwError(errorTitle: string, error: any): Observable<string> {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    return throwError({ title: errorTitle, message: new Error(errorMessage) });
  }

  getDashboardLookups() {
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/dashboard-lookups`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getCaseById(caseId: string) {
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/cases/${caseId}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  updateCaseMediators(caseId: string, mediatorList: any) {
    return this._httpClient.put<OperationResultWithValue<any>>(`${this.baseUrl}/cases/${caseId}/mediators`, mediatorList).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  updateCase(caseId: string, obj: any) {
    return this._httpClient.put<OperationResultWithValue<any>>(`${this.baseUrl}/cases/${caseId}`, obj).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  changeCaseNumber(caseId: string, obj: MediationChangeCaseNumber) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this._httpClient
      .put<OperationResult>(`${this.baseUrl}/cases/${caseId}/change-case-number`, obj)
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  closeCase(caseId: string, obj: MediationCloseCase) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this._httpClient
      .put<OperationResult>(`${this.baseUrl}/cases/${caseId}/close-case`, obj)
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  validateLearner(caseId: string, learnerId: string) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this._httpClient
      .put<OperationResultWithValue<any>>(`${this.baseUrl}/cases/${caseId}/validate-learner/${learnerId}`, {})
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  getCaseSummaryLookups() {
    return this._httpClient.get<OperationResultWithValue<any>>(`${this.baseUrl}/lookups/case-summary`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  downloadPublicRequest(id: string, docId: string) {
    window.open(`${this.baseUrl}/public/${id}/documents/${docId}`, '_blank');
  }
}
