import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenavContent } from '@angular/material/sidenav';
import { ActivatedRoute, Event, Router, Scroll } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { delay, filter, map, shareReplay, take } from 'rxjs/operators';
import ConfigJson from '../../config.json';
import { AuthService, Roles } from '../auth/auth.service';
import { MedicaidReportModalService } from '../medicaid/services/medicaid-report-modal.service';
import { AppPermissions } from '../permissions';
import { AppEvents, AutosavingStates } from '../shared/app-events';
import { CreateStickyNoteModalComponent } from '../shared/components/create-sticky-note-modal/create-sticky-note-modal.component';
import { FeatureFlagService } from '../shared/services/feature-flags/feature-flag.service';
import { FeatureFlags } from '../shared/services/feature-flags/feature-flags';
import { TimeoutModalComponent } from '../shared/modals/timeout-modal/timeout-modal.component';
import { IntakeType } from '../shared/models/case';
import { AchieveConfigService } from '../shared/services/achieve-config-service/achieve-config.service';
import { CaseService } from '../shared/services/case/case.service';
import { HelpModalConfig, HelpService } from '../shared/services/help/help.service';
import { SignalRService } from '../shared/services/signal-r.service';
import { NewWindowConfig, openNewWindow, popupChecker } from '../shared/windowHelpers';
import { DatabaseLinksService } from '../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [SignalRService],
})
export class NavComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @ViewChild('drawer') drawer: MatDrawer;
  version = ConfigJson.version;
  @ViewChild('topNav') topNav: ElementRef;

  isVr: boolean;
  isInteractiveAccess: boolean;
  isFamily = false;
  isLearner = false;
  isEligibleFamily = false;
  isAea = false;
  hasTools = false;
  permissions = AppPermissions;
  isPopup = false;
  scrollPosition: number;
  drawerOpen = true;
  userFullName = '';
  userEmail = '';
  isMobile: boolean;
  savingState$ = new BehaviorSubject<AutosavingStates>(AutosavingStates.Off);
  safeSavingState$ = this.savingState$.pipe(delay(0));
  autosavingStates = AutosavingStates;
  intakeType: IntakeType;
  caseId: string;
  isDhh: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  @ViewChild('sideNavContent') mainContent: MatSidenavContent;

  get achieveSettings() {
    return this.achieveConfigService.settings;
  }

  get canAssignServiceCoordinator() {
    return this.authService.isAllowed(AppPermissions.AssignServiceCoordinator);
  }

  get canViewPendingReferrals() {
    return this.authService.isAllowed(AppPermissions.ViewPendingReferrals);
  }

  get canViewReferrals() {
    return (
      this.authService.isAllowed(AppPermissions.ViewReferrals) ||
      (this.intakeType !== IntakeType.PartB && this.authService.isAllowed(AppPermissions.PartCViewReferral))
    );
  }

  get onlyLeaUserManagerRoleAssigned() {
    return this.isLeaUserManager && this.authService.user?.rolePermissions?.length === 1;
  }

  get isReportingOnly() {
    return this.authService.user?.rolePermissions?.every((x) => x.role === Roles.ReportingOnly);
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  get isVRManagementPhase2() {
    return this.featureFlagService.featureOn(FeatureFlags.IVRSPhase2);
  }

  get isVRManagement() {
    return this.authService.isSuperAdmin || this.authService.isAllowedStatewide(AppPermissions.VRManagement);
  }

  get isLeaUserManager() {
    return this.authService.user?.rolePermissions?.every((x) => x.role === Roles.LeaUserManager);
  }

  get isGeneralEducation() {
    return this.authService.user?.rolePermissions?.every((x) => x.role === Roles.GeneralEducation);
  }

  get canViewDashboard() {
    return (
      !this.authService.isUserWithDhhOnlyRoles ||
      (this.featureFlagService.featureOn(FeatureFlags.Dhh) &&
        (this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isDhhAudiometrist))
    );
  }

  get canViewDhhRecheck() {
    return this.authService.isDhhAudiologist || this.authService.isDhhAudiometrist || this.authService.isSuperAdmin;
  }

  get canViewDhhScreening() {
    return this.authService.isDhhAudiologist || this.authService.isDhhAudiometrist || this.authService.isSuperAdmin;
  }

  get isDhhOnly() {
    return this.authService.isUserWithDhhOnlyRoles;
  }

  get isDhhFeatureFlagOn() {
    return this.featureFlagService.featureOn(FeatureFlags.Dhh);
  }

  FeatureFlags = FeatureFlags;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly viewportScroller: ViewportScroller,
    private readonly helpService: HelpService,
    private readonly caseService: CaseService,
    private dialog: MatDialog,
    private readonly idle: Idle,
    private readonly achieveConfigService: AchieveConfigService,
    private readonly databaseLinksService: DatabaseLinksService,
    private medicaidReportModalService: MedicaidReportModalService,
    public readonly featureFlagService: FeatureFlagService
  ) {
    AppEvents.autosaving.subscribe((state: AutosavingStates) => {
      if (this.savingState$.value === AutosavingStates.Saved && state === AutosavingStates.Off) {
        return;
      }
      this.savingState$.next(state);
      const delayedUpdate = (newState: AutosavingStates, delayMs = 1000) => {
        timer(delayMs)
          .pipe(take(1))
          .subscribe(() => {
            this.savingState$.next(newState);
          });
      };
      switch (state) {
        case AutosavingStates.Saved:
          delayedUpdate(AutosavingStates.Done);
          break;
        case AutosavingStates.OneOff:
          this.savingState$.next(AutosavingStates.Saved);
          delayedUpdate(AutosavingStates.Off);
          break;
      }
    });
    // TODO: Make this configurable from the db
    this.configureIdleTimeoutService();
  }

  private configureIdleTimeoutService() {
    // sets an idle timeout of 28 minutes, for testing purposes.
    const settings = this.achieveConfigService.settings;
    if (settings.timeoutMinutes > -1) {
      this.idle.setIdle(settings.timeoutMinutes * 60);
      // sets a timeout period of 120 seconds. after 30 minutes of inactivity, the user will be considered timed out.
      this.idle.setTimeout(120);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      this.idle.onTimeout.subscribe(async () => await this.logout());
      this.idle.onIdleStart.subscribe(() => this.dialog.open(TimeoutModalComponent, { width: '748px' }));
      this.idle.watch();
    }
  }

  ngOnInit() {
    this.router.events.pipe(filter((e: Event): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      if (e.position) {
        // backward navigation
        this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        this.viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        this.mainContent.getElementRef().nativeElement.scrollTo(0, 0);
      }
    });

    if (!!this.authService.user) {
      this.authService.authUserChanged$.subscribe(() => {
        if (this.authService.user.firstName && this.authService.user.lastName) {
          this.userFullName = `${this.authService.user.firstName} ${this.authService.user.lastName}`;
        } else if (this.authService.user.firstName) {
          this.userFullName = this.authService.user.firstName;
        }
        this.userEmail = this.authService.user.email;
      });
      this.authService.authUserChanged();

      this.isVr = this.featureFlagService.featureOn(FeatureFlags.Ivrs) && this.authService.isVrUser;
      this.isInteractiveAccess =
        this.featureFlagService.featureOn(FeatureFlags.DisputeResolution) && this.authService.isInteractiveAccessUser;
      this.isFamily = this.authService.isFamily;
      this.isLearner = this.authService.isLearner;
      this.isEligibleFamily = this.authService.isEligibleFamily;
      this.isAea = !this.isLearner && !this.isFamily && !this.isEligibleFamily;
      this.isDhh = this.authService.isDhhUser || this.authService.isSuperAdmin;
    }

    this.isHandset$.subscribe((d: boolean) => {
      this.isMobile = d;

      if (d) {
        this.drawerOpen = false;
      }
    });

    this.isPopup = popupChecker(this.route);
    this.hasTools = this.featureFlagService.featureOn(FeatureFlags.MyTools);
  }

  onOpenEconsent() {
    // const formKey = ConsentFormType.FiieConsent // InitialFamilyEvaluationAndAssessment;
    // this.mapConsentFormTypeToFormKey(consentForm.type);
    // const config: NewWindowConfig = {
    //   path: `/consent-signature`,
    //   params: {
    //     learnerId: 'ca5a7774-056d-4fe1-beb4-adcc012b212d', // consentForm.learnerId,
    //     caseId: 'd8bdc376-b915-4ca3-8b68-adcc012b25bb', // consentForm.caseId,
    //     consentFormId: 'd2621331-510c-46f7-b60e-adcc012b6176', // consentForm.id,
    //     formKey: 'ConsentForFullAndIndividualEvaluation',
    /*
         learnerId: '15E3C27E-3761-4D1C-A889-ADB900D32A9A', //consentForm.learnerId,
         caseId: 'F33B24E5-6DDD-459F-B397-ADB900D32A8E', //consentForm.caseId,
         consentFormId: '15E3C27E-3761-4D1C-A889-ADB900D32A9A', //consentForm.id,
        */
    //   },
    //   popup: true,
    //   isPreview: true,
    //   width: '1450px',
    // };
    // openNewWindow(config);
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.drawerOpen = this.drawer.opened;
  }

  openMedicaidReport() {
    this.medicaidReportModalService.openReportModal();
  }

  async logout() {
    this.authService.logout(this.isVr ? '/auth/ivrs/login' : this.isInteractiveAccess ? '/auth/interactive-access/login' : '');
  }

  onScroll(e) {
    this.scrollPosition = e.target.scrollTop;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.drawer) {
      return;
    }
    timer(50)
      .pipe(take(1))
      .subscribe(() => (this.drawerOpen = this.drawer.opened));
  }

  onOpenHelp() {
    const dictionary = this.helpService.getAllDictionaries();
    this.helpService.openHelpModal({
      help: dictionary,
      canBrowse: true,
    } as HelpModalConfig);
  }

  onOpenCreateStickyNote() {
    this.dialog.open(CreateStickyNoteModalComponent, { width: '748px' });
  }

  onOpenStickyNotes() {
    const config: NewWindowConfig = {
      path: 'sticky-notes/list',
      popup: true,
    };
    openNewWindow(config);
  }

  switchVersion(version: string) {
    const url = ['/'];
    if (version === 'ivrs') {
      this.isVr = true;
      url.push('ivrs');
    } else if (version === 'achieve') {
      this.isVr = false;
    }
    this.router.navigate(url);
  }

  async openPLS() {
    const token = await this.authService.getToken();
    const plsUrl = this.databaseLinksService.getDatabaseLink('plsUrl');
    window.open(`${plsUrl}/?app=achieve&token=${token}`, '_blank');
  }

  async openGeneralGuide() {
    window.open(`${this.databaseLinksService.getDatabaseLink('generalNavigationUserGuide')}`, '_blank');
  }

  async openEarlyAccessGuide() {
    window.open(`${this.databaseLinksService.getDatabaseLink('earlyAccessSystemUserGuide')}`, '_blank');
  }

  async openSpecialEducationGuide() {
    window.open(`${this.databaseLinksService.getDatabaseLink('specialEducationSystemUserGuide')}`, '_blank');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
