import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { ActivityLogEntry } from 'src/app/shared/models/activity-log-entry';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { OperationResultWithValue } from 'src/app/shared/models/operation-result';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { ActivityLogLabels } from '../../../shared/models/activity-log-entry';
import { NotificationService } from '../../../shared/services/notification.service';
import { SearchService } from '../search.service';
import { NewCaseModalComponent } from './new-case-modal/new-case-modal.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  learnerSummary: LearnerSummary;
  activityLog: MatTableDataSource<ActivityLogEntry>;
  activityLogLabels = ActivityLogLabels;
  pageTitle: string;
  intakeTypeEnum = IntakeType;
  activeCases: CaseSummary[] = [];
  exitedCases: CaseSummary[] = [];
  private referralId: string;
  private isLocationOnlySearch = false;
  private isSif = false;
  isPossibleDuplicate: boolean;
  isAllowedToContinueToDashboard: boolean;
  private isOutOfStateUser: boolean;
  canStartCase: boolean;
  canViewLog: boolean;
  canContinueToDashboard: boolean;
  canStartChildFind: boolean;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private location: Location,
    private searchService: SearchService,
    private caseService: CaseService,
    public learnerService: LearnerService,
    private notificationService: NotificationService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.canStartCase = this.authService.canInitiateDisabilitySuspect;
    this.canViewLog = this.authService.isAllowed(AppPermissions.ChildSearchActivityLog);
    this.isAllowedToContinueToDashboard = this.authService.isAllowed(AppPermissions.ChildSearchResults);
    this.isOutOfStateUser = this.authService.isAllowed(AppPermissions.OutOfStateAddChild);
    this.load();
  }

  load() {
    const id = this.route.snapshot.paramMap.get('id');
    const isSifParam = this.route.snapshot.queryParams.sif;
    const zoneId = this.route.snapshot.queryParams.zoneId;
    this.referralId = this.route.snapshot.queryParams.referralId;
    this.isLocationOnlySearch = this.route.snapshot.queryParams.isLocationOnlySearch == 'true';
    const sifRecord = JSON.parse(decodeURIComponent(escape(window.atob(this.route.snapshot.queryParams.sifRecord ?? ''))));

    if (isSifParam && typeof isSifParam === 'string') {
      this.isSif = isSifParam.toLowerCase() === 'true';
    }

    forkJoin([
      this.isSif
        ? this.learnerService.getLearnerSummaryForSifSearchResult(sifRecord)
        : this.learnerService.getLearnerSummaryForSearchResult(id, isSifParam, zoneId),
      this.learnerService.getActivityLog(id),
      this.caseService.getAllCases(id),
    ]).subscribe(([learnerSummaryResult, allEvents, cases]) => {
      if (!this.isSif) {
        learnerSummaryResult = { succeeded: true, value: learnerSummaryResult as LearnerSummary };
      } else {
        learnerSummaryResult = learnerSummaryResult as OperationResultWithValue<LearnerSummary>;
        this.isPossibleDuplicate = learnerSummaryResult.errors?.some((e) => e.description === 'ISPOSSIBLEDUPLICATE');
      }

      if (learnerSummaryResult.succeeded) {
        this.learnerSummary = learnerSummaryResult.value;
        this.pageTitle = 'Detailed Results - ' + this.learnerSummary.fullName;

        this.activityLog = new MatTableDataSource(allEvents);

        this.activeCases = cases.value.filter((c) => c.exitFinalizedOn === null);
        this.exitedCases = cases.value.filter((c) => c.exitFinalizedOn !== null);
        this.canContinueToDashboard = this.activeCases?.length > 0 || this.exitedCases?.some((c) => c.intakeType === IntakeType.PartB);
        this.canStartChildFind = !this.canContinueToDashboard && (this.canStartCase || this.isOutOfStateUser);
      } else if (learnerSummaryResult.errors?.length) {
        this.notificationService.alert(learnerSummaryResult.errors[0].description, 'Alert', () => {
          this.returnToSearch();
        });
      }
    });
  }

  returnToSearch() {
    if (!this.isLocationOnlySearch) {
      this.searchService.flipStoredCriteriaFlag();
    }
    this.location.back();
  }

  continue() {
    if (this.canStartChildFind && !this.authService.isSuperAdmin) {
      this.startChildFind();
    } else {
      const activeCaseId = !!this.learnerSummary.activeCases ? this.learnerSummary.activeCases[0]?.id : null;
      if (activeCaseId || this.canContinueToDashboard) {
        this.goToLearnerDashboard();
      }
    }
    // If you reached this far, something is wrong.
  }

  startChildFind() {
    const dialogRef = this.dialog.open(NewCaseModalComponent, {
      width: '740px',
      data: {
        learnerSummary: this.learnerSummary,
        referralId: this.referralId,
        isFromSif: this.isSif,
        isPossibleDuplicate: this.isPossibleDuplicate,
        isOutOfState: this.isOutOfStateUser,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res === 'AlreadyExists') {
          this.notificationService.error('This learner already has an open case in ACHIEVE.');
        }
        this.load();
      }
    });
  }

  goToLearnerDashboard() {
    this.routingService.learnerDashboard(this.learnerSummary.id);
  }
}
