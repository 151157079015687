import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { InterimPlanModalComponent } from 'src/app/ifsp/interim-plan-modal/interim-plan-modal.component';
import { IfspSurvey } from 'src/app/ifsp/models/ifsp-survey';
import { OutcomeReviewDto } from 'src/app/ifsp/models/outcome-models';
import { OutcomeService } from 'src/app/ifsp/services/outcome.service';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { FeatureFlagService } from 'src/app/shared/services/feature-flags/feature-flag.service';
import { IfspIepResetDateComponent } from 'src/app/shared/components/ifsp-iep-reset-date/ifsp-iep-reset-date.component';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow, openPdfWindow, openPopup } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../auth/auth.service';
import { IfspModificationModalComponent } from '../../ifsp/modals/ifsp-modification-modal/ifsp-modification-modal.component';
import {
  AnnualReviewUpdate,
  IfspAddUpdate,
  IfspPeriodicAnnualReview,
  IfspReviewType,
  IfspStatus,
  IfspType,
  IfspView,
} from '../../ifsp/models/ifsp';
import { IfspModificationService } from '../../ifsp/services/ifsp-modification.service';
import { IfspService } from '../../ifsp/services/ifsp.service';
import { AppPermissions } from '../../permissions';
import { DateFormatPipe } from '../../shared/pipes/date-transform.pipe';
import { ChangeTrackerService } from '../../shared/services/change-tracker.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../shared/services/deactivation.service';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { PrintDocumentsModalComponent } from '../documentation/print-documents-modal/print-documents-modal.component';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';

@Component({
  selector: 'app-ifsp-data',
  templateUrl: './ifsp-data.component.html',
  styleUrls: ['./ifsp-data.component.scss'],
  providers: [ChangeTrackerService],
})
export class IfspDataComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  private creatingIfsp = false;
  private subscriptions = new Subscription();
  pdfOutputs = PdfOutputs;

  @ViewChild('interimConfirmation') interimConfirmation: TemplateRef<any>;
  dataSource = new MatTableDataSource<IfspView>();
  displayedColumns = ['action', 'createdOn', 'ifspType', 'ifspStart', 'ifspEnd', 'info'];
  outcomeDisplayedColumns = ['typeOfOutcome', 'title', 'outcomeEndDate', 'tags'];
  learnerId: string;
  caseSummary: CaseSummary;
  caseId: string;
  ifsps: IfspView[] = [];
  canEdit = false;
  canView = false;
  canViewComplete = false;
  canViewCompleteOutput = false;
  ifspStatuses = IfspStatus;
  intakeLockedOn: Date;
  surveysByQuestion: IfspSurvey[] = [];
  outcomeReviews: OutcomeReviewDto[] = [];
  ifspPeriodicAnnualReviews: IfspPeriodicAnnualReview[] = [];
  permissions = AppPermissions;
  loading = {
    finalization: false,
  };

  get draftIFSP() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Draft);
  }

  get activeIFSP() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Active);
  }

  get completeIFSP() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Complete);
  }

  get ifspsInModification() {
    return this.ifsps.filter((x) => x.isModifying).length > 0;
  }

  get isCaseWorkable(): boolean {
    return this.caseSummary?.isActive && this.caseSummary?.exitFinalizedOn === null;
  }

  get enableStartNewIFSPBtn() {
    return (
      !this.draftIFSP &&
      (!this.activeIFSP || this.activeIFSP?.ifspType === IfspType.Interim) &&
      !this.ifspsInModification &&
      !this.creatingIfsp &&
      this.canEdit
    );
  }

  get enableInterimIFSPBtn() {
    const now: Date = dayjs().toDate();
    return (
      !this.draftIFSP &&
      !this.creatingIfsp &&
      this.canEdit &&
      now > this.intakeLockedOn &&
      !this.ifsps?.find((x) => x.ifspType === IfspType.Interim && !x.activatedOn)
    );
  }

  get interimIfsp() {
    return this.ifsps.find((x) => x.ifspType === IfspType.Interim);
  }

  get initialIfsp() {
    return this.ifsps.find((x) => x.ifspType === IfspType.Initial && x.ifspStatus !== IfspStatus.Complete);
  }

  canResetDates(ifsp: IfspView) {
    return this.authService.isSuperAdmin && ifsp.startDate && !ifsp.isModifying;
  }

  get achieveSettings() {
    return this.achieveConfigService.settings;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  constructor(
    private readonly router: Router,
    private readonly ifspService: IfspService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly routingService: RoutingService,
    private readonly outcomeService: OutcomeService,
    private readonly changeTracker: ChangeTrackerService,
    private readonly ifspModificationService: IfspModificationService,
    private learnerService: LearnerService,
    private readonly featureFlagService: FeatureFlagService,
    private notificationService: NotificationService,
    private readonly reportingService: ReportingService,
    private achieveConfigService: AchieveConfigService,
    private spinnerService: SpinnerService
  ) {}

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)));
  }

  ngOnInit(): void {
    this.learnerId = this.route.parent?.snapshot.paramMap.get('learnerId');
    this.loadData();

    if (this.isPortalUser) {
      this.displayedColumns = ['action', 'createdOn', 'ifspType', 'ifspStart', 'ifspEnd', 'familyInfo'];
    }
  }

  enterProgress() {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseId).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  onComingSoon() {
    this.dialog.open(DialogComingSoonComponent);
  }

  loadData() {
    forkJoin([this.ifspService.getByLearnerId(this.learnerId), this.learnerService.getCases(this.learnerId, IntakeType.PartC)]).subscribe(
      ([ifsps, caseSummary]) => {
        this.ifsps = this.dataSource.data = ifsps;
        this.caseSummary = caseSummary[0];
        this.caseId = this.caseSummary.id;
        this.canEdit = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditIFSP);
        this.canView = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewIFSP);
        this.canViewComplete =
          this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSP) ||
          (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSPInTransition) &&
            this.caseSummary.learner.isSecondaryTransition);
        this.canViewCompleteOutput =
          this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSPOutput) ||
          (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewFinalizedIFSPInTransitionOutput) &&
            this.caseSummary.learner.isSecondaryTransition);
        this.intakeLockedOn = !!this.caseSummary.intakeLockedOn ? dayjs(this.caseSummary.intakeLockedOn).toDate() : null;
        if (this.activeIFSP) {
          this.getReviews();
        }
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'createdOn':
              return item.createdOn;
            case 'ifspType':
              return item.ifspType;
            case 'ifspStart':
              return item.startDate;
            case 'ifspEnd':
              return item.endDate;
            default:
              return item[property];
          }
        };
      }
    );
  }

  getModificationDates(ifsp) {
    return ifsp.modifications?.map((x) => new DateFormatPipe().transform(x.finalizeDate))?.join(', ');
  }

  getReviews() {
    forkJoin([this.outcomeService.getOutcomesForReview(this.activeIFSP.id), this.ifspService.getReviews(this.activeIFSP.id)]).subscribe(
      ([outcomes, reviews]) => {
        this.outcomeReviews = outcomes;
        this.ifspPeriodicAnnualReviews = this.ifspService.cleanReviews(reviews.filter((x) => x.ifspReviewType === IfspReviewType.Periodic));
        for (const review of this.ifspPeriodicAnnualReviews) {
          review.surveysByQuestion = this.ifspService.convertSurveysToByQuestion(
            review.generalSurveyUserFullNameDtos,
            this.caseSummary?.learner?.fullName
          );
        }
      }
    );
  }

  private async startAnnualReview() {
    const annualReviewUpdate: AnnualReviewUpdate = {
      reviewActive: true,
      annualReviewDate: new Date(),
      ifspType: IfspReviewType.Annual,
    };
    await this.ifspService.updateReviewStatus(this.initialIfsp.id, annualReviewUpdate).toPromise();
    this.initialIfsp.annualReviewActive = true;
  }

  async beginAnnualReview() {
    if (!this.ifspsInModification && this.activeIFSP) {
      this.modifyIfsp(this.activeIFSP, true);
    } else {
      await this.startAnnualReview();
    }
  }

  async modifyIfsp(activeIfsp: any, withAnnualReview: boolean): Promise<void> {
    this.ifspModificationService.getModificationsByIfspId(activeIfsp?.id).subscribe(async (modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification && !withAnnualReview) {
        // does not have modifications
        const dialogRef = this.dialog.open(IfspModificationModalComponent, {
          width: '760px',
          data: {
            ifspId: activeIfsp.id,
            caseId: this.caseId,
          },
        });
        dialogRef.afterClosed().subscribe(async (closeResult) => {
          if (closeResult) {
            if (withAnnualReview) {
              await this.startAnnualReview();
            }
            this.router.navigate(['cases', this.caseId, 'ifsp', activeIfsp.id, 'details']);
          }
        });
      } else {
        if (withAnnualReview) {
          await this.startAnnualReview();
        }
        this.router.navigate(['cases', this.caseId, 'ifsp', activeIfsp.id, 'details']);
      }
    });
  }

  async createIfsp(): Promise<void> {
    if (this.creatingIfsp) {
      return;
    }

    this.creatingIfsp = true;

    const newIFSP = {
      caseId: this.caseId,
      ifspType: IfspType.Initial,
      ifspStatus: IfspStatus.Draft,
    } as IfspAddUpdate;

    try {
      const res = await this.ifspService.createIFSP(newIFSP).toPromise();
      await this.goToIfsp(res.id);
    } finally {
      this.creatingIfsp = false;
    }
  }

  onIfspViewOnlyView(ifspId) {
    if (this.canView) {
      if (this.featureFlagService.featureOn('outputIfspEnabled')) {
        this.loading.finalization = true;
        this.reportingService.createIfspOutput(ifspId, false).subscribe({
          next: (documentId: string) => this.handleCreateInlineOutput(documentId),
          error: () => this.handleError(),
        });
      } else {
        this.notificationService.error('IFSP output is not enabled');
      }
    } else {
      this.notificationService.error('Sorry, you do not have permission to access this functionality');
    }
  }

  onFamilyIfspView(ifsp) {
    openPdfWindow(this.learnerId, this.getLastIfspDocumentId(ifsp));
  }

  onIfspView(ifsp: IfspView) {
    if (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CreateIFSP) || this.canViewComplete) {
      this.router.navigate(['/', 'cases', this.caseId, 'ifsp', ifsp.id, 'details']);
    } else if (this.canViewCompleteOutput && ifsp.isModifying) {
      if (this.featureFlagService.featureOn('outputIfspEnabled')) {
        this.loading.finalization = true;
        this.reportingService.createIfspOutput(ifsp.id, true).subscribe({
          next: (documentId: string) => this.handleCreateInlineOutput(documentId),
          error: () => this.handleError(),
        });
      } else {
        this.notificationService.error('IFSP output is not enabled');
      }
    } else if (this.canViewCompleteOutput && !ifsp.isModifying) {
      openPdfWindow(this.learnerId, this.getLastIfspDocumentId(ifsp));
    } else {
      this.notificationService.error('Sorry, you do not have permission to access this functionality');
    }
  }

  private handleError() {
    this.loading.finalization = false;
    this.notificationService.error("Couldn't open output");
  }

  private handleCreateOutput(documentId: string) {
    this.loading.finalization = false;
    openPdfWindow(this.learnerId, documentId);
  }
  private handleCreateInlineOutput(documentId: string) {
    this.loading.finalization = false;
    openPdfWindow(this.learnerId, documentId);
  }
  openInterimPlanModal() {
    const dialogRef = this.dialog.open(InterimPlanModalComponent, {
      width: '768px',
      data: {
        caseId: this.caseId,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  goToIfsp(ifspId: string): Promise<boolean> {
    return this.router.navigate(['/', 'cases', this.caseId, 'ifsp', ifspId, 'profile']);
  }

  onAddNewServiceLog(e: Event) {
    e.stopPropagation();
    const config: NewWindowConfig = {
      path: this.routingService.addServiceLogPath(this.caseSummary.id).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  canDeleteIfsp(ifsp: IfspView) {
    return this.authService.isSuperAdmin && ifsp.ifspStatus === IfspStatus.Draft;
  }

  onDeleteIfsp(ifsp: IfspView) {
    this.notificationService.areYouSure(
      'Pressing Yes below will delete the data entered for this Draft ISFP but will leave uploaded documents available in the Documents stepper.',
      () => {
        // delete.
        this.ifspService.deleteIfsp(ifsp.id).subscribe(async (_) => {
          await this.loadData();
        });
      }
    );
  }

  onResetDates(ifsp: IfspView) {
    const data = {
      isIfsp: true,
      isInterim: ifsp.ifspType === IfspType.Interim,
      isIep: false,
      startDate: ifsp.startDate,
      endDate: ifsp.endDate,
    };
    const dialogRef = this.dialog.open(IfspIepResetDateComponent, { data, width: '728px' });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.ifspService.resetDates(ifsp.id, res).subscribe(
          (response) => {
            if (response) {
              this.notificationService.success('IFSP date reset success!');
              this.loadData();
            }
          },
          (error) => {
            this.notificationService.error('IFSP date reset failed.');
            console.log(error);
          }
        );
      }
    });
  }

  onAddNewProgress(event) {
    event.stopPropagation();
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseSummary.id).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  onPrintProgressReport(event) {
    event.stopPropagation();
    // this.onComingSoon();
    this.spinnerService.incrementLoading();
    this.reportingService.createIfspOutcomeProgressReportOutput(this.caseSummary.activeIfspId).subscribe({
      complete: () => this.spinnerService.decrementLoading(),
      next: (documentId) => {
        openPopup(`documents/docid/${documentId}`);
      },
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  onPrintDocuments(ifsp: IfspView) {
    this.dialog.open(PrintDocumentsModalComponent, {
      width: '728px',
      data: {
        documents: ifsp.documents,
        type: 'ifsp',
      },
    });
  }

  getLastIfspDocumentId(ifsp: IfspView) {
    if (!ifsp?.modifications || ifsp?.modifications?.length == 0 || ifsp?.ifspStatus === IfspStatus.Complete) return ifsp?.outputDocumentId;

    return (
      ifsp.modifications.sort((a, b) => {
        return Date.parse(b.finalizeDate) - Date.parse(a.finalizeDate);
      })[0]?.outputDocumentId || ifsp?.outputDocumentId
    );
  }

  showActions(ifsp: IfspView): boolean {
    return (
      (!this.isPortalUser && (this.canResetDates(ifsp) || (this.achieveSettings.printAssociatedDocuments && ifsp.documents?.length > 0))) ||
      (this.isPortalUser && (ifsp.ifspStatus === IfspStatus.Active || ifsp.ifspStatus === IfspStatus.Complete))
    );
  }
}
