import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { DhhChildSearchComponent } from './components/dhh-child-search/dhh-child-search.component';
import { DhhLearnerEntryComponent } from './components/dhh-learner-entry/dhh-learner-entry.component';
import { DhhPendingLearnerUpdateComponent } from './components/dhh-pending-learners/dhh-pending-learner-update/dhh-pending-learner-update.component';
import { DhhPendingLearnersComponent } from './components/dhh-pending-learners/dhh-pending-learners.component';
import { DhhReportingComponent } from './components/dhh-reporting/dhh-reporting.component';
import { LearningHearingDashboardComponent } from './components/learning-hearing-dashboard/learning-hearing-dashboard.component';
import { DhhEventSchedulerComponent } from './components/shared/dhh-event-scheduler/dhh-event-scheduler.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'child-search',
    component: DhhChildSearchComponent,
  },
  {
    path: 'list-search',
    loadChildren: () => import('./components/dhh-list-search/dhh-list-search.module').then((mod) => mod.DhhListSearchModule),
  },
  {
    path: 'screening',
    loadChildren: () => import('./components/dhh-screening/dhh-screening.module').then((mod) => mod.DhhScreeningModule),
  },
  {
    path: 'recheck',
    loadChildren: () => import('./components/dhh-recheck/dhh-recheck.module').then((mod) => mod.DhhRecheckModule),
  },
  {
    path: 'appointment',
    loadChildren: () => import('./components/dhh-appointment/dhh-appointment.module').then((mod) => mod.DhhAppointmentModule),
  },
  {
    path: 'pending-learners',
    component: DhhPendingLearnersComponent,
  },
  {
    path: 'reporting',
    component: DhhReportingComponent,
  },
  {
    path: 'learner-entry',
    component: DhhLearnerEntryComponent,
  },
  {
    path: 'event-scheduler',
    component: DhhEventSchedulerComponent,
  },
  {
    path: 'event-scheduler/:appointmentId',
    component: DhhEventSchedulerComponent,
  },
  {
    path: 'pending-learner/:learnerId',
    component: DhhPendingLearnerUpdateComponent,
  },
  {
    path: 'learner-hearing-dashboard',
    loadChildren: () =>
      import('./components/learning-hearing-dashboard/learner-hearing-dashboard.module').then((mod) => mod.LearnerHearingDashboardModule),
  },
  {
    path: 'administration',
    loadChildren: () => import('./components/dhh-administration/dhh-administration.module').then((mod) => mod.DhhAdministrationModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DhhRoutingModule {}
