import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { PseSummaryService } from 'src/app/iep/iep-plaafp/secondary-transition/services/pse-summary.service';
import { IepAmendmentModalComponent } from 'src/app/iep/modals/iep-amendment-modal/iep-amendment-modal.component';
import { IepStatus, IepView } from 'src/app/iep/models/iep';
import { IepAmendmentService } from 'src/app/iep/services/iep-amendment.service';
import { IepService } from 'src/app/iep/services/iep.service';
import { InterimPlanModalComponent } from 'src/app/ifsp/interim-plan-modal/interim-plan-modal.component';
import { IfspModificationModalComponent } from 'src/app/ifsp/modals/ifsp-modification-modal/ifsp-modification-modal.component';
import { AnnualReviewUpdate, IfspReviewType, IfspStatus, IfspType, IfspView, PeriodicReviewUpdate } from 'src/app/ifsp/models/ifsp';
import { IfspModificationService } from 'src/app/ifsp/services/ifsp-modification.service';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureMemoModalComponent } from 'src/app/shared/components/are-you-sure-memo-modal/are-you-sure-memo-modal.component';
import { AdditionalInformationModalComponent } from 'src/app/shared/modals/additional-information-modal/additional-information-modal.component';
import { FinalEcoRatingPkStatus, LearnerCaseSummary, LearnerSummary } from 'src/app/shared/models/learner';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { MdService } from '../../behavior-discipline/md/md.service';
import { ReevaluationService } from '../../reevaluation/services/reevaluation.service';
import { AreYouSureComponent } from '../../shared/components/are-you-sure-modal/are-you-sure.component';
import { CaseSummary, IntakeType, QuickLinkDetails } from '../../shared/models/case';
import { FamilyConsentStatus } from '../../shared/models/fiie-consent/family-consent-status';
import { CaseService } from '../../shared/services/case/case.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from '../../shared/windowHelpers';
import { ManageLearnerStateComponent } from '../learner-dashboard/manage-learner-state/manage-learner-state.component';
import { ManageReevaluationDueDateComponent } from '../learner-dashboard/manage-reevaluation-due-date/manage-reevaluation-due-date.component';
import { ManageMigrationDetailsComponent } from '../learner-dashboard/manage-migration-details/manage-migration-details.component';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit, OnDestroy {
  @Input() caseSummary: CaseSummary;
  caseId: string;
  learnerSummary: LearnerSummary;
  stateIdQuickLinkTitle: string;
  quickLinkDetails: QuickLinkDetails;
  consentStatus = FamilyConsentStatus;
  intakeType = IntakeType;
  isReady = false;
  permissions = AppPermissions;
  ifsps: IfspView[] = [];
  ieps: IepView[] = [];
  intakeLockedOn: Date;
  hasEvaluationConsent: boolean;
  learnerId: string;
  bipId: string;
  subscription = new Subscription();
  activeCall = false;
  private quickLinksSubject = new Subject<void>();
  generatingAgeOfMajorityLetters = false;
  viewReferralAdditionalRequirements = new Map<string, string[]>([['TeamMember', ['ServiceCoordinator', 'AeaEdit']]]);
  completeDisabilitySuspectedAdditionalRequirements = new Map<string, string[]>([
    ['TeamMember', ['AeaEdit', 'ACHIEVEDataLead', 'ACHIEVEDataTechnician']],
  ]);
  requestForFiieConsentAdditionalRequirements = new Map<string, string[]>([['TeamMember', ['AeaEdit']]]);
  viewSchedulerAdditionalRequirements = new Map<string, string[]>([
    ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
  ]);
  partCActiveCase: LearnerCaseSummary;

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private iepService: IepService,
    private ifspService: IfspService,
    private mdService: MdService,
    private ifspModificationService: IfspModificationService,
    private iepAmendmentService: IepAmendmentService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly evaluationService: EvaluationService,
    private readonly reevaluationService: ReevaluationService,
    private readonly notificationService: NotificationService,
    private readonly reportingService: ReportingService,
    private readonly dialog: MatDialog,
    private ngZone: NgZone,
    public readonly routingService: RoutingService,
    private readonly learnerService: LearnerService,
    private readonly pseService: PseSummaryService
  ) {}

  getLearnerSummary() {
    this.learnerService.getLearnerSummary(this.learnerId).subscribe((x) => {
      this.learnerSummary = x;
      this.setStateIdLinkText();
    });
  }

  setStateIdLinkText() {
    this.stateIdQuickLinkTitle = this.learnerSummary && this.learnerSummary.stateAssignedId != null ? 'Change State ID' : 'Add State ID';
  }

  get draftIfsp() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Draft);
  }

  get activeIfsp() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Active);
  }

  get completeIFSP() {
    return this.ifsps.find((x) => x.ifspStatus === IfspStatus.Complete);
  }

  get draftIep() {
    return this.ieps.find((x) => x.iepStatus === IepStatus.Draft);
  }

  get activeIep() {
    return this.ieps.find((x) => x.iepStatus === IepStatus.Active);
  }

  get completeIep() {
    return this.ieps.find((x) => x.iepStatus === IepStatus.Complete);
  }

  get canBeginPeriodicReview() {
    return this.quickLinkDetails?.canBeginPeriodicReview;
  }

  get canBeginAnnualReview() {
    return this.quickLinkDetails?.canBeginAnnualReview;
  }

  get canSendPeriodicReviewPoll() {
    return this.caseSummary?.intakeType === IntakeType.PartC;
    // return (
    //   this.activeIFSP !== undefined &&
    //   this.activeIFSP.canSendPeriodicReviewPoll &&
    //   !this.activeIFSP.sentPeriodicReviewPoll
    // );
  }

  get canSendAnnualReviewPoll() {
    return this.caseSummary?.intakeType === IntakeType.PartC;
    // return (
    //   this.activeIFSP !== undefined &&
    //   this.activeIFSP.canSendAnnualReviewPoll &&
    //   !this.activeIFSP.sentAnnualReviewPoll
    // );
  }

  get IsAbleForScheduleMeeting() {
    return (
      this.authService.isAllowedByCaseId(this.caseSummary.id, undefined, AppPermissions.ScheduleMeeting) ||
      this.authService.isAllowedByCaseId(
        this.caseSummary.id,
        new Map<string, string[]>([
          ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
        ]),
        AppPermissions.ScheduleMeeting
      )
    );
  }

  get enableInterimIFSPBtn() {
    const now: Date = new Date();
    return (
      ((!this.completeIFSP && !this.draftIfsp) || (this.completeIFSP && !this.draftIfsp)) &&
      // this.authService.isAllowed(AppPermissions.EditIFSP) &&
      now > this.intakeLockedOn &&
      !this.ifsps.find((x) => x.ifspType === IfspType.Interim) &&
      this.quickLinkDetails?.intakeType !== IntakeType.PartB
    );
  }

  get canExitPartC() {
    return (
      (this.authService.isSuperAdmin && this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.ExitPartCLearner)) ||
      this.authService.isAllowedByCaseId(
        this.caseSummary?.id,
        new Map<string, string[]>([['CaseOwner', ['ServiceCoordinator']]]),
        AppPermissions.ExitPartCLearner
      )
    );
  }

  get canExitPartB() {
    return (
      this.authService.isSuperAdmin ||
      this.authService.isAllowedByCaseId(
        this.caseSummary?.id,
        new Map<string, string[]>([['TeamMember', ['AeaEdit', 'LeaProviderEdit']]]),
        AppPermissions.ExitPartBLearner
      ) ||
      (this.authService.isCaseOwner(this.caseSummary?.id) &&
        this.authService.isAllowedByCaseId(
          this.caseSummary?.id,
          new Map<string, string[]>([['CaseOwner', ['AeaEdit', 'LeaProviderEdit']]]),
          AppPermissions.ExitPartBLearner
        ))
    );
  }

  get canCompleteDSForm() {
    return (
      this.authService.isAllowedByCaseId(this.caseId, null, AppPermissions.CompleteDisabilitySuspect) &&
      ((!this.caseSummary.learner.eligibleInLegacySystem && !this.caseSummary.learner.movingFromOutOfState) ||
        ((this.caseSummary.learner.eligibleInLegacySystem || this.caseSummary.learner.movingFromOutOfState) &&
          !this.caseSummary.isFirstPartBCase))
    );
  }

  get canReopenCase() {
    const restrictedExitCodes = ['GCP', 'GRD', 'RMA'];

    const exitedPartBCase =
      this.caseSummary?.exitFinalizedOn !== null &&
      this.caseSummary?.intakeType === this.intakeType.PartB &&
      !restrictedExitCodes.some((c) => c === this.caseSummary?.exitReasonCode);

    const hasReopenPermission =
      this.authService.isCaseOwner(this.caseSummary.id) ||
      this.authService.isAllowedByCaseId(this.caseSummary.id, undefined, AppPermissions.ReopenPartBCase);

    return exitedPartBCase && hasReopenPermission;
  }

  get showFba() {
    if (this.authService.isVrUser) return false;
    return (
      this.quickLinkDetails?.intakeType === IntakeType.PartB &&
      this.caseSummary?.isActive &&
      ((this.quickLinkDetails?.fiieConsentSubmitted && this.quickLinkDetails?.fiieConsentStatus === this.consentStatus.Approved) ||
        this.caseSummary?.learner?.isMigrated ||
        this.caseSummary?.learner?.eligibleInLegacySystem ||
        this.caseSummary?.learner?.movingFromOutOfState)
    );
  }

  get canEditMD() {
    return this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.EditMD);
  }

  get canReadMD() {
    return this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.ReadMD) && this.quickLinkDetails.activeMdId;
  }

  get showTransitionToKindergartenFinalECOLink() {
    if (this.authService.isVrUser || this.authService.isGeneralEducation) return false;
    return (
      this.caseSummary.intakeType === IntakeType.PartB && this.caseSummary.learner.isPK && !this.caseSummary.learner.finalEcoRatingPkStatus
    );
  }

  get hideThankYouLetter() {
    return this.authService.IsReadOnly && !this.authService.isCaseOwner(this.caseId);
  }

  get hideAgeOfMajorityLetter() {
    return (
      (this.familyInPortal && this.quickLinkDetails.ageOfMajorityLetterSent === true) ||
      this.authService.IsReadOnly ||
      (!this.authService.isCaseTeamMember(this.caseId) && !this.authService.isSuperAdmin) ||
      dayjs(this.caseSummary?.learner?.dateOfBirth).add(18, 'year').toDate() >= dayjs().toDate()
    );
  }

  get familyInPortal() {
    return this.caseSummary.learner.familyMembers.some((x) => x.isUserAccountActive === true);
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin;
  }

  get isAbleForPrintCaseClosureLetter() {
    return (
      this.quickLinkDetails.intakeType !== 'PartB' &&
      this.partCActiveCase &&
      (this.authService.isCaseOwner(this.partCActiveCase.id) || this.authService.isSuperAdmin)
    );
  }

  get canAdditionalInformationLetter() {
    if (this.authService.isVrUser) return false;
    if (this.caseSummary?.intakeType !== this.intakeType.PartB) return false;
    return this.authService.isDataLead || this.authService.isDataManager || this.authService.isSuperAdmin;
  }

  get canCancelSecondaryTransition() {
    return this.authService.isSuperAdmin && this.caseSummary.learner.isSecondaryTransition;
  }

  ngOnInit(): void {
    this.learnerId = this.route.parent.snapshot.paramMap.get('learnerId');
    this.caseId = this.caseSummary.id;
    this.bipId = this.caseSummary.bipId;
    this.getLearnerSummary();
    this.getQuickLinkDetails();
    this.learnerService.learnerSummaryChange$.subscribe((learnerSummary) => {
      const partCCase = learnerSummary?.activeCases.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);
      if (partCCase) {
        this.partCActiveCase = partCCase;
      }
    });

    this.subscription.add(
      this.caseService.caseSummaryChange$.pipe(filter((x) => !!x)).subscribe((caseSummary) => {
        this.intakeLockedOn = caseSummary.intakeLockedOn;
        this.hasEvaluationConsent = caseSummary.hasEvaluationConsent;
        this.bipId = caseSummary.bipId;

        if (caseSummary?.intakeType === 'PartC') {
          this.ifspService.getByCaseId(this.caseId).subscribe((res) => {
            this.ifsps = res;
          });
        }

        if (caseSummary?.intakeType === 'PartB') {
          this.iepService.getByCaseId(this.caseId).subscribe((res) => {
            // TODO: This likely will need changed, probably could go in the QuickLinkDetails dto
            this.ieps = res;
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  beginAnnualReview() {
    this.activeCall = true;
    const annualReviewUpdate: AnnualReviewUpdate = {
      reviewActive: true,
      annualReviewDate: new Date(),
      ifspType: IfspReviewType.Annual,
    };

    this.ifspService.updateReviewStatus(this.caseSummary.activeIfspId, annualReviewUpdate).subscribe((res) => {
      this.routingService.ifspDetails(this.caseId, this.caseSummary.activeIfspId);
      this.activeCall = false;
    });
  }

  beginPeriodicReview() {
    this.activeCall = true;
    const periodicReviewUpdate: PeriodicReviewUpdate = {
      periodicReviewActive: true,
      periodicReviewDate: new Date(),
    };

    this.ifspService.updatePeriodicReview(this.caseSummary.activeIfspId, periodicReviewUpdate).subscribe((res) => {
      this.routingService.ifspList(this.learnerId);
      this.activeCall = false;
    });
  }

  sendPeriodicReviewPoll() {
    this.activeCall = true;
    this.ifspService.sendPeriodicReviewPoll(this.caseSummary.activeIfspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
      this.router.navigate(this.routingService.ifspListPath(this.learnerId));
      this.activeCall = false;
    });
  }

  sendAnnualReviewPoll() {
    this.activeCall = true;
    this.ifspService.sendAnnualReviewPoll(this.caseSummary.activeIfspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
      this.activeCall = false;
    });
  }

  amendIep(): void {
    this.activeCall = true;
    this.iepAmendmentService.getAmendmentsByIepId(this.activeIep.id).subscribe((modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification) {
        // does not have modifications
        const dialogRef = this.dialog.open(IepAmendmentModalComponent, {
          width: '760px',
          data: {
            iepId: this.activeIep.id,
            caseId: this.caseId,
            iepEndDate: this.activeIep.endDate,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'iep', this.activeIep.id, 'details']);
          }
          this.activeCall = false;
        });
      } else {
        this.router.navigate(['cases', this.caseId, 'iep', this.activeIep.id, 'details']);
      }
    });
  }

  createMdDraft() {
    this.reportingService.createMdOutput(this.quickLinkDetails.activeMdId).subscribe((docId) => {
      openPdfWindow(this.learnerId, docId);
    });
  }

  createMd() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking yes will open a Manifestation Determination for this learner.',
      },
    });

    dialogRef.afterClosed().subscribe((proceed) => {
      if (proceed) {
        this.mdService.createMD(this.caseId).subscribe((md) => {
          this.router.navigate(['/', 'cases', this.caseId, 'behavior-discipline', 'md', md.id, 'overview']);
        });
      } else {
        this.activeCall = false;
      }
    });
  }

  goToESY() {
    if (this.quickLinkDetails.iepId) {
      this.router.navigate(['/', 'cases', this.caseId, 'iep', this.quickLinkDetails.iepId, 'esy', 'overview']);
    } else {
      this.router.navigate(['/', 'cases', this.caseId, 'iep', this.caseSummary.activeIepId, 'esy', 'view']);
    }
  }

  modifyIfsp(): void {
    this.activeCall = true;
    this.ifspModificationService.getModificationsByIfspId(this.activeIfsp.id).subscribe((modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification) {
        // does not have modifications
        const dialogRef = this.dialog.open(IfspModificationModalComponent, {
          width: '760px',
          data: {
            ifspId: this.activeIfsp.id,
            caseId: this.caseId,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'ifsp', this.activeIfsp.id, 'details']);
          }
          this.activeCall = false;
        });
      } else {
        this.router.navigate(['cases', this.caseId, 'ifsp', this.activeIfsp.id, 'details']);
      }
    });
  }

  onOpenNewLearner(e: Event): void {
    e.preventDefault();

    openNewWindow({
      path: this.routingService.newLearnerInfoPath(this.learnerId).join('/'),
      popup: true,
    } as NewWindowConfig);
  }

  getQuickLinkDetails() {
    this.caseService.getQuickLinkDetails(this.caseId).subscribe((result) => {
      this.quickLinkDetails = result;
      this.isReady = true;
      this.quickLinksSubject.next();
    });
  }

  displayIntakeButton() {
    return this.quickLinkDetails?.intakeType === IntakeType.PartC || this.quickLinkDetails?.intakeType === IntakeType.PartCLate;
  }

  goToResultLetter(): Window {
    const openResultsLetter = (documentId = this.caseSummary.resultsLetterDocumentId): Window =>
      openPdfWindow(this.caseSummary.learnerId, documentId);

    if (this.caseSummary.resultsLetterDocumentId) {
      return openResultsLetter();
    }

    this.reportingService.createPartCResultsLetter(this.caseSummary.id).subscribe({
      next: (documentId: string) => this.handleOutputCreation(documentId, openResultsLetter),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open output")
        ),
    });
  }

  onPrintContactLetter() {
    const pdfId = this.caseSummary.caseClosureLetterDocumentId;
    if (pdfId) {
      openPdfWindow(this.learnerId, pdfId);
    } else {
      this.reportingService.createCaseClosureLetter(this.partCActiveCase.id).subscribe({
        next: (documentId: string) => this.handleCaseClosureLetterOutputCreation(documentId),
        error: (err) =>
          this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
            this.notificationService.alert(err.error, "Couldn't open output")
          ),
      });
    }
  }

  private handleCaseClosureLetterOutputCreation(documentId: string) {
    this.caseSummary.caseClosureLetterDocumentId = documentId;
    openPdfWindow(this.learnerId, documentId);
  }

  handleOutputCreation(documentId: string, openResultsLetter: (documentId: string) => Window) {
    openResultsLetter(documentId);
    this.caseSummary.resultsLetterDocumentId = documentId;
  }

  goToAgeOfMajorityLetter() {
    const openAgeOfMajorityLetters = (ids: string[]) => ids.forEach((id) => openPdfWindow(this.learnerId, id));

    const generateAgeOfMajorityLetters = () => {
      this.generatingAgeOfMajorityLetters = true;
      this.subscription.add(
        this.reportingService.createAgeOfMajorityDocuments(this.learnerId).subscribe((res) => {
          this.getQuickLinkDetails();
          this.quickLinksSubject.subscribe(() => {
            openAgeOfMajorityLetters(res);
          });
        })
      );
    };

    if (this.quickLinkDetails.ageOfMajorityDocumentIds?.length > 0) {
      openAgeOfMajorityLetters(this.quickLinkDetails.ageOfMajorityDocumentIds);
    } else {
      if (this.generatingAgeOfMajorityLetters) return;
      if (this.familyInPortal) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '728px',
          data: {
            title: 'Create and Push Age of Majority Letter',
            message: 'Are you sure you want to create and push the Age of Majority Letter?',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            generateAgeOfMajorityLetters();
            this.updateAgeOfMajorityLetterSentStatus(true);
          }
        });
      } else {
        generateAgeOfMajorityLetters();
      }
    }
  }

  goToReferralThankYouLetter() {
    const openThankYouLetter = () => openNewWindow(`api/documents/${this.learnerId}/${this.quickLinkDetails.thankYouLetterDocumentId}`);

    if (this.quickLinkDetails.thankYouLetterDocumentId) {
      openThankYouLetter();
    } else {
      this.subscription.add(
        this.reportingService.createThankYouLetter(this.caseId).subscribe(() => {
          this.getQuickLinkDetails();
          this.quickLinksSubject.subscribe(() => openThankYouLetter());
        })
      );
    }
  }

  routeToEarlyAccessReferralReadOnlyForm() {
    const config: NewWindowConfig = {
      path: `child-find/early-access-referral/${this.quickLinkDetails.earlyAccessReferralId}/read-only`,
      popup: true,
    };
    openNewWindow(config);
  }

  openPwn() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/pwns${this.quickLinkDetails.pwnId ? `/${this.quickLinkDetails.pwnId}` : ''}`,
      popup: true,
    };
    const window = openNewWindow(config);
    window.addEventListener('beforeunload', (ev) => {
      this.ngZone.run(() => this.getQuickLinkDetails());
    });
  }

  updateLetterStatus(letterStatus: boolean) {
    this.caseService.updateLetterStatus(this.caseId, letterStatus).subscribe(() => {});
  }

  updateAgeOfMajorityLetterSentStatus(letterStatus: boolean) {
    this.learnerService.updateAgeOfMajorityLetterSentStatus(this.learnerId, letterStatus).subscribe(() => {});
  }

  updateResultLetterStatus(letterStatus: boolean) {
    this.caseService.updateResultLetterStatus(this.caseId, letterStatus).subscribe(() => {});
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.learnerId);
  }

  onGoToEvaluation(evaluationId = '') {
    const navById = (id) => {
      this.router.navigate(['/', 'cases', this.caseId, 'evaluation', id, 'overview']).then();
    };

    if (evaluationId) {
      navById(evaluationId);
      return;
    }

    this.evaluationService.add(this.caseId).subscribe(
      (result) => {
        navById(result.id);
        return;
      },
      (response: HttpErrorResponse) => {
        const noConsent = 'Consent not provided';
        if (response.error?.find((x) => x.description === noConsent)) {
          this.notificationService.error(noConsent);
        }
      }
    );
  }

  onGoToReevaluation(reevaluationId) {
    const navById = (id) => {
      this.router.navigate(['/', 'cases', this.caseId, 'reevaluation', id, 'overview']).then();
    };
    if (reevaluationId) {
      navById(reevaluationId);
      return;
    } else {
      this.reevaluationService.add(this.caseId).subscribe(
        (result) => {
          navById(result);
        },
        (response: HttpErrorResponse) => {
          const noConsent = 'Consent not provided';
          if (response.error?.find((x) => x.description === noConsent)) {
            this.notificationService.error(noConsent);
          }
        }
      );
    }
  }

  openInterimPlanModal() {
    const dialogRef = this.dialog.open(InterimPlanModalComponent, {
      width: '728px',
      data: {
        caseId: this.caseId,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  enterProgress() {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseId).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  addNewServiceLogEntry() {
    const config: NewWindowConfig = {
      path: this.routingService.addServiceLogPath(this.caseSummary.id).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  async exitCase(caseId: string): Promise<void> {
    await this.caseService.updateExitingInfo(caseId, { exiting: true, isSystemExit: false }).toPromise();
    this.caseSummary.exiting = true;
  }

  hasIep() {
    return this.quickLinkDetails.iepId;
  }

  hasIfsp() {
    return this.quickLinkDetails.ifspId;
  }

  canViewIep() {
    return this.hasIep() && this.authService.isAllowedByCaseId(this.caseId, null, AppPermissions.EditIEP);
  }

  canViewIfsp() {
    return this.hasIfsp() && this.authService.isAllowedByCaseId(this.caseId, null, AppPermissions.EditIFSP);
  }

  showEvaluation() {
    let hasEditPermission = false;
    if (this.quickLinkDetails.intakeType === IntakeType.PartB) {
      hasEditPermission = this.authService.isAllowedByCaseId(
        this.caseId,
        new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
        AppPermissions.EditEvaluationPartB
      );
    } else {
      hasEditPermission = this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditEvaluationPartC);
    }
    return this.quickLinkDetails.hasIepIfsp && this.hasEvaluationConsent && !this.quickLinkDetails.evaluationFinalized && hasEditPermission;
  }

  showUpdateCompleteIntake() {
    return (
      this.authService.isCaseOwner(this.caseId) ||
      !this.authService.isReadOnlyByCaseId(this.caseId, AppPermissions.ViewUpdateCompleteIntake)
    );
  }

  showReevaluation() {
    if (this.quickLinkDetails.intakeType === IntakeType.PartB) {
      const hasEditPermission = this.authService.isAllowedByCaseId(
        this.caseId,
        new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
        AppPermissions.EditEvaluationPartB
      );
      return hasEditPermission && this.quickLinkDetails.evaluationFinalized;
    }
    return false;
  }

  canViewReferral(caseId: string) {
    return this.authService.isAllowedByCaseId(
      caseId,
      new Map<string, string[]>([['TeamMember', ['AeaEdit', 'ServiceCoordinator']]]),
      AppPermissions.PartCViewReferral
    );
  }

  canCreateEditPwn(quickLinkDetails: QuickLinkDetails) {
    return this.authService.isAllowedByCaseId(
      this.caseId,
      null,
      quickLinkDetails.intakeType === IntakeType.PartB ? AppPermissions.AddPwnB : AppPermissions.AddPwnC
    );
  }

  showTransitionKindergartenFinalEcoModal() {
    const modalConfirmationText = {
      title: 'Confirmation',
      message: `Do not continue if the learner is exiting Special Education services.
                This process is for learners who are staying in Special Education and transitioning to
                Kindergarten. Do you want to continue?`,
      buttons: {
        ok: 'Continue',
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: modalConfirmationText,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.learnerService.updateLearnerFinalEcoRatingPkStatus(this.caseSummary.learnerId, FinalEcoRatingPkStatus.Draft).subscribe((_) => {
        this.caseSummary.learner.finalEcoRatingPkStatus = FinalEcoRatingPkStatus.Draft;
      });
    });
  }

  openChangeStateId() {
    const dialogRef = this.dialog.open(ManageLearnerStateComponent, {
      width: '460px',
      data: {
        title: this.stateIdQuickLinkTitle,
        caseSummary: this.caseSummary,
      },
    });
    dialogRef.afterClosed().subscribe((closeResult) => {
      this.getLearnerSummary();
      if (closeResult) {
        this.notificationService.success('');
      }
    });
  }

  openChangeReevaluationDate() {
    const dialogRef = this.dialog.open(ManageReevaluationDueDateComponent, {
      width: '460px',
      data: {
        caseId: this.caseSummary.id,
        reevaluationDueOn: this.caseSummary.reevaluationDueOn,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.caseSummary.reevaluationDueOn = result;
      }
    });
  }

  openChangeMigrationDetails() {
    const dialogRef = this.dialog.open(ManageMigrationDetailsComponent, {
      width: '740px',
      data: this.learnerSummary,
    });
  }

  onLetterRequested() {
    this.dialog.open(AdditionalInformationModalComponent, {
      width: '728px',
      data: {
        id: this.learnerId,
        onSuccess: (documentId) => openPdfWindow(this.learnerId, documentId),
      },
    });
  }

  cancelSecondaryTransition() {
    const cancellationDto = {
      aged: dayjs().diff(this.caseSummary.learner.dateOfBirth, 'year') >= 16,
      postSecondarySummaryId: this.caseSummary.postSecondarySummaryId,
      id: '00000000-0000-0000-0000-000000000000',
      learnerId: this.caseSummary.learnerId,
      iepId: this.caseSummary.activeIepId,
      caseId: this.caseSummary.caseId,
      reason: '',
    };
    const pssConfirmationMessage = cancellationDto.aged ? '' : 'in the Postsecondary Summary stepper as well as';
    this.dialog
      .open(AreYouSureMemoModalComponent, {
        width: '550px',
        data: {
          subQuestion: `Clicking Yes will remove all data entered ${pssConfirmationMessage} in the Secondary Transition fields on the IEP PLAAFP page. The data will be permanently deleted and will not be recovered if/when Secondary Transition is re-added at a later date.`,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data?.answer) {
          cancellationDto.reason = data.cancellationRationale;
          this.pseService.cancel(cancellationDto).subscribe({
            next: () => {
              this.notificationService.success('Secondary Transition cancellation successfully.');
              this.caseSummary.learner.isSecondaryTransition = false;
            },
            error: () => this.notificationService.alert('Secondary Transition cancellation failed.'),
          });
        }
      });
  }
}
