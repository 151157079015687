<div class="card-together__container">
  <mat-card class="card__together card__together--top py-2">
    <div class="display-flex justify-content-between align-items-center flex-wrap">
      <h4 class="text-primary my-0">Tagged Developmental Areas:</h4>
      <app-help-directional-text direction="right">
        <p>
          Developmental areas below will turn yellow as they are
          <a
            (click)="onOpenHelp($event, helpSection.EvalAssessmentDetails, evalAssessmentDetailsHelp.Tagged)"
            class="text-lower text-underline"
            >{{ evalAssessmentDetailsHelp.Tagged }}</a
          >.
        </p>
      </app-help-directional-text>
    </div>
  </mat-card>

  <mat-card class="card__together card__together--bottom py-2">
    <div class="badge__group" aria-labelledby="Tagged Developmental Areas">
      <span
        class="badge"
        *ngFor="let evalDomain of evaluationDomains"
        [ngClass]="{
          'badge--completed': isEvaluationDomainChecked(evalDomain.id)
        }"
      >
        {{ evalDomain.label }}
      </span>
    </div>
  </mat-card>
</div>

<div class="card-together__container mt-2">
  <mat-card class="card__together card__together--top py-2">
    <div class="display-flex align-items-center justify-content-between flex-wrap">
      <h4 class="text-primary my-0">Tagged ECO Areas:</h4>
      <app-help-directional-text direction="right">
        <p>
          ECO areas below will turn yellow as they are
          <a
            (click)="onOpenHelp($event, helpSection.EvalAssessmentDetails, evalAssessmentDetailsHelp.Tagged)"
            class="text-lower text-underline"
            >{{ evalAssessmentDetailsHelp.Tagged }}</a
          >.
        </p>
      </app-help-directional-text>
    </div>
  </mat-card>

  <mat-card class="card__together card__together--bottom py-2">
    <div class="badge__group" aria-labelledby="Tagged ECO Areas">
      <div
        class="badge"
        *ngFor="let ecoArea of ecoAreas"
        [ngClass]="{
          'badge--completed': isEcoAreaChecked(ecoArea.id)
        }"
      >
        {{ ecoArea.label }}
      </div>
    </div>
  </mat-card>
</div>

<div #editingTitle></div>

<mat-card *ngIf="currentEvaluationNote || detailEditing" class="mb-0 currently-editing">
  <div class="display-flex align-items-center">
    <div class="display-flex align-items-center">
      <h3 class="my-0">Currently Editing:</h3>
      <p class="my-0 ml-2 text-md">
        <span *ngIf="currentEvaluationNote?.note">
          {{ currentEvaluationNote?.note | truncate : 85 }}
        </span>
      </p>
    </div>
  </div>
</mat-card>

<section
  [ngClass]="{
    'section--editing': currentEvaluationNote || detailEditing
  }"
>
  <app-help-directional-text [outsideCard]="true">
    <p class="mt-3">
      At least one method must be selected in order to enter information into and tag developmental areas, ECO areas, and/or routines.
      Multiple methods are required to complete the evaluation.
    </p>
  </app-help-directional-text>

  <app-evaluation-method
    [isEditing]="isEditing"
    [isReview]="currentEvaluationDetail?.review"
    (reviewToggled)="updateEvaluationMethod(evaluationMethod.Review)"
    [isInterview]="currentEvaluationDetail?.interview"
    (interviewToggled)="updateEvaluationMethod(evaluationMethod.Interview)"
    [isObservation]="currentEvaluationDetail?.observation"
    (observationToggled)="updateEvaluationMethod(evaluationMethod.Observation)"
    [isTest]="currentEvaluationDetail?.test"
    (testToggled)="updateEvaluationMethod(evaluationMethod.Test)"
    (resetDeleteMethod)="resetDeleteMethod()"
  ></app-evaluation-method>

  <div
    class="card-spacing-top"
    *ngIf="
      (currentEvaluationDetail.review ||
        currentEvaluationDetail.interview ||
        currentEvaluationDetail.observation ||
        currentEvaluationDetail.test) &&
      currentEvaluationDetail.id
    "
  >
    <div [trackFormChanges]="formGroup" [formGroup]="formGroup" class="card-spacing-bottom">
      <mat-card class="card--shadow card-spacing-top mb-0">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <app-date-picker
              class="w-100"
              label="Date of Evaluation/Assessment"
              controlName="dateOfEvaluation"
              [max]="today"
              [min]="twentyTwoYearsAgo"
            ></app-date-picker>
          </div>

          <div class="col-md-6 col-lg-4" *ngIf="currentEvaluationDetail.test">
            <app-evaluation-test
              #evaluationTest
              [currentEvaluation]="currentEvaluation"
              [currentEvaluationDetail]="currentEvaluationDetail"
              [currentEvaluationNote]="currentEvaluationNote"
              (testValid)="testValid = $event"
              (testChange)="onChildChange($event)"
              [trackFormChanges]="evaluationTest.formGroup"
            ></app-evaluation-test>
          </div>

          <div class="col-md-6 col-lg-4" *ngIf="currentEvaluationDetail.observation">
            <app-evaluation-observation
              #evaluationObservation
              [currentEvaluationDetail]="currentEvaluationDetail"
              (observationValid)="observationValid = $event"
              (observationChange)="onChildChange($event)"
              [trackFormChanges]="evaluationObservation.formGroup"
            ></app-evaluation-observation>
          </div>

          <div
            class="col-md-12"
            [ngClass]="{
              'col-lg-8': currentEvaluationDetail.review,
              'col-lg-6': !currentEvaluationDetail.review
            }"
          >
            <app-documentation-upload
              [documents]="currentEvaluationDetail.documents"
              [baseUrl]="baseUrl"
              [activeCall]="activeCall"
              [isEvaluation]="true"
              (upload)="onUpload($event)"
              (deleteDocument)="onDeleteDocument($event)"
            ></app-documentation-upload>
          </div>
        </div>
      </mat-card>

      <mat-card class="card--shadow card-spacing-top pt-2 pb-1" *ngIf="currentEvaluationDetail.review">
        <app-evaluation-documentation
          #evaluationDocumentation
          [documents]="documents"
          [currentEvaluationDetail]="currentEvaluationDetail"
          (documentationValid)="documentationValid = $event"
          (documentationChange)="onChildChange($event)"
          [trackFormChanges]="evaluationDocumentation.formGroup"
        ></app-evaluation-documentation>
      </mat-card>
    </div>

    <div *ngIf="currentEvaluationDetail.interview">
      <app-evaluation-interview
        #evaluationInterview
        [caseId]="currentEvaluation?.caseId"
        [currentEvaluationNote]="currentEvaluationNote"
        [currentEvaluationDetail]="currentEvaluationDetail"
        [isEligible]="currentEvaluation.caseEligibilityDecision"
        [learnerFirstName]="currentEvaluation.learner.firstName"
        (interviewValid)="interviewValid = $event"
        (interviewChange)="onChildChange($event)"
        [isEditing]="!!currentEvaluationNote || detailEditing"
        [routineEditingId]="editingRoutineId"
        [trackFormChanges]="evaluationInterview.formGroup"
      ></app-evaluation-interview>
    </div>

    <mat-card
      class="card-spacing-top card--form mb-0 transition-default py-2"
      [ngClass]="{
        'card--edit': currentEvaluationNote || currentEvaluationDetail
      }"
      *ngIf="
        currentEvaluationDetail.review ||
        currentEvaluationDetail.observation ||
        currentEvaluationDetail.test ||
        (currentEvaluationDetail.interview && currentEvaluationDetail.interviewType === 'General')
      "
    >
      <div class="row">
        <div class="col-md-12">
          <app-evaluation-note
            #evaluationNote
            [currentEvaluationDetail]="currentEvaluationDetail"
            [currentEvaluationNote]="currentEvaluationNote"
            [trackFormChanges]="evaluationNote.formGroup"
            (noteSubmit)="evaluationNoteSubmit($event)"
          >
            <div
              cancelNoteAndCloseButton
              *ngIf="
                (currentEvaluationDetail.review ||
                  currentEvaluationDetail.interview ||
                  currentEvaluationDetail.observation ||
                  currentEvaluationDetail.test) &&
                currentEvaluationDetail.id
              "
            >
              <button
                mat-raised-button
                aria-label="Cancel Note and Close Button"
                type="button"
                class="ml-2"
                (click)="onCancelAndClose()"
                tabindex="0"
              >
                Cancel Note and Close
              </button>
            </div>

            <ng-container
              doneButton
              *ngIf="
                (currentEvaluationDetail.review ||
                  currentEvaluationDetail.interview ||
                  currentEvaluationDetail.observation ||
                  currentEvaluationDetail.test) &&
                currentEvaluationDetail.id
              "
            >
              <button
                mat-raised-button
                aria-label="Complete Note and Close Button"
                color="primary"
                class="ml-2 mr-0"
                (click)="onClose()"
                [isBusy]="activeCall"
                tabindex="0"
              >
                Complete Note and Close
              </button>
              <button
                mat-raised-button
                aria-label="Show Required Button"
                color="accent"
                class="ml-2"
                (click)="showAllErrors()"
                tabindex="0"
              >
                Show Required
              </button>
            </ng-container>
          </app-evaluation-note>
        </div>
      </div>
    </mat-card>
  </div>
</section>

<div
  *ngIf="currentEvaluationDetail.interview && currentEvaluationDetail.interviewType !== 'General' && currentEvaluationDetail.id"
  class="display-flex justify-content-end align-items-center mt-2"
>
  <button mat-raised-button aria-label="Complete Notes and Close Button" color="primary" (click)="onClose()" tabindex="0">
    Complete Notes and Close
  </button>
  <button mat-raised-button aria-label="Show Required Button" color="accent" class="ml-2" (click)="showAllErrors()" tabindex="0">
    Show Required
  </button>
</div>

<div class="mb-2 card-spacing-top display-flex justify-content-end align-items-center">
  <button
    mat-raised-button
    attr.aria-label="{{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }} Button"
    color="accent"
    (click)="whileToggleActive = true; toggleAccordion()"
    [disabled]="whileToggleActive"
    tabindex="0"
  >
    {{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}

    <mat-icon class="ml-2" attr.aria-label=" {{ isAccordionsExpanded ? 'expand_less' : 'expand_more' }} Icon">
      {{ isAccordionsExpanded ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </button>
</div>

<mat-accordion displayMode="flat" multi class="accordion display-block accordion--no-shadow accordion--short">
  <mat-expansion-panel
    class="mat-elevation-z0"
    *ngFor="let evaluationDomain of evaluationDomains"
    [disabled]="!selectedEvaluationDomains.includes(evaluationDomain.id)"
    [ngClass]="{
      'expansion-panel--disabled': !selectedEvaluationDomains.includes(evaluationDomain.id)
    }"
  >
    <mat-expansion-panel-header class="flex-wrap evaluation-note__header">
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between w-100">
          <h3 class="my-0 text-normal">{{ evaluationDomain.label }}</h3>
          <p
            class="text-md text-black my-0"
            *ngIf="showDocumentedDelay(getAssociatedDetails(evaluationDetails, evaluationDomain.id), evaluationDomain)"
          >
            Documented Delay of 25% or Greater
          </p>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Inner Accordion -->
    <mat-accordion
      displayMode="flat"
      multi
      class="accordion accordion--close accordion--plain accordion--no-border accordion--no-shadow accordion--short display-block"
    >
      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let detail of getAssociatedDetails(evaluationDetails, evaluationDomain.id)">
        <mat-expansion-panel-header>
          <mat-panel-title class="evaluation-note__title flex-wrap">
            <!-- TITLE -->
            <div class="display-flex align-items-center flex-wrap">
              <!-- TODO: Use an icon and possibly tooltip when incomplete -->
              <mat-icon *ngIf="!detail.isSubmitted" color="warn" class="mr-2">warning</mat-icon>
              <p class="evaluation-note__title-user">
                {{ detail.dateOfEvaluation | dateFormat }}, {{ detail.createdBy.fullName
                }}<span *ngIf="detail.test && detail.observation">
                  , Location:
                  {{
                    detail.location !== 'other'
                      ? getLocationOption(detail.location)
                      : detail.descriptionOfLocation !== null
                      ? detail.descriptionOfLocation
                      : 'Other'
                  }}
                </span>
              </p>
              <p class="evaluation-note__title-method" *ngIf="detail.review">Review</p>
              <p class="evaluation-note__title-method" *ngIf="detail.interview">Interview</p>
              <p class="evaluation-note__title-method" *ngIf="detail.observation">Observation</p>
              <p class="evaluation-note__title-method" *ngIf="detail.test">Test</p>
            </div>

            <div *ngIf="!detail.isSubmitted" class="display-flex align-items-center evaluation-note__title-domains">
              <p class="text-sm text-danger my-0">
                Detail is incomplete! Either required fields are missing, or no note is associated with this detail.
              </p>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- BODY -->
        <div class="evaluation-note__body">
          <hr class="input__divider mt-0" />
          <div class="note__row" *ngIf="detail.review">
            <h2>Review</h2>

            <ng-container *ngIf="detail.reviewedDocuments">
              <h4 class="text-primary">Documents</h4>
              <ul class="note__list">
                <li class="note__item">
                  <ng-container *ngFor="let document of detail.reviewedDocuments; last as isLast">
                    {{ document.title }} {{ isLast ? '' : ' | ' }}
                  </ng-container>
                  <span class="ml-1" *ngIf="detail.documentationNotes">({{ detail.documentationNotes }})</span>
                </li>
              </ul>
            </ng-container>
          </div>

          <div class="note__row" *ngIf="detail.interview">
            <h2>Interview</h2>
            <h4 class="text-primary" *ngIf="detail.interviewType">Type</h4>
            <ul class="note__list">
              <li class="note__item">
                <span>
                  {{ detail.interviewType }}
                </span>
              </li>
            </ul>
            <h4 class="text-primary" *ngIf="detail.toolUsed">Tool Used</h4>
            <ul class="note__list">
              <li class="note__item">
                <span *ngIf="detail.toolUsed !== 'facs2'">
                  {{ detail.toolUsed }}
                </span>
                <span *ngIf="detail.toolUsed === 'facs2'"> Family Assessment Conversation Starters Revised (FACS-2) </span>
                <span *ngIf="detail.toolUsed === 'other'">
                  {{ detail.toolOtherDescription }}
                </span>
              </li>
            </ul>
          </div>

          <div class="note__row" *ngIf="detail.interviewType === 'General'">
            <h4 class="text-primary">Interviewee</h4>
            <ul class="note__list">
              <li class="note__item">
                {{ detail.interviewee }}
              </li>
            </ul>
          </div>

          <div class="note__row" *ngIf="detail.observation && !detail.test">
            <h2>Observation</h2>
            <h4 class="text-primary">Observation Location</h4>
            <ul class="note__list">
              <li class="note__item">
                {{ getLocationOption(detail.location) }}
              </li>

              <li class="note__item" *ngIf="detail.location === 'other'">
                {{ detail.descriptionOfLocation }}
              </li>
            </ul>
          </div>

          <div class="note__row" *ngIf="detail.test">
            <h2>Test</h2>
            <h4 class="text-primary">Test Info</h4>
            <ul class="note__list">
              <li class="note__item">
                <span *ngIf="detail.test && !detail.observation">
                  Location:
                  {{ getLocationOption(detail.location) }}

                  <span *ngIf="detail.location === 'other'"> | Location Description: {{ detail.descriptionOfLocation }} </span>

                  |
                </span>
                Test Given: {{ detail.testGiven }}
              </li>
            </ul>
          </div>

          <div class="note__row" *ngIf="detail.documents.length > 0">
            <h4 class="text-primary">Uploaded Documents</h4>
            <ul class="note__list">
              <li class="note__item">
                <span *ngFor="let document of detail.documents; last as isLast"> {{ document.title }} {{ isLast ? '' : ' | ' }} </span>
              </li>
            </ul>
          </div>
        </div>

        <div *ngFor="let routine of getRoutines(detail.routines, evaluationDomain.id)" class="routine">
          <div class="routine__content">
            <div class="routine__header">
              <div class="routine__col text-left" *ngIf="routine.label">
                <h4 class="routine__title">Routine</h4>
                <p>{{ routine.label }}</p>
              </div>

              <div class="routine__col" *ngIf="routine.satisfaction">
                <h4 class="routine__title">Satisfaction Rating</h4>
                <p>{{ getSatisfactionText(routine.satisfaction) }}</p>
              </div>

              <div class="routine__col" *ngIf="routine.workOnTogether !== null">
                <h4 class="routine__title">Will we work on this?</h4>
                <p>{{ routine.workOnTogether | boolToYesNo }}</p>
              </div>

              <div
                class="routine__col routine__col--long text-left"
                *ngIf="routine?.ecoAreas?.length > 0 || routine?.domainAreas?.length > 0"
              >
                <h4 class="routine__title">ECO Areas and Developmental Areas</h4>
                <p>
                  {{ joinEcoAndDomain(routine.ecoAreas, routine.domainAreas) }}
                </p>
              </div>
            </div>
            <ul class="routine__list" *ngIf="routine.satisfaction > 2">
              <li class="routine__item" *ngIf="routine.workingWell">
                <strong>What's working well:</strong>
                {{ routine.workingWell }}
              </li>

              <li class="routine__item" *ngIf="routine.routineDifficulty">
                <strong>What's difficult:</strong>
                {{ routine.routineDifficulty }}
              </li>

              <li class="routine__item" *ngIf="routine.routineParticipation">
                <strong>Child/family participation:</strong>
                {{ routine.routineParticipation }}
              </li>
            </ul>

            <ul class="routine__list" *ngIf="routine.satisfaction < 3">
              <li class="routine__item" *ngIf="routine.routineLooksLike">
                <strong>Description of the routine:</strong>
                {{ routine.routineLooksLike }}
              </li>

              <li class="routine__item" *ngIf="routine.routineParticipation">
                <strong>Child/family participation:</strong>
                {{ routine.routineParticipation }}
              </li>

              <li class="routine__item" *ngIf="routine.partWorkingWell">
                <strong>What's working well:</strong>
                {{ routine.partWorkingWell }}
              </li>

              <li class="routine__item" *ngIf="routine.routineDifficulty">
                <strong>What's difficult:</strong>
                {{ routine.routineDifficulty }}
              </li>

              <li class="routine__item" *ngIf="routine.wouldRoutineImproveHelp">
                <strong>What would help:</strong>
                {{ routine.wouldRoutineImproveHelp }}
              </li>
            </ul>
          </div>

          <div class="routine__action">
            <button
              *ngIf="detail.id !== currentEvaluationDetail?.id && (detail.createdBy.id === userId || isSuperAdmin)"
              mat-icon-button
              aria-label="Edit Detail Button"
              class="mr-2"
              (click)="$event.stopPropagation(); onDetailEdit(detail, routine.id)"
              tabindex="0"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>

        <div *ngFor="let note of getNotes(detail.notes, evaluationDomain.id)">
          <div class="note__row w-100">
            <hr class="input__divider" />
            <div class="note__col w-100 note__col--lg">
              <div class="display-flex justify-content-between align-items-center">
                <div class="display-flex align-items-end">
                  <h4 class="text-primary mb-1">
                    Note
                    {{ note.interviewQuestion ? '(' + note.interviewQuestion + ')' : '' }}
                    <span *ngIf="note.calculatedPercentDelayLow && !note.calculatedPercentDelayHigh">
                      | Calculate Percent Delay:
                      {{ note.calculatedPercentDelayLow | percent }}
                    </span>
                    <span *ngIf="note.calculatedPercentDelayLow && note.calculatedPercentDelayHigh"
                      >| Calculate Percent Delay: {{ note.calculatedPercentDelayHigh | percent }} -
                      {{ note.calculatedPercentDelayLow | percent }}</span
                    >
                  </h4>
                  <ul class="note__list">
                    <li class="note__item">
                      <div class="display-flex align-items-center ml-0 my-1 flex-wrap">
                        <ng-container *ngIf="note.ecoAreas.length > 0">
                          <p *ngFor="let ecoArea of note.ecoAreas" class="evaluation-note__title-domain">
                            {{ ecoArea.label }}
                          </p>
                        </ng-container>

                        <ng-container *ngIf="note.domainAreas?.length > 0">
                          <p *ngFor="let evaluationDomain of note.domainAreas" class="evaluation-note__title-domain">
                            {{ evaluationDomain.label }}
                          </p>
                        </ng-container>

                        <ng-container *ngIf="note.evaluationRoutines?.length > 0">
                          <p *ngFor="let routine of note.evaluationRoutines" class="evaluation-note__title-domain">
                            {{ routine.label }}
                          </p>
                        </ng-container>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="display-flex align-items-start">
                  <p class="text-primary my-0" *ngIf="getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)">
                    Currently Editing
                  </p>

                  <button
                    *ngIf="!getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)"
                    mat-icon-button
                    aria-label="Edit Note Button"
                    class="mr-2 text-md"
                    (click)="$event.stopPropagation(); onNoteEdit(note)"
                    tabindex="0"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    *ngIf="!getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)"
                    mat-icon-button
                    color="warn"
                    aria-label="Delete Icon"
                    class="mr-2"
                    (click)="$event.stopPropagation(); onNoteDelete(note)"
                    tabindex="0"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <ul class="note__list">
                <li class="note__item">
                  <div class="display-flex justify-content-between align-items-center">
                    <p class="white-space-pre-line">{{ note.note }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" *ngIf="anyNotesNotTagged() || anyRoutinesNotTagged()">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">No Developmental Area Tagged</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-accordion
      displayMode="flat"
      multi
      class="accordion accordion--close accordion--plain accordion--no-border accordion--no-shadow accordion--short display-block"
    >
      <ng-container *ngFor="let detail of evaluationDetails">
        <ng-container
          *ngIf="
            (detail.notes.length > 0 && notesNotTagged(detail.notes)) ||
            (detail.routines.length > 0 && routinesNotTagged(detail.routines) && detail.isSubmitted)
          "
        >
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title class="evaluation-note__title flex-wrap">
                <!-- TITLE -->
                <div class="display-flex align-items-center">
                  <!-- TODO: Use an icon and possibly tooltip when incomplete -->
                  <mat-icon *ngIf="!detail.isSubmitted" color="warn" class="mr-2">warning</mat-icon>
                  <p class="evaluation-note__title-user">
                    {{ detail.dateOfEvaluation | dateFormat }}, {{ detail.createdBy.fullName
                    }}<span *ngIf="detail.test && detail.observation">
                      , Location:
                      {{
                        detail.location !== 'other'
                          ? getLocationOption(detail.location)
                          : detail.descriptionOfLocation !== null
                          ? detail.descriptionOfLocation
                          : 'Other'
                      }}
                    </span>
                  </p>
                  <p class="evaluation-note__title-method" *ngIf="detail.review">Review</p>
                  <p class="evaluation-note__title-method" *ngIf="detail.interview">Interview</p>
                  <p class="evaluation-note__title-method" *ngIf="detail.observation">Observation</p>
                  <p class="evaluation-note__title-method" *ngIf="detail.test">Test</p>
                </div>

                <div *ngIf="!detail.isSubmitted" class="display-flex align-items-center evaluation-note__title-domains">
                  <p class="text-sm text-danger my-0">
                    Detail is incomplete! Either required fields are missing, or no note is associated with this detail.
                  </p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- BODY -->
            <div class="evaluation-note__body">
              <hr class="input__divider mt-0" />
              <div class="note__row" *ngIf="detail.review">
                <h2>Review</h2>

                <ng-container *ngIf="detail.reviewedDocuments">
                  <h4 class="text-primary">Documents</h4>
                  <ul class="note__list">
                    <li class="note__item" *ngFor="let document of detail.reviewedDocuments; last as isLast">
                      {{ document.title }} {{ isLast ? '' : ' | ' }}
                    </li>

                    <li class="note__item">
                      {{ detail.documentationNotes }}
                    </li>
                  </ul>
                </ng-container>
              </div>

              <div class="note__row" *ngIf="detail.interview">
                <h2>Interview</h2>
                <h4 class="text-primary" *ngIf="detail.interviewType">Type</h4>
                <ul class="note__list">
                  <li class="note__item">
                    <span>
                      {{ detail.interviewType }}
                    </span>
                  </li>
                </ul>

                <h4 class="text-primary" *ngIf="detail.toolUsed">Tool Used</h4>
                <ul class="note__list">
                  <li class="note__item">
                    <span *ngIf="detail.toolUsed !== 'facs2'">
                      {{ detail.toolUsed }}
                    </span>
                    <span *ngIf="detail.toolUsed === 'facs2'"> Family Assessment Conversation Starters Revised (FACS-2) </span>
                    <span *ngIf="detail.toolUsed === 'other'">
                      {{ detail.toolOtherDescription }}
                    </span>
                  </li>
                </ul>
              </div>

              <div class="note__row" *ngIf="detail.interviewType === 'General'">
                <h4 class="text-primary">Interviewee</h4>
                <ul class="note__list">
                  <li class="note__item">
                    {{ detail.interviewee }}
                  </li>
                </ul>
              </div>

              <div class="note__row" *ngIf="detail.observation && !detail.test">
                <h2>Observation</h2>
                <h4 class="text-primary">Observation Location</h4>
                <ul class="note__list">
                  <li class="note__item">
                    {{ getLocationOption(detail.location) }}
                  </li>

                  <li class="note__item" *ngIf="detail.location === 'other'">
                    {{ detail.descriptionOfLocation }}
                  </li>
                </ul>
              </div>

              <div class="note__row" *ngIf="detail.test">
                <h2>Test</h2>
                <h4 class="text-primary">Test Info</h4>
                <ul class="note__list">
                  <li class="note__item">
                    <span *ngIf="detail.test && !detail.observation">
                      Location:
                      {{ getLocationOption(detail.location) }}

                      <span *ngIf="detail.location === 'other'"> | Test Description: {{ detail.descriptionOfLocation }} </span>
                      |
                    </span>
                    Test Given: {{ detail.testGiven }}
                  </li>
                </ul>
              </div>

              <div class="note__row" *ngIf="detail.documents.length > 0">
                <h4 class="text-primary">Uploaded Documents</h4>
                <ul class="note__list">
                  <li class="note__item">
                    <span *ngFor="let document of detail.documents; last as isLast"> {{ document.title }} {{ isLast ? '' : ' | ' }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngFor="let routine of detail.routines">
              <div class="routine" *ngIf="routine.domainAreas && routine.domainAreas.length === 0 && routine.satisfaction">
                <div class="routine__content">
                  <hr class="input__divider" />
                  <div class="routine__header">
                    <div class="routine__col text-left" *ngIf="routine.label">
                      <h4 class="routine__title">Routine</h4>
                      <p>{{ routine.label }}</p>
                    </div>

                    <div class="routine__col" *ngIf="routine.satisfaction">
                      <h4 class="routine__title">Satisfaction Rating</h4>
                      <p>{{ getSatisfactionText(routine.satisfaction) }}</p>
                    </div>

                    <div class="routine__col" *ngIf="routine.workOnTogether !== null">
                      <h4 class="routine__title">Will we work on this?</h4>
                      <p>{{ routine.workOnTogether | boolToYesNo }}</p>
                    </div>

                    <div
                      class="routine__col routine__col--long text-left"
                      *ngIf="routine?.ecoAreas?.length > 0 || routine?.domainAreas?.length > 0"
                    >
                      <h4 class="routine__title">ECO Areas and Developmental Areas</h4>
                      <p>
                        {{ joinEcoAndDomain(routine.ecoAreas, routine.domainAreas) }}
                      </p>
                    </div>
                  </div>
                  <ul class="routine__list" *ngIf="routine.satisfaction > 2">
                    <li class="routine__item" *ngIf="routine.workingWell">
                      <strong>What's working well:</strong>
                      {{ routine.workingWell }}
                    </li>

                    <li class="routine__item" *ngIf="routine.routineDifficulty">
                      <strong>What's difficult:</strong>
                      {{ routine.routineDifficulty }}
                    </li>

                    <li class="routine__item" *ngIf="routine.routineParticipation">
                      <strong>Child/family participation:</strong>
                      {{ routine.routineParticipation }}
                    </li>
                  </ul>

                  <ul class="routine__list" *ngIf="routine.satisfaction < 3">
                    <li class="routine__item" *ngIf="routine.routineLooksLike">
                      <strong>Description of the routine:</strong>
                      {{ routine.routineLooksLike }}
                    </li>

                    <li class="routine__item" *ngIf="routine.routineParticipation">
                      <strong>Child/family participation:</strong>
                      {{ routine.routineParticipation }}
                    </li>

                    <li class="routine__item" *ngIf="routine.partWorkingWell">
                      <strong>What's working well:</strong>
                      {{ routine.partWorkingWell }}
                    </li>

                    <li class="routine__item" *ngIf="routine.routineDifficulty">
                      <strong>What's difficult:</strong>
                      {{ routine.routineDifficulty }}
                    </li>

                    <li class="routine__item" *ngIf="routine.wouldRoutineImproveHelp">
                      <strong>What would help:</strong>
                      {{ routine.wouldRoutineImproveHelp }}
                    </li>
                  </ul>
                </div>
                <div class="routine__action">
                  <button
                    *ngIf="detail.id !== currentEvaluationDetail?.id && (detail.createdBy.id === userId || isSuperAdmin)"
                    mat-icon-button
                    aria-label="Edit Detail Button"
                    class="mr-2"
                    (click)="$event.stopPropagation(); onDetailEdit(detail, routine.id)"
                    tabindex="0"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div *ngFor="let note of detail.notes">
              <div class="note__row w-100" *ngIf="note.note && note.domainAreas.length === 0">
                <hr class="input__divider" />
                <div class="note__col w-100 note__col--lg">
                  <div class="display-flex align-items-center justify-content-between">
                    <h4 class="text-primary mb-1">
                      Note
                      {{ note.interviewQuestion ? '(' + note.interviewQuestion + ')' : '' }}
                    </h4>

                    <div class="display-flex align-items-end">
                      <p class="text-primary my-0" *ngIf="getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)">
                        Currently Editing
                      </p>

                      <button
                        *ngIf="!getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)"
                        mat-icon-button
                        aria-label="Edit Note Button"
                        class="mr-2 text-md"
                        (click)="$event.stopPropagation(); onNoteEdit(note)"
                        tabindex="0"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        *ngIf="!getIsEditing(note.id) && (note.createdBy.id === userId || isSuperAdmin)"
                        mat-icon-button
                        color="warn"
                        aria-label="Delete Icon"
                        class="mr-2"
                        (click)="$event.stopPropagation(); onNoteDelete(note)"
                        tabindex="0"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <ul class="note__list">
                    <li class="note__item">
                      <div class="display-flex justify-content-between align-items-start">
                        <p class="white-space-pre-line">{{ note.note }}</p>
                      </div>
                    </li>
                  </ul>
                  <div class="display-flex align-items-center evaluation-note__title-domains flex-wrap">
                    <ng-container *ngIf="note.ecoAreas.length > 0">
                      <p *ngFor="let ecoArea of note.ecoAreas" class="evaluation-note__title-domain">
                        {{ ecoArea.label }}
                      </p>
                    </ng-container>

                    <ng-container *ngIf="note.domainAreas.length > 0">
                      <p *ngFor="let evaluationDomain of note.domainAreas" class="evaluation-note__title-domain">
                        {{ evaluationDomain.label }}
                      </p>
                    </ng-container>

                    <ng-container *ngIf="note.evaluationRoutines.length > 0">
                      <p *ngFor="let routine of note.evaluationRoutines" class="evaluation-note__title-domain">
                        {{ routine.label }}
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
    </mat-accordion>
  </mat-expansion-panel>
  <div #incomplete></div>
  <mat-expansion-panel class="mat-elevation-z0" *ngIf="anyIncomplete()" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-danger"> Incomplete </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion
      displayMode="flat"
      multi
      class="accordion accordion--close accordion--plain accordion--no-border accordion--no-shadow display-block accordion--short"
    >
      <ng-container *ngFor="let detail of incompleteEvalDetails()">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="evaluation-note__title flex-wrap">
              <!-- TITLE -->
              <div class="display-flex align-items-center">
                <mat-icon *ngIf="!detail.isSubmitted" color="warn" class="mr-2">warning</mat-icon>
                <p class="evaluation-note__title-user">
                  {{ detail.dateOfEvaluation | dateFormat }}, {{ detail.createdBy.fullName
                  }}<span *ngIf="detail.test && detail.observation">
                    , Location:
                    {{
                      detail.location !== 'other'
                        ? getLocationOption(detail.location)
                        : detail.descriptionOfLocation !== null
                        ? detail.descriptionOfLocation
                        : 'Other'
                    }}
                  </span>
                </p>
                <p class="evaluation-note__title-method" *ngIf="detail.review">Review</p>
                <p class="evaluation-note__title-method" *ngIf="detail.interview">Interview</p>
                <p class="evaluation-note__title-method" *ngIf="detail.observation">Observation</p>
                <p class="evaluation-note__title-method" *ngIf="detail.test">Test</p>
              </div>
              <div class="display-flex align-items-right py-2">
                <button
                  *ngIf="detail.id !== currentEvaluationDetail?.id && (detail.createdBy.id === userId || isSuperAdmin)"
                  mat-icon-button
                  aria-label="Edit Detail Button"
                  class="mr-2"
                  (click)="$event.stopPropagation(); onDetailEdit(detail)"
                  tabindex="0"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  *ngIf="detail.id !== currentEvaluationDetail?.id && (detail.createdBy.id === userId || isSuperAdmin)"
                  mat-icon-button
                  color="warn"
                  aria-label="Delete Icon"
                  class="mr-2"
                  (click)="$event.stopPropagation(); onDetailDelete(detail)"
                  tabindex="0"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>

              <div
                *ngIf="!detail.isSubmitted || (detail.isSubmitted && detail.notes.length === 0)"
                class="display-flex align-items-center evaluation-note__title-domains"
              >
                <p class="text-sm text-danger my-0">
                  Detail is incomplete! Either required fields are missing, or no note is associated with this detail.
                </p>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- BODY -->
          <div class="evaluation-note__body">
            <hr class="input__divider mt-0" />
            <div class="note__row" *ngIf="detail.review">
              <h2>Review</h2>
              <ng-container *ngIf="detail.reviewedDocuments">
                <h4 class="text-primary">Documents</h4>
                <ul class="note__list">
                  <li class="note__item">
                    <span *ngFor="let document of detail.reviewedDocuments; last as isLast">
                      {{ document.title }} {{ isLast ? '' : ' | ' }}
                    </span>

                    <span class="ml-1" *ngIf="detail.documentationNotes">({{ detail.documentationNotes }})</span>
                  </li>
                </ul>
              </ng-container>
            </div>

            <div class="note__row" *ngIf="detail.interview">
              <h2>Interview</h2>
              <h4 class="text-primary" *ngIf="detail.interviewType">Type</h4>
              <ul class="note__list">
                <li class="note__item">
                  <span>
                    {{ detail.interviewType }}
                  </span>
                </li>
              </ul>
              <h4 class="text-primary" *ngIf="detail.toolUsed">Tool Used</h4>
              <ul class="note__list">
                <li class="note__item">
                  <span *ngIf="detail.toolUsed !== 'facs2'">
                    {{ detail.toolUsed }}
                  </span>
                  <span *ngIf="detail.toolUsed === 'facs2'"> Family Assessment Conversation Starters Revised (FACS-2) </span>
                  <span *ngIf="detail.toolUsed === 'other'">
                    {{ detail.toolOtherDescription }}
                  </span>
                </li>
              </ul>
            </div>

            <div class="note__rpw" *ngIf="detail.interviewType === 'General'">
              <h4 class="text-primary">Interviewee</h4>
              <ul class="note__list">
                <li class="note__item">
                  {{ detail.interviewee }}
                </li>
              </ul>
            </div>

            <div class="note__row" *ngIf="detail.observation && !detail.test">
              <h2>Observation</h2>
              <h4 class="text-primary">Observation Location</h4>
              <ul class="note__list">
                <li class="note__item">
                  {{ getLocationOption(detail.location) }}
                </li>

                <li class="note__item" *ngIf="detail.location === 'other'">
                  {{ detail.descriptionOfLocation }}
                </li>
              </ul>
            </div>

            <div class="note__row" *ngIf="detail.test">
              <h2>Test</h2>
              <h4 class="text-primary">Test Info</h4>
              <ul class="note__list">
                <li class="note__item">
                  <span *ngIf="detail.test && !detail.observation">
                    Location:
                    {{ getLocationOption(detail.location) }}

                    <span *ngIf="detail.location === 'other'"> | Test Description: {{ detail.descriptionOfLocation }} </span>
                    |
                  </span>
                  Test Given: {{ detail.testGiven }}
                </li>
              </ul>
            </div>

            <div class="note__row" *ngIf="detail.documents.length > 0">
              <h4 class="text-primary">Uploaded Documents</h4>
              <ul class="note__list">
                <li class="note__item">
                  <span *ngFor="let document of detail.documents; last as isLast"> {{ document.title }} {{ isLast ? '' : ' | ' }} </span>
                </li>
              </ul>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-expansion-panel>
</mat-accordion>
<div class="page-bottom-leeway"></div>
